import { useGradient, useThemeColors } from "../hooks"
import { join } from "../utils"

const gradients = {
  "basic-gradient": ({ theme }) => {
    const ref = useThemeColors(theme)

    return <div className={join("basic-gradient", "animating")} ref={ref} />
  },
  "dynamic-gradient": ({ theme }) => {
    const ref = useGradient(theme)
    useThemeColors(theme, ref)

    return (
      <div className="dynamic-gradient">
        <canvas ref={ref} />
      </div>
    )
  }
}

const Gradient = ({ type = "basic-gradient", ...props }) => {
  const SelectedGradient = gradients[type] || gradients["basic-gradient"]

  return <SelectedGradient {...props} />
}

export default Gradient
