import { useMemo } from "react"

const memory = {}

const roles = ["promisor", "promisee"]

class PinkyPromise {

  constructor({ name, promise, role }) {
    this.name = name
    this.role = role
    this.promise = promise
  }

  then(fx) {
    return this.promise.then(fx)
  }

  catch(fx) {
    return this.promise.catch(fx)
  }

  finally(fx) {
    return this.promise.finally(fx)
  }

  reset() {
    const { name } = this
    if (memory[name]) {
      delete memory[name]
      PinkyPromise.create(name)
      return memory[name]
    }
    else return false
  }

  static create(name) {

    if (!name) {
      console.error("You must provide a name for the pinky promise")
      return {}
    }

    // Instantiate a new pinky promise
    if (!memory[name]) memory[name] = new PinkyPromiseObserver(name)

  }
  
}

class PinkyPromiseObserver extends PinkyPromise {

  constructor(name) {

    const members = {}

    const promise = Promise.allSettled(roles.map((role) => {
      let actions
      const promise = new Promise((resolve, reject) => {
        actions = { resolve, reject }
      })
      return { role, promise, actions }
    }).map(({ role, actions }, index, schema) => {
      const { promise, role: dependency } = [...schema].reverse()[index]
      members[role] = new PinkyPromiseMember({ name, role, promise, actions, dependency })
      return promise
    }))

    super({ name, promise, role: "observer" })

    Object.keys(members).forEach((member) => {
      members[member].observer = this
      this[member] = members[member]
    })

    this.observer = this

  }

  subscribe() {
    return this.promise
  }

}

class PinkyPromiseMember extends PinkyPromise {

  data = {}

  status = "pending"
  complete = false
  kept = false
  broken = false
  subscribed = false

  state = {
    data: {},
    status: "pending",
    complete: false,
    kept: false,
    broken: false,
    subscribed: false
  }

  constructor({ name, role, promise, actions, dependency }) {

    super({ name, promise, role })
    this.actions = actions
    this.dependency = dependency

    // Update state when rejected
    this.promise.catch((error) => {
      this.complete = true
      this.broken = true
      this.status = "broken"
      this.error = error
    })

    // Update State when resolved
    this.promise.then((data) => {
      this.complete = true
      this.kept = true
      this.status = "kept"
      this.data = data
    })

  }

  keep(data) {
    if (this.state.complete) return this
    this.actions.resolve(data)
    this.state.complete = true
    this.state.data = data
    return this
  }

  break(...errorParams) {
    if (this.state.complete) return this
    const error = new Error(...errorParams)
    this.state.complete = true
    this.actions.reject(error)
    this.state.error = error
    return this
  }

  subscribe() {
    this.observer[this.dependency].subscribed = true
  }

}

const usePinkyPromise = (name, role) => {

  // Memoize Pinky Promise
  const pinkyPromise = useMemo(() => {

    // Create a new pinky promise
    PinkyPromise.create(name)

    // Subscribe when its called
    memory[name][role].subscribe()

    return memory[name]

  }, [name, role])

  return !role ? pinkyPromise : pinkyPromise[role]

}

export default usePinkyPromise