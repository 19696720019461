import anime from "animejs"
import { useCallback, useRef } from "react"
import { useBemify, useEvents, useVideoPlayer } from "../../hooks"

export default function Video({ id, schema, assets, state, settings }) {
  const container = useRef()

  const { video } = schema.template.data

  // Turbocharge Functionality with my hooks!
  const bem = useBemify("template-video")
  const { ref, ...videoHandlers } = useVideoPlayer({
    name: id,
    debug: false,
    start: video.start,
    autoplay: true
  })

  // Handle Slide Events
  useEvents("slide", state, videoHandlers, {
    slideTransitionIn: useCallback(() => {
      anime({
        targets: container.current,
        opacity: [0, 1],
        easing: "easeOutQuad",
        duration: schema.crossfade * 500,
        delay: schema.crossfade * 400
      })
    }),
    slideTransitionOut: useCallback(() => {
      anime({
        targets: container.current,
        opacity: 0,
        easing: "easeOutQuad",
        duration: schema.crossfade * 900
      })
    })
  })

  return (
    <div className={bem()} ref={container}>
      <div className={bem("player")}>
        <video
          className={bem("video")}
          src={assets[video.video_src]}
          ref={ref}
          controls={false}
          playsInline={true}
          muted={true}
          loop={true}
          crossOrigin="anonymous"
        />
      </div>
    </div>
  )
}
