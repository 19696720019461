import { join } from "../../utils"

const Snowman = ({ animated = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={join("snowman", [animated, "animated"])}
      viewBox="0 0 500 500"
    >
      <g
        className="ai"
        style={{
          transformOrigin: "253.253px 252.014px"
        }}
      >
        <path
          d="M445.76 281.67c2.79-41.44-19.42-74-54-84-20.39-5.92-41.7-12.45-54.53-32.7-11.61-18.33-19.47-39.72-33.42-56.16-22.29-26.28-60.94-26.7-89.08-13-25.53 12.38-49.81 38.56-47.62 71.68 1.7 25.73 14.41 46.71-12.27 61.6-37.74 21.07-33.81-7-70.76 31.6s-23.58 86-6.29 121.12c11 22.38 8.64 30.49 5.45 33.43a172 172 0 0 0 12.44-2.47c.21-5.15 7.85-15.89 10.52-18.56 3.27-3.28-8.9 6.08-11.7-2.81s6.08-19.66 7.48-22.47-4.21-1.4-8-5.14 1.87-8.9 2.34-13.11 0-3.27-5.62-9.36 4.21-29.95 5.15-37-4.68 3.74-7-2.81 10.3-21.06 10.3-21.06-2.81-2.81 0-9.36 9.36-10.3 11.7-6.56-2.34 8-2.34 8 6.08 6.08 6.55 13.11-.47 6.08 2.81 8.89 15.44 10.76 15.44 17.78-7.49 1.41-7.49 2.81 19.66 21.53 19.19 32.3-9.36 3.74-8.89 7 17.79 10.76 19.19 22-11.23 3.28-11.7 5.15c-.37 1.48 10.69 7.1 15.09 11.21a16.16 16.16 0 0 1 4-1.32c6.89-1.3 9.88-1 9.88-1s2.61-11.92 19-13.65 76.72-3.44 76.72-3.44l36.63-.44s31 3.88 38.36 8.19S352 394.16 360.74 394c2.65-4 6.19-8.72 6.19-8.72s-7.49 5.14-8.89 1.4 9.36-17.32 9.36-17.32-5.62 1.41-5.15-4.21 12.17-14.51 12.17-14.51a16.18 16.18 0 0 1-6.55-5.15c-2.81-3.74.93-5.15 4.21-8.42s3.27-13.11 3.27-13.11-4.21 2.34-4.21-3.27 14.51-15 14.51-16.38-6.08 1.4-3.28-4.22 12.63-10.8 12.63-10.8c7-4.68 11.7 1.4 11.7 7.49s-4.21.47-6.08 1.87 1.87 14 1.87 14-3.74-.94-5.15-.47 8 17.79 7.49 25.28-4.21.93-5.15 2.34 1.87 7.49 2.81 14-5.15 6.55-5.15 6.55 7.49 25.27 7.49 27.61-7.49-2.8-7.49-2.8a148.06 148.06 0 0 1 3 14.54c5 .36 10.16 1.34 13.31 8 3.83 8.09 12.27 8.19 17.05 9.44 2.6-2.71 3.55-7.73 2.51-17-2.36-21.07-33-49.16-17.3-65.83s27.49-17.53 29.85-52.64Z"
          className="ai"
          style={{
            fill: "#90caf9",
            transformOrigin: "253.253px 252.014px"
          }}
        />
        <path
          d="M445.76 281.67c2.79-41.44-19.42-74-54-84-20.39-5.92-41.7-12.45-54.53-32.7-11.61-18.33-19.47-39.72-33.42-56.16-22.29-26.28-60.94-26.7-89.08-13-25.53 12.38-49.81 38.56-47.62 71.68 1.7 25.73 14.41 46.71-12.27 61.6-37.74 21.07-33.81-7-70.76 31.6s-23.58 86-6.29 121.12c11 22.38 8.64 30.49 5.45 33.43a172 172 0 0 0 12.44-2.47c.21-5.15 7.85-15.89 10.52-18.56 3.27-3.28-8.9 6.08-11.7-2.81s6.08-19.66 7.48-22.47-4.21-1.4-8-5.14 1.87-8.9 2.34-13.11 0-3.27-5.62-9.36 4.21-29.95 5.15-37-4.68 3.74-7-2.81 10.3-21.06 10.3-21.06-2.81-2.81 0-9.36 9.36-10.3 11.7-6.56-2.34 8-2.34 8 6.08 6.08 6.55 13.11-.47 6.08 2.81 8.89 15.44 10.76 15.44 17.78-7.49 1.41-7.49 2.81 19.66 21.53 19.19 32.3-9.36 3.74-8.89 7 17.79 10.76 19.19 22-11.23 3.28-11.7 5.15c-.37 1.48 10.69 7.1 15.09 11.21a16.16 16.16 0 0 1 4-1.32c6.89-1.3 9.88-1 9.88-1s2.61-11.92 19-13.65 76.72-3.44 76.72-3.44l36.63-.44s31 3.88 38.36 8.19S352 394.16 360.74 394c2.65-4 6.19-8.72 6.19-8.72s-7.49 5.14-8.89 1.4 9.36-17.32 9.36-17.32-5.62 1.41-5.15-4.21 12.17-14.51 12.17-14.51a16.18 16.18 0 0 1-6.55-5.15c-2.81-3.74.93-5.15 4.21-8.42s3.27-13.11 3.27-13.11-4.21 2.34-4.21-3.27 14.51-15 14.51-16.38-6.08 1.4-3.28-4.22 12.63-10.8 12.63-10.8c7-4.68 11.7 1.4 11.7 7.49s-4.21.47-6.08 1.87 1.87 14 1.87 14-3.74-.94-5.15-.47 8 17.79 7.49 25.28-4.21.93-5.15 2.34 1.87 7.49 2.81 14-5.15 6.55-5.15 6.55 7.49 25.27 7.49 27.61-7.49-2.8-7.49-2.8a148.06 148.06 0 0 1 3 14.54c5 .36 10.16 1.34 13.31 8 3.83 8.09 12.27 8.19 17.05 9.44 2.6-2.71 3.55-7.73 2.51-17-2.36-21.07-33-49.16-17.3-65.83s27.49-17.53 29.85-52.64Z"
          className="ai"
          style={{
            fill: "#fff",
            opacity: 0.6,
            transformOrigin: "253.253px 252.014px"
          }}
        />
      </g>
      <g
        className="ai"
        style={{
          transformOrigin: "267.66px 396.211px"
        }}
      >
        <path
          d="M304.41 397.1s-49-3.71-37.85 4.46 40.08-5.2 39.34.74-12.62 4.45-.74 5.93 25.23-1.48 29.68.75 7.48 7.39 13 6.29 13.7-2.58 22.61-1.84 15.58 0 4.45-5.94-21.52-3-29.68-7.41c-5.92-3.23-9.27-12.88-16.19-14.59-9.54-2.37-14.27 11.61-24.62 11.61Z"
          className="ai"
          style={{
            fill: "#90caf9",
            transformOrigin: "322.821px 400.321px"
          }}
        />
        <path
          d="M304.41 397.1s-49-3.71-37.85 4.46 40.08-5.2 39.34.74-12.62 4.45-.74 5.93 25.23-1.48 29.68.75 7.48 7.39 13 6.29 13.7-2.58 22.61-1.84 15.58 0 4.45-5.94-21.52-3-29.68-7.41c-5.92-3.23-9.27-12.88-16.19-14.59-9.54-2.37-14.27 11.61-24.62 11.61Z"
          className="ai"
          style={{
            fill: "#fff",
            opacity: 0.6,
            transformOrigin: "322.821px 400.321px"
          }}
        />
        <path
          d="M135.19 398.75c-3.68.77-8.54 1.85-8.54 1.85"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "130.92px 399.675px"
          }}
        />
        <path
          d="M186 377a15.66 15.66 0 0 0-3.13 1c-9.5 4.38-10.23 12.42-10.23 12.42a50.63 50.63 0 0 0-11.64 1.41c-5.11 1.46-6.57 3.65-9.5 5.11-2.39 1.2-8.21.93-12.25 1.19"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "162.625px 387.565px"
          }}
        />
        <path
          d="M400.11 399.82c1.55.05 3 .05 4.18.05a5.47 5.47 0 0 1 4.38 2.19"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "404.39px 400.94px"
          }}
        />
        <path
          d="M352.62 391.76a6.88 6.88 0 0 0 4.91 2.26c7.31.73 15.34-8 23.38-2.92s9.5 7.31 12.42 8c.46.11 1 .21 1.52.29"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "373.735px 394.447px"
          }}
        />
        <path
          d="M328 378.41a26.43 26.43 0 0 1 16.39 3.92 18.12 18.12 0 0 1 5.88 5.93"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "339.135px 383.282px"
          }}
        />
      </g>
      <g
        className="ai snowman__snowman"
        style={{
          transformOrigin: "244.086px 251.565px"
        }}
      >
        <path
          d="M287.39 384.52c14.61 2.93 20.46 10.23 20.46 10.23a9.13 9.13 0 0 1 1.23-.59c25.86-15.9 43-43.54 43-75 0-49.32-42.07-89.29-94-89.29s-94 40-94 89.29c0 38.22 25.28 70.82 60.81 83.56l2.3.1a31.67 31.67 0 0 0 11.17-5.89c14.69-11.68 34.42-15.33 49.03-12.41Z"
          className="ai"
          style={{
            fill: "#fff",
            transformOrigin: "258.08px 316.345px"
          }}
        />
        <g
          className="ai"
          style={{
            clipPath: "url(#a)",
            transformOrigin: "289.615px 315.715px"
          }}
        >
          <path
            d="m258.63 229.89-31.48 15.31s9.54 10.31 28.29 12.19 23.12-3.75 23.12-3.75 8.12 20.62 28.12 27.49 29.6 5.71 37.49 21.87-1.25 27.5-1.25 27.5 3.75 5 1.25 9.37-1.25 6.87-6.87 6.87-11.88-3.12-11.88-3.12a20.92 20.92 0 0 0 7.5 6.25 27.27 27.27 0 0 0 9.38 1.87s-2.5 7.5-14.38 17.5-18.74 12.49-31.86 12.49-25-7.49-43.74 3.75c-11.11 6.67-17.83 12.45-21.46 16.06a33.12 33.12 0 0 0 7.58-4.6c14.61-11.69 34.34-15.34 48.95-12.42s20.46 10.23 20.46 10.23a9.13 9.13 0 0 1 1.23-.59c25.86-15.9 43-43.54 43-75-.01-49.16-41.8-89-93.45-89.27Z"
            className="ai"
            style={{
              fill: "#90caf9",
              opacity: 0.5,
              mixBlendMode: "multiply",
              transformOrigin: "289.615px 315.715px"
            }}
          />
        </g>
        <path
          d="M287.39 384.52c14.61 2.93 20.46 10.23 20.46 10.23a9.13 9.13 0 0 1 1.23-.59c25.86-15.9 43-43.54 43-75 0-49.32-42.07-89.29-94-89.29s-94 40-94 89.29c0 38.22 25.28 70.82 60.81 83.56l2.3.1a31.67 31.67 0 0 0 11.17-5.89c14.69-11.68 34.42-15.33 49.03-12.41Z"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "258.08px 316.345px"
          }}
        />
        <ellipse
          cx={293.31}
          cy={279.46}
          className="ai"
          rx={10.44}
          ry={9.81}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "293.31px 279.46px"
          }}
        />
        <ellipse
          cx={301.54}
          cy={326.93}
          className="ai"
          rx={10.44}
          ry={9.81}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "301.54px 326.93px"
          }}
        />
        <path
          d="M177.83 293.81A25.25 25.25 0 0 0 183.6 312"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "180.674px 302.905px"
          }}
        />
        <path
          d="M186.71 315.1s3.1 3.55 6.65 4.44"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "190.035px 317.32px"
          }}
        />
        <path
          d="M178.72 321.76s3.55 9.32 15.53 13.76"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "186.485px 328.64px"
          }}
        />
        <path
          d="M176.5 308s3.55 10.2 11.54 15.08"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "182.27px 315.54px"
          }}
        />
        <path
          d="M182.71 335.07a29.27 29.27 0 0 0 11.54 5.77"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "188.48px 337.955px"
          }}
        />
        <path
          d="M190.26 359.92s4.88 7.1 21.74 10.21"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "201.13px 365.025px"
          }}
        />
        <path
          d="M205.79 372.79a20.27 20.27 0 0 0 8.87 2.22"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "210.225px 373.9px"
          }}
        />
        <path
          d="m310.72 262.06 53.8-59.5s2.6-31.3 6.4-31.3 5 .92 4.36 6-4.67 20.74-4.67 20.74 10.36-10 16.06-18.19 10.76-20.89 15.82-20.89 4.44 5.07.64 10.76-41.78 44.94-39.25 46.21 28.49-19 29.12-13.93-42.41 28.45-50.64 36.04-28.48 31-28.48 31Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "358.426px 213.96px"
          }}
        />
        <path
          d="m310.72 262.06 53.8-59.5s2.6-31.3 6.4-31.3 5 .92 4.36 6-4.67 20.74-4.67 20.74 10.36-10 16.06-18.19 10.76-20.89 15.82-20.89 4.44 5.07.64 10.76-41.78 44.94-39.25 46.21 28.49-19 29.12-13.93-42.41 28.45-50.64 36.04-28.48 31-28.48 31Z"
          className="ai"
          style={{
            opacity: 0.5,
            transformOrigin: "358.426px 213.96px"
          }}
        />
        <path
          d="m405.69 161.95-5.59 7.14"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "402.895px 165.52px"
          }}
        />
        <path
          d="m398.24 170.64-2.79 3.73"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "396.845px 172.505px"
          }}
        />
        <path
          d="m357.26 220.63-4.97 4.97"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "354.775px 223.115px"
          }}
        />
        <path
          d="m152.35 238.33 16.14 10.55"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "160.42px 243.605px"
          }}
        />
        <path
          d="m170.66 250.12 4.97 3.42"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "173.145px 251.83px"
          }}
        />
        <path
          d="m201.22 262.06-36.72-22.79c-18.35-11.39-31-20.26-31-20.26l-5.06-36.71a26.34 26.34 0 0 0-5.7-.63c-3.17 0-3.8 4.43-3.8 4.43s-1.26-.63 2.53 7.6 4.42 20.3 4.42 20.3-13.89-13.34-23.42-20.3-10.76-11.4-15.82-12-5.7 8.23-3.16 10.76 48.73 34.81 51.9 36.71-18.36-3.8-22.79-5.06-7-.63-5.06 3.16 37.34 9.5 37.34 9.5L194.25 269s5.75 3.18 6.97-6.94Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "141.63px 225.572px"
          }}
        />
        <path
          d="m201.22 262.06-36.72-22.79c-18.35-11.39-31-20.26-31-20.26l-5.06-36.71a26.34 26.34 0 0 0-5.7-.63c-3.17 0-3.8 4.43-3.8 4.43s-1.26-.63 2.53 7.6 4.42 20.3 4.42 20.3-13.89-13.34-23.42-20.3-10.76-11.4-15.82-12-5.7 8.23-3.16 10.76 48.73 34.81 51.9 36.71-18.36-3.8-22.79-5.06-7-.63-5.06 3.16 37.34 9.5 37.34 9.5L194.25 269s5.75 3.18 6.97-6.94Z"
          className="ai"
          style={{
            opacity: 0.5,
            transformOrigin: "141.63px 225.572px"
          }}
        />
        <path
          d="M86.65 181.67s2 .15 2 4.81-1.42 7.87-5.21 6"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "86.045px 187.346px"
          }}
        />
        <path
          d="m89.01 193.62 6.83 5.59"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "92.425px 196.415px"
          }}
        />
        <path
          d="m88.39 188.65 4.34 3.73"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "90.56px 190.515px"
          }}
        />
        <path
          d="M207.47 172.09s-1.12-11.35-7.74-19.3a47 47 0 0 0-13.86-11.47s7.79-27.31 41.58-36.81c25.69-7.22 36-2.94 36-2.94s-1.5 26.44 9.12 42.49c-.01 0-24.29-2.06-65.1 28.03Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "229.22px 136.2px"
          }}
        />
        <path
          d="M195.35 145.48a38.75 38.75 0 0 1 11.9-14.64"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "201.3px 138.16px"
          }}
        />
        <path
          d="M210 129s4.11-2.29 7.32-3.66"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "213.66px 127.17px"
          }}
        />
        <path
          d="M201.3 148.68s5.49-9.15 12.81-13.27"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "207.705px 142.045px"
          }}
        />
        <path
          d="M262.16 123.06a34.31 34.31 0 0 0-15.56-.46"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "254.38px 122.49px"
          }}
        />
        <path
          d="M236.66 131c-15.29 4.66-27.25 14.54-33.82 26.41a43.36 43.36 0 0 1 4.63 14.67c40.81-30.09 65.09-28 65.09-28a49.77 49.77 0 0 1-6.22-14.56 60.14 60.14 0 0 0-29.68 1.48Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "237.7px 150.202px"
          }}
        />
        <ellipse
          cx={248.72}
          cy={172.68}
          className="ai"
          rx={63.79}
          ry={29.38}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "248.72px 172.68px",
            transform: "rotate(-23.46deg)"
          }}
        />
        <ellipse
          cx={249.64}
          cy={175.88}
          className="ai"
          rx={63.79}
          ry={29.38}
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "249.64px 175.88px",
            transform: "rotate(-23.46deg)"
          }}
        />
        <path
          d="M197.5 177.75c8.21-10.46 22.59-21.07 40.44-28.82 25.46-11.05 50.37-13 62.95-6"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "249.195px 158.291px"
          }}
        />
        <path
          d="M191 189.19a34.09 34.09 0 0 1 4-8"
          className="ai"
          style={{
            fill: "none",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "193px 185.19px"
          }}
        />
        <path
          d="M223.3 221.22a6 6 0 0 0-2.19 5.69c.44 3.93 2.19 4.37.88 7.44s-3.07 4.81.43 7.43 11.82 10.07 30.2 10.07 29.32-3.5 36.76-9.63 3.94-8.31 3.94-12.25 3.5-6.13-.88-10.94-12.69 13.97-69.14 2.19Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "257.506px 235.037px"
          }}
        />
        <path
          d="M223.3 221.22a6 6 0 0 0-2.19 5.69c.44 3.93 2.19 4.37.88 7.44s-3.07 4.81.43 7.43 11.82 10.07 30.2 10.07 29.32-3.5 36.76-9.63 3.94-8.31 3.94-12.25 3.5-6.13-.88-10.94-12.69 13.97-69.14 2.19Z"
          className="ai"
          style={{
            opacity: 0.3,
            transformOrigin: "257.506px 235.037px"
          }}
        />
        <path
          d="M276.56 248.9a36.8 36.8 0 0 0 12.82-6.68c.22-.18.43-.35.63-.53-.79-5.63-2.59-12.59-5.63-17.9-.59-1-1.12-1.95-1.63-2.77-7 2.62-19 5.78-41.31 3.2l-8.32 2.36s-4.66 4.2-4.66 7.93v5.12l-1.31 5.57a40.29 40.29 0 0 0 11.71 5l.32-7.32s0-8.85 3.72-10.25 11.19 1.87 11.19 1.87l-.74 17.33a101.25 101.25 0 0 0 14.88-1.06c.47-4.2.82-10.78-.62-16.27l-2.33-8.86 9.78-1.4s2.8 8.39 2.33 15.85c-.22 3.58-.55 6.61-.83 8.81Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "258.58px 236.425px"
          }}
        />
        <path
          d="M296.82 240.91s13.13 12.69 33.26 1.31 23.19-18.82 34.13-20.13a110.49 110.49 0 0 1 20.57-.44l-2.19 21s-7-3.5-28 3.94-31.5 12.69-44.63 8.75-13.14-14.43-13.14-14.43Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "340.8px 239.025px"
          }}
        />
        <path
          d="M296.82 240.91s13.13 12.69 33.26 1.31 23.19-18.82 34.13-20.13a110.49 110.49 0 0 1 20.57-.44l-2.19 21s-7-3.5-28 3.94-31.5 12.69-44.63 8.75-13.14-14.43-13.14-14.43Z"
          className="ai"
          style={{
            opacity: 0.4,
            transformOrigin: "340.8px 239.025px"
          }}
        />
        <path
          d="M369.91 221.58 366 243.24c12.22-2.76 16.6-.58 16.6-.58l2.19-21a110.35 110.35 0 0 0-14.88-.08Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "375.395px 232.304px"
          }}
        />
        <path
          d="M329.8 255.11c5.4-1.42 11.44-3.68 18.86-6.38l5.63-22.93c-4.78 3.06-9.93 7.81-19.86 13.87Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "342.045px 240.455px"
          }}
        />
        <path
          d="M264.87 235.66s11.38-2.66 14.88 4.34 8.32 18.82 26.7 28 40.26 4.38 56 1.31 25.82 0 25.82 0l2.19-26.25s-14.88-10.94-31.07-4.38-37.64 13.57-50.77 7.88-15.31-21-23.19-20.57c.01.01-18.37 6.6-20.56 9.67Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "327.665px 249.659px"
          }}
        />
        <path
          d="M264.87 235.66s11.38-2.66 14.88 4.34 8.32 18.82 26.7 28 40.26 4.38 56 1.31 25.82 0 25.82 0l2.19-26.25s-14.88-10.94-31.07-4.38-37.64 13.57-50.77 7.88-15.31-21-23.19-20.57c.01.01-18.37 6.6-20.56 9.67Z"
          className="ai"
          style={{
            opacity: 0.3,
            transformOrigin: "327.665px 249.659px"
          }}
        />
        <path
          d="m292.27 230.19-14.89 7.06a7.53 7.53 0 0 1 2.37 2.78c1.13 2.27 2.4 5 4.22 8.05l14.72-9.26c-2.53-3.03-4.49-6.2-6.42-8.63Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "288.035px 239.135px"
          }}
        />
        <path
          d="m316.83 271.77 10.53-23.58a42.39 42.39 0 0 1-14.46-.19l-12.69 16.57a70.13 70.13 0 0 0 6.24 3.52 47.79 47.79 0 0 0 10.38 3.68Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "313.785px 259.885px"
          }}
        />
        <path
          d="m367.48 268.56 3.52-31.92a28.5 28.5 0 0 0-11.6 2.08c-2.13.86-4.35 1.73-6.63 2.58l-4.88 30.7c5.2-.79 10.13-1.79 14.57-2.66 1.75-.34 3.42-.58 5.02-.78Z"
          className="ai"
          style={{
            fill: "#263238",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "359.445px 254.313px"
          }}
        />
        <path
          d="M299.87 192.65a44.44 44.44 0 0 1-44.44 44.43c-24.53 0-43.17-15.06-43.17-39.6s23.91-41.89 48.45-41.89 39.16 12.52 39.16 37.06Z"
          className="ai"
          style={{
            fill: "#fff",
            transformOrigin: "256.065px 196.335px"
          }}
        />
        <g
          className="ai"
          style={{
            clipPath: "url(#b)",
            transformOrigin: "256.045px 196.334px"
          }}
        >
          <path
            d="M260.71 155.59c-24.54 0-48.45 17.35-48.45 41.89a43.79 43.79 0 0 0 1 9.2c5.32-7.43 17.8-20.16 43.48-26.15a74.65 74.65 0 0 1 42.38 2.84c-3.39-18.37-17.18-27.78-38.41-27.78Z"
            className="ai"
            style={{
              fill: "#90caf9",
              opacity: 0.5,
              mixBlendMode: "multiply",
              transformOrigin: "255.69px 181.135px"
            }}
          />
          <path
            d="M294.83 213.19a44.25 44.25 0 0 0 5-20.54c0-8-1.56-14.68-4.49-20.1a126.92 126.92 0 0 1-2.46 26.07 27.11 27.11 0 0 0-6.11-8.83c-4.61-4.37-13.84-4.61-15.54 1 0 0 1.21 7.53 5.59 9.71a31.86 31.86 0 0 0 9.95 2.67 13.46 13.46 0 0 0 2.68 3.89c.33.28.59.49.82.67-5.56 15.1-15.32 21.69-27.31 25-11.82 3.28-24.52.53-32.8-2.2a48.6 48.6 0 0 0 25.23 6.54 44.43 44.43 0 0 0 39.4-23.89Z"
            className="ai"
            style={{
              fill: "#90caf9",
              opacity: 0.5,
              mixBlendMode: "multiply",
              transformOrigin: "264.995px 204.814px"
            }}
          />
        </g>
        <path
          d="M299.87 192.65a44.44 44.44 0 0 1-44.44 44.43c-24.53 0-43.17-15.06-43.17-39.6s23.91-41.89 48.45-41.89 39.16 12.52 39.16 37.06Z"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "256.065px 196.335px"
          }}
        />
        <path
          d="M267.63 175c.92 2.39.76 4.68-.34 5.1s-2.76-1.16-3.68-3.56-.76-4.68.34-5.1 2.75 1.15 3.68 3.56Z"
          className="ai"
          style={{
            fill: "#263238",
            transformOrigin: "265.62px 175.77px"
          }}
        />
        <path
          d="M281.39 172.49c.92 2.39.77 4.67-.34 5.1s-2.75-1.17-3.67-3.56-.77-4.68.34-5.11 2.75 1.17 3.67 3.57Z"
          className="ai"
          style={{
            fill: "#263238",
            transformOrigin: "279.385px 173.255px"
          }}
        />
        <path
          d="M261.72 210.1s-17.67-1-14.13-17.66"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "254.421px 201.27px"
          }}
        />
        <path
          d="m252.64 191.93-9.09 1.01"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "248.095px 192.435px"
          }}
        />
        <path
          d="M216.88 191.75s0 3.11 4.44 5.33"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "219.1px 194.415px"
          }}
        />
        <path
          d="M218.21 198.85s3.55 3.11 6.66 3.11"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "221.54px 200.405px"
          }}
        />
        <path
          d="M257.82 168.26a5.88 5.88 0 0 1 6.18-5.09"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "260.91px 165.71px"
          }}
        />
        <path
          d="M275.08 163.17s4.35-2.18 6.53 2.36"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "278.345px 164.088px"
          }}
        />
        <path
          d="M275.89 179.09a12.5 12.5 0 0 0-4.74 6.42c-1.1 4.05.78 10.1 5.55 10.72a20.75 20.75 0 0 0 9.62-1.51 25.71 25.71 0 0 0 10.52-.44c5.71-1.44 5.35-4.53 5.35-4.53s1.64.59 7.3-1.24 6.33-6.22 6.33-6.22 1.2.25 5.7-1.45 8.76-2.19 8.87-4.55-13.58-2.34-16.63-1.59-5.84.28-9.71.73a18.4 18.4 0 0 0-6.43 1.92s-4.86-1.39-7.95-1a28.14 28.14 0 0 0-5 1s-6.82-1.62-8.78 1.74Z"
          className="ai"
          style={{
            fill: "#90caf9",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "300.618px 185.275px"
          }}
        />
        <path
          d="M284.69 177.28a5.39 5.39 0 0 0-2.25 4.76"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "283.559px 179.66px"
          }}
        />
        <path
          d="M282.44 184.42a4.84 4.84 0 0 0 .87 3.26"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "282.864px 186.05px"
          }}
        />
        <path
          d="M298.49 182a6.92 6.92 0 0 0 3.7 7.71"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "300.258px 185.855px"
          }}
        />
        <path
          d="M314.11 176.4s-.65.86.22 3"
          className="ai"
          style={{
            fill: "none",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "314.103px 177.9px"
          }}
        />
      </g>
      <g
        className="ai snowman__snow"
        style={{
          transformOrigin: "248.801px 253.942px"
        }}
      >
        <path
          d="M132.81 332.86c-.55 1.38-3.07 1.67-5.64.65s-4.21-3-3.66-4.35 3.08-1.67 5.65-.65 4.2 2.97 3.65 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "128.16px 331.014px"
          }}
        />
        <path
          d="M112.39 252.91c-.55 1.38-3.08 1.67-5.65.65s-4.2-3-3.65-4.35 3.08-1.67 5.64-.65 4.21 2.96 3.66 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "107.74px 251.064px"
          }}
        />
        <path
          d="M168.56 169c-.55 1.38-3.07 1.67-5.64.65s-4.2-3-3.65-4.35 3.07-1.67 5.64-.65 4.2 3 3.65 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "163.915px 167.154px"
          }}
        />
        <path
          d="M368.67 296.31c-.55 1.38-3.08 1.67-5.65.65s-4.2-3-3.65-4.35 3.08-1.67 5.64-.65 4.21 2.96 3.66 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "364.02px 294.464px"
          }}
        />
        <path
          d="M402.19 139.86c-.55 1.38-3.08 1.67-5.65.65s-4.2-3-3.65-4.35 3.07-1.67 5.64-.65 4.2 2.97 3.66 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "397.538px 138.014px"
          }}
        />
        <path
          d="M230.89 217.51c-.55 1.38-3.07 1.67-5.64.65s-4.2-3-3.65-4.35 3.07-1.67 5.64-.65 4.2 2.97 3.65 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "226.245px 215.664px"
          }}
        />
        <path
          d="M407.11 378.34c-.55 1.38-3.07 1.67-5.64.65s-4.2-3-3.66-4.35 3.08-1.67 5.65-.65 4.2 3.01 3.65 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "402.462px 376.494px"
          }}
        />
        <ellipse
          cx={322.46}
          cy={406.42}
          className="ai"
          rx={6.16}
          ry={5.77}
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "322.46px 406.42px"
          }}
        />
        <path
          d="M351.71 411.43a3.85 3.85 0 1 1-3.85-3.85 3.85 3.85 0 0 1 3.85 3.85Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "347.86px 411.43px"
          }}
        />
        <path
          d="M105.67 156.43c-.54 1.39-3.07 1.68-5.64.66s-4.2-3-3.65-4.35 3.07-1.68 5.64-.66 4.2 2.97 3.65 4.35Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "101.025px 154.591px"
          }}
        />
        <path
          d="M334.19 129.75c-.39 1-2.2 1.2-4 .47s-3-2.12-2.61-3.11 2.2-1.2 4-.47 3 2.12 2.61 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "330.885px 128.431px"
          }}
        />
        <path
          d="M182.87 96.15c-.4 1-2.2 1.2-4 .47s-3-2.12-2.61-3.11 2.2-1.2 4-.47 3 2.12 2.61 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "179.565px 94.8315px"
          }}
        />
        <path
          d="M66.11 285.29c-.39 1-2.2 1.2-4 .47s-3-2.13-2.62-3.12 2.2-1.19 4-.47 3.02 2.13 2.62 3.12Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "62.8029px 283.969px"
          }}
        />
        <path
          d="M438.11 293.9c-.4 1-2.2 1.2-4 .47s-3-2.12-2.62-3.11 2.2-1.2 4-.47 3.01 2.12 2.62 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "434.801px 292.581px"
          }}
        />
        <path
          d="M284.7 103.29c-.39 1-2.2 1.2-4 .47s-3-2.13-2.62-3.12 2.2-1.2 4-.47 3.02 2.13 2.62 3.12Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "281.393px 101.966px"
          }}
        />
        <path
          d="M132.64 344.26c-.39 1-2.2 1.2-4 .47s-3-2.12-2.62-3.11 2.2-1.2 4-.47 2.98 2.12 2.62 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "129.327px 342.941px"
          }}
        />
        <path
          d="M352.72 353.49c-.39 1-2.2 1.2-4 .47s-3-2.12-2.62-3.11 2.2-1.2 4-.47 3.02 2.12 2.62 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "349.413px 352.171px"
          }}
        />
        <path
          d="M262.44 343.71c-.39 1-2.2 1.2-4 .47s-3-2.13-2.62-3.11 2.2-1.2 4-.47 3.02 2.12 2.62 3.11Z"
          className="ai"
          style={{
            fill: "#fff",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transformOrigin: "259.133px 342.393px"
          }}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            d="M287.39 384.52c14.61 2.93 20.46 10.23 20.46 10.23a9.13 9.13 0 0 1 1.23-.59c25.86-15.9 43-43.54 43-75 0-49.32-42.07-89.29-94-89.29s-94 40-94 89.29c0 38.22 25.28 70.82 60.81 83.56l2.3.1a31.67 31.67 0 0 0 11.17-5.89c14.69-11.68 34.42-15.33 49.03-12.41Z"
            style={{
              fill: "#fff",
              stroke: "#263238",
              strokeLinecap: "round",
              strokeLinejoin: "round"
            }}
          />
        </clipPath>
        <clipPath id="b">
          <path
            d="M299.87 192.65a44.44 44.44 0 0 1-44.44 44.43c-24.53 0-43.17-15.06-43.17-39.6s23.91-41.89 48.45-41.89 39.16 12.52 39.16 37.06Z"
            style={{
              fill: "#fff",
              stroke: "#263238",
              strokeLinecap: "round",
              strokeLinejoin: "round"
            }}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Snowman
