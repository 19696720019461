import { join } from "../../utils"

const SnowGlobe = ({ animated = true }) => (
  <svg
    className={join("snow-globe", [animated, "animated"])}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
  >
    <g
      className="ai"
      style={{
        transformOrigin: "251.09px 388.977px"
      }}
    >
      <path
        d="M137 323.11c-31.5 18.19-47.25 42-47.25 65.87s15.75 47.68 47.25 65.87c63 36.37 165.17 36.37 228.18 0 31.44-18.15 47.19-41.93 47.25-65.72s-15.68-47.79-47.25-66c-62.97-36.4-165.13-36.4-228.18-.02Z"
        style={{
          opacity: 0.1,
          transformOrigin: "251.09px 388.977px"
        }}
        className="ai"
      />
    </g>
    <g
      className="snow-globe__plants ai"
      style={{
        transformOrigin: "251.141px 340.06px"
      }}
    >
      <g
        className="snow-globe__plants ai"
        style={{
          transformOrigin: "72.5296px 340.06px"
        }}
      >
        <path
          d="M61.71 306.33c2.53 2.16 3.86 5 2.75 9.63-4.29 1.65-7.41 1-9.94-1.15a5.58 5.58 0 1 1 7.19-8.48Z"
          style={{
            fill: "#187032",
            transformOrigin: "58.5101px 310.783px"
          }}
          className="ai"
        />
        <path
          d="M58.74 324.87c3.32-.25 6.24.82 8.72 4.92-1.9 4.2-4.57 5.92-7.89 6.16a5.57 5.57 0 1 1-.83-11.08Z"
          style={{
            fill: "#187032",
            transformOrigin: "60.3224px 330.423px"
          }}
          className="ai"
        />
        <path
          d="M79.94 341.45c2.83 1.75 4.56 4.33 4.16 9.1-4 2.28-7.17 2.1-10 .36a5.58 5.58 0 1 1 5.84-9.46Z"
          style={{
            fill: "#187032",
            transformOrigin: "77.5914px 346.291px"
          }}
          className="ai"
        />
        <path
          d="M87.4 312.44c0 3.33-1.26 6.17-5.52 8.36-4-2.19-5.58-5-5.6-8.3a5.58 5.58 0 1 1 11.12-.06Z"
          style={{
            fill: "#187032",
            transformOrigin: "81.8375px 313.609px"
          }}
          className="ai"
        />
        <path
          d="M103.73 329.13c-.46 3.29-2.12 5.91-6.65 7.47-3.7-2.74-4.81-5.72-4.35-9a5.57 5.57 0 1 1 11 1.54Z"
          style={{
            fill: "#187032",
            transformOrigin: "98.2455px 329.489px"
          }}
          className="ai"
        />
        <path
          d="M72.15 323.5a96.9 96.9 0 0 1 9.61 9.72 79.71 79.71 0 0 1 8.36 11.86 70.06 70.06 0 0 1 3.59 7.14c.52 1.22.92 2.57 1.39 3.88l-1.21-.68c-2.07-1.18-4.15-2.34-6.23-3.51-1.78-1-3.62-1.92-5.31-3.1-.37-.26-.31.43-.14.6a24.86 24.86 0 0 0 5.51 3.76l6.28 3.56 1.81 1c.57.33.86.48 1.32.72 2 1.06 8.65 3.94 10.75 4.71l.51-1.07A102.84 102.84 0 0 1 96.76 357c-.14-.41-.29-.82-.42-1.21-.42-1.2-.88-2.4-1.37-3.57a70.76 70.76 0 0 0-3.15-6.57 77.23 77.23 0 0 0-8.33-12.19 95.57 95.57 0 0 0-9.61-10 99.28 99.28 0 0 0-5.81-4.84 34.68 34.68 0 0 0-6.67-4.4c-.12-.06-.1.22 0 .27 3.33 3.25 7.24 5.93 10.75 9.01Z"
          style={{
            fill: "#187032",
            transformOrigin: "84.8537px 338.686px"
          }}
          className="ai"
        />
        <path
          d="M68.68 306.15c-.12-.17-.29 0-.27.15a23 23 0 0 0 1 3.71c.49 1.51 1 3 1.49 4.52 1 3.24 1.71 6.53 2.53 9.81.15.59 1.27.94 1.17.1a50.67 50.67 0 0 0-2-8.93c-.96-3.16-1.98-6.64-3.92-9.36Z"
          style={{
            fill: "#187032",
            transformOrigin: "71.5073px 315.509px"
          }}
          className="ai"
        />
        <path
          d="M81.13 317.71c-.1-.22-.36-.14-.31.1.14.73.17 1.46.35 2.19s.36 1.58.55 2.36c.35 1.49.71 3 1 4.48.53 2.93.88 5.89 1.35 8.83.06.38.76.75.79.17a36.31 36.31 0 0 0-.8-9.06c-.29-1.48-.61-3-1-4.43a30.65 30.65 0 0 0-1.93-4.64Z"
          style={{
            fill: "#187032",
            transformOrigin: "82.8496px 326.861px"
          }}
          className="ai"
        />
        <path
          d="M80.11 331.77a31.13 31.13 0 0 0-4.88-1.77 28.36 28.36 0 0 0-5.23-.8 13.86 13.86 0 0 0-5.37.4c-.29.1 0 .62.21.63 1.72.12 3.45 0 5.18.16a31.67 31.67 0 0 1 4.75.72 28.39 28.39 0 0 1 4.61 1.46c.68.28 1.35.59 2 .92a10.65 10.65 0 0 1 1.05.57c.32.23.56.53.88.76.13.09.36.14.42-.07.35-1.44-2.65-2.53-3.62-2.98Z"
          style={{
            fill: "#187032",
            transformOrigin: "74.133px 331.998px"
          }}
          className="ai"
        />
        <path
          d="M97.28 334.66a.17.17 0 0 0-.15 0c-.52.22-.38 1.05-.42 1.52-.06.69-.19 1.38-.3 2.07a29.13 29.13 0 0 1-1 4 30 30 0 0 1-1.58 4c-.48 1-1.57 2.55-1 3.65.11.2.49.53.73.3.45-.43.54-1.23.8-1.8s.61-1.23.91-1.86a27.89 27.89 0 0 0 1.52-4 28.71 28.71 0 0 0 .9-4.09c.22-1.14.55-2.96-.41-3.79Z"
          style={{
            fill: "#187032",
            transformOrigin: "95.2985px 342.46px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.55,
            transformOrigin: "80.2777px 333.918px"
          }}
          className="ai"
        >
          <path
            d="M61.71 306.33c2.53 2.16 3.86 5 2.75 9.63-4.29 1.65-7.41 1-9.94-1.15a5.58 5.58 0 1 1 7.19-8.48Z"
            style={{
              fill: "#fff",
              transformOrigin: "58.5101px 310.783px"
            }}
            className="ai"
          />
          <path
            d="M58.74 324.87c3.32-.25 6.24.82 8.72 4.92-1.9 4.2-4.57 5.92-7.89 6.16a5.57 5.57 0 1 1-.83-11.08Z"
            style={{
              fill: "#fff",
              transformOrigin: "60.3224px 330.423px"
            }}
            className="ai"
          />
          <path
            d="M79.94 341.45c2.83 1.75 4.56 4.33 4.16 9.1-4 2.28-7.17 2.1-10 .36a5.58 5.58 0 1 1 5.84-9.46Z"
            style={{
              fill: "#fff",
              transformOrigin: "77.5914px 346.291px"
            }}
            className="ai"
          />
          <path
            d="M87.4 312.44c0 3.33-1.26 6.17-5.52 8.36-4-2.19-5.58-5-5.6-8.3a5.58 5.58 0 1 1 11.12-.06Z"
            style={{
              fill: "#fff",
              transformOrigin: "81.8375px 313.609px"
            }}
            className="ai"
          />
          <path
            d="M103.73 329.13c-.46 3.29-2.12 5.91-6.65 7.47-3.7-2.74-4.81-5.72-4.35-9a5.57 5.57 0 1 1 11 1.54Z"
            style={{
              fill: "#fff",
              transformOrigin: "98.2455px 329.489px"
            }}
            className="ai"
          />
          <path
            d="M72.15 323.5a96.9 96.9 0 0 1 9.61 9.72 79.71 79.71 0 0 1 8.36 11.86 70.06 70.06 0 0 1 3.59 7.14c.52 1.22.92 2.57 1.39 3.88l-1.21-.68c-2.07-1.18-4.15-2.34-6.23-3.51-1.78-1-3.62-1.92-5.31-3.1-.37-.26-.31.43-.14.6a24.86 24.86 0 0 0 5.51 3.76l6.28 3.56 1.81 1c.57.33.86.48 1.32.72 2 1.06 8.65 3.94 10.75 4.71l.51-1.07A102.84 102.84 0 0 1 96.76 357c-.14-.41-.29-.82-.42-1.21-.42-1.2-.88-2.4-1.37-3.57a70.76 70.76 0 0 0-3.15-6.57 77.23 77.23 0 0 0-8.33-12.19 95.57 95.57 0 0 0-9.61-10 99.28 99.28 0 0 0-5.81-4.84 34.68 34.68 0 0 0-6.67-4.4c-.12-.06-.1.22 0 .27 3.33 3.25 7.24 5.93 10.75 9.01Z"
            style={{
              fill: "#fff",
              transformOrigin: "84.8537px 338.686px"
            }}
            className="ai"
          />
          <path
            d="M68.68 306.15c-.12-.17-.29 0-.27.15a23 23 0 0 0 1 3.71c.49 1.51 1 3 1.49 4.52 1 3.24 1.71 6.53 2.53 9.81.15.59 1.27.94 1.17.1a50.67 50.67 0 0 0-2-8.93c-.96-3.16-1.98-6.64-3.92-9.36Z"
            style={{
              fill: "#fff",
              transformOrigin: "71.5073px 315.509px"
            }}
            className="ai"
          />
          <path
            d="M81.13 317.71c-.1-.22-.36-.14-.31.1.14.73.17 1.46.35 2.19s.36 1.58.55 2.36c.35 1.49.71 3 1 4.48.53 2.93.88 5.89 1.35 8.83.06.38.76.75.79.17a36.31 36.31 0 0 0-.8-9.06c-.29-1.48-.61-3-1-4.43a30.65 30.65 0 0 0-1.93-4.64Z"
            style={{
              fill: "#fff",
              transformOrigin: "82.8496px 326.861px"
            }}
            className="ai"
          />
          <path
            d="M80.11 331.77a31.13 31.13 0 0 0-4.88-1.77 28.36 28.36 0 0 0-5.23-.8 13.86 13.86 0 0 0-5.37.4c-.29.1 0 .62.21.63 1.72.12 3.45 0 5.18.16a31.67 31.67 0 0 1 4.75.72 28.39 28.39 0 0 1 4.61 1.46c.68.28 1.35.59 2 .92a10.65 10.65 0 0 1 1.05.57c.32.23.56.53.88.76.13.09.36.14.42-.07.35-1.44-2.65-2.53-3.62-2.98Z"
            style={{
              fill: "#fff",
              transformOrigin: "74.133px 331.998px"
            }}
            className="ai"
          />
          <path
            d="M97.28 334.66a.17.17 0 0 0-.15 0c-.52.22-.38 1.05-.42 1.52-.06.69-.19 1.38-.3 2.07a29.13 29.13 0 0 1-1 4 30 30 0 0 1-1.58 4c-.48 1-1.57 2.55-1 3.65.11.2.49.53.73.3.45-.43.54-1.23.8-1.8s.61-1.23.91-1.86a27.89 27.89 0 0 0 1.52-4 28.71 28.71 0 0 0 .9-4.09c.22-1.14.55-2.96-.41-3.79Z"
            style={{
              fill: "#fff",
              transformOrigin: "95.2985px 342.46px"
            }}
            className="ai"
          />
        </g>
        <path
          d="M69.23 348.39a73.9 73.9 0 0 1 9.85 6c6 4.35 23.16 23 28 28.54l1.19-.59v-.05c-4.25-6.26-22.63-25.19-28.85-29.43a80.72 80.72 0 0 0-9.69-5.64c-3.51-1.72-7.37-3.53-11.26-4.11-.28 0-.1.47 0 .53 3.6 1.72 7.28 2.99 10.76 4.75Z"
          style={{
            fill: "#187032",
            transformOrigin: "83.2951px 363.02px"
          }}
          className="ai"
        />
        <path
          d="M65.49 346.36c-3.7.81-9 1.28-15.68-3.22s-13.12-7.85-13.81-9.55 10-1.63 18.13 1.25 10 7.22 11.36 11.52Z"
          style={{
            fill: "#187032",
            transformOrigin: "50.7291px 339.674px"
          }}
          className="ai"
        />
        <path
          d="M67.28 352.81c-.4-1.36-4.67-2.46-11.2-.89s-14.07 6-14.08 7 9.59 2 16.22-.35 9.43-4.49 9.06-5.76Z"
          style={{
            fill: "#187032",
            transformOrigin: "54.6561px 355.581px"
          }}
          className="ai"
        />
        <path
          d="M66.73 317.2c-1.18.08-1.6 11.16 1.65 18.58 2.82 6.45 5.15 9.6 6.64 10 0 .09 0 .19.07.28a19.16 19.16 0 0 0 1.37 3.58c1.09 2.18 2.6 4.66 4.82 5.84.14.07.13-.17.09-.23-1.16-2-2.57-3.76-3.6-5.8a27.07 27.07 0 0 1-1.65-4c1.22-1.34 1.74-6-.31-12.57-2.29-7.41-7.9-15.75-9.08-15.68Z"
          style={{
            fill: "#187032",
            transformOrigin: "73.6284px 336.346px"
          }}
          className="ai"
        />
        <path
          d="M84.33 361.68c-.27-1.39-4.42-2.9-11.06-2s-14.59 4.55-14.7 5.57 9.34 3 16.17 1.23 9.83-3.48 9.59-4.8Z"
          style={{
            fill: "#187032",
            transformOrigin: "71.4562px 363.299px"
          }}
          className="ai"
        />
        <path
          d="M77.4 351.7a4.52 4.52 0 0 0-2.74-.71 17.58 17.58 0 0 0-3.39.29 32.22 32.22 0 0 0-6.92 2.13c-.23.09 0 .63.18.6 2.3-.35 4.54-.94 6.82-1.37a17.3 17.3 0 0 1 3.2-.34h1.53c.53 0 1.06.19 1.42-.08.16-.08 0-.42-.1-.52Z"
          style={{
            fill: "#187032",
            transformOrigin: "70.9107px 352.496px"
          }}
          className="ai"
        />
        <path
          d="M85 360.82a14.73 14.73 0 0 0-2.73.31 9.69 9.69 0 0 0-2.54.93c-.2.1-.07.52.15.48A49.2 49.2 0 0 1 85 362a16.58 16.58 0 0 1 2.44.07 3.8 3.8 0 0 1 1.85.64c.45.31.92 0 .61-.53-.9-1.49-3.41-1.38-4.9-1.36Z"
          style={{
            fill: "#187032",
            transformOrigin: "84.8154px 361.822px"
          }}
          className="ai"
        />
        <path
          d="m93.42 366.68 1.27.15c1.55-1.43 3.29-8 2.32-14.38-1.13-7.44-4.67-12.86-5.57-13.25s-3.93 8.3-2.36 16.79c.92 5.01 3.1 9.37 4.34 10.69Z"
          style={{
            fill: "#187032",
            transformOrigin: "92.9631px 353.009px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.15,
            transformOrigin: "72.1191px 350.065px"
          }}
          className="ai"
        >
          <path
            d="M69.23 348.39a73.9 73.9 0 0 1 9.85 6c6 4.35 23.16 23 28 28.54l1.19-.59v-.05c-4.25-6.26-22.63-25.19-28.85-29.43a80.72 80.72 0 0 0-9.69-5.64c-3.51-1.72-7.37-3.53-11.26-4.11-.28 0-.1.47 0 .53 3.6 1.72 7.28 2.99 10.76 4.75Z"
            className="ai"
            style={{
              transformOrigin: "83.2951px 363.02px"
            }}
          />
          <path
            d="M65.49 346.36c-3.7.81-9 1.28-15.68-3.22s-13.12-7.85-13.81-9.55 10-1.63 18.13 1.25 10 7.22 11.36 11.52Z"
            className="ai"
            style={{
              transformOrigin: "50.7291px 339.674px"
            }}
          />
          <path
            d="M67.28 352.81c-.4-1.36-4.67-2.46-11.2-.89s-14.07 6-14.08 7 9.59 2 16.22-.35 9.43-4.49 9.06-5.76Z"
            className="ai"
            style={{
              transformOrigin: "54.6561px 355.581px"
            }}
          />
          <path
            d="M66.73 317.2c-1.18.08-1.6 11.16 1.65 18.58 2.82 6.45 5.15 9.6 6.64 10 0 .09 0 .19.07.28a19.16 19.16 0 0 0 1.37 3.58c1.09 2.18 2.6 4.66 4.82 5.84.14.07.13-.17.09-.23-1.16-2-2.57-3.76-3.6-5.8a27.07 27.07 0 0 1-1.65-4c1.22-1.34 1.74-6-.31-12.57-2.29-7.41-7.9-15.75-9.08-15.68Z"
            className="ai"
            style={{
              transformOrigin: "73.6284px 336.346px"
            }}
          />
          <path
            d="M84.33 361.68c-.27-1.39-4.42-2.9-11.06-2s-14.59 4.55-14.7 5.57 9.34 3 16.17 1.23 9.83-3.48 9.59-4.8Z"
            className="ai"
            style={{
              transformOrigin: "71.4562px 363.299px"
            }}
          />
          <path
            d="M77.4 351.7a4.52 4.52 0 0 0-2.74-.71 17.58 17.58 0 0 0-3.39.29 32.22 32.22 0 0 0-6.92 2.13c-.23.09 0 .63.18.6 2.3-.35 4.54-.94 6.82-1.37a17.3 17.3 0 0 1 3.2-.34h1.53c.53 0 1.06.19 1.42-.08.16-.08 0-.42-.1-.52Z"
            className="ai"
            style={{
              transformOrigin: "70.9107px 352.496px"
            }}
          />
          <path
            d="M85 360.82a14.73 14.73 0 0 0-2.73.31 9.69 9.69 0 0 0-2.54.93c-.2.1-.07.52.15.48A49.2 49.2 0 0 1 85 362a16.58 16.58 0 0 1 2.44.07 3.8 3.8 0 0 1 1.85.64c.45.31.92 0 .61-.53-.9-1.49-3.41-1.38-4.9-1.36Z"
            className="ai"
            style={{
              transformOrigin: "84.8154px 361.822px"
            }}
          />
          <path
            d="m93.42 366.68 1.27.15c1.55-1.43 3.29-8 2.32-14.38-1.13-7.44-4.67-12.86-5.57-13.25s-3.93 8.3-2.36 16.79c.92 5.01 3.1 9.37 4.34 10.69Z"
            className="ai"
            style={{
              transformOrigin: "92.9631px 353.009px"
            }}
          />
        </g>
        <path
          d="M87.83 309.56c-3.52-1.39-8.17-3.94-11.24-11.41s-6.52-13.83-6.16-15.64 9.22 4.22 14.36 11.14 4.3 11.57 3.04 15.91Z"
          style={{
            fill: "#187032",
            transformOrigin: "79.5945px 295.872px"
          }}
          className="ai"
        />
        <path
          d="M95 315.31c1.38.68 4.45-5.93 4.22-12.58s-2.8-11.74-3.55-12.16-4.27 9.37-3.81 14.52 2.21 9.78 3.14 10.22Z"
          style={{
            fill: "#187032",
            transformOrigin: "95.5255px 302.958px"
          }}
          className="ai"
        />
        <path
          d="M101 329.65c1.36 1.08 6.25-5.53 7.49-12.95s-.43-13.68-1.17-14.33-6.82 9.4-7.47 15.22.27 11.41 1.15 12.06Z"
          style={{
            fill: "#187032",
            transformOrigin: "104.283px 316.053px"
          }}
          className="ai"
        />
        <path
          d="M93.5 326.32c1.33-1.13-4.19-7.22-11.22-9.9s-13.49-2.28-14.28-1.68 5.28 7.51 13.44 10.32c5.56 1.94 11.21 1.99 12.06 1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "80.8148px 320.527px"
          }}
          className="ai"
        />
        <path
          d="M106 347.31c-.52-1.76-2.59-8-3.42-10.25l-.3-1.13a23.58 23.58 0 0 1-.41-3.64c0-1.18.06-2.35.14-3.52a17.55 17.55 0 0 0 .29-3.52c0-.16-.27-.54-.45-.33a3.67 3.67 0 0 0-.62 1.51c-.17.64-.33 1.28-.46 1.93a16.54 16.54 0 0 0-.29 3.58c-.77-1.74-1.59-3.46-2.43-5.15-.51-2-1-4.07-1.38-6.15-.24-1.49-.46-3-.6-4.51a30.18 30.18 0 0 0-.54-5.21c0-.16-.29-.43-.41-.19-.68 1.36-.54 3.31-.5 4.8a32.77 32.77 0 0 0 .46 4.94l.18.95a104.71 104.71 0 0 0-10.88-17c-.2-.25-.44-.05-.3.23 3.21 6.58 7.37 12.68 10.76 19.18.65 1.24 1.25 2.49 1.85 3.74a20.54 20.54 0 0 0-3.09-2.39 10.62 10.62 0 0 0-3.83-1.69c-.4-.06-.19.54 0 .66 1.16.81 2.42 1.44 3.56 2.26a22.17 22.17 0 0 1 2.84 2.46c.8.81 1.58 1.63 2.3 2.52.74 1.67 1.78 4.32 2.83 7.11a87.43 87.43 0 0 1 5.58 26.82 141.78 141.78 0 0 1-.22 17.56l1.91.48s2.24-19.09-2.57-36.05Z"
          style={{
            fill: "#187032",
            transformOrigin: "96.5646px 343.833px"
          }}
          className="ai"
        />
        <path
          d="M76.06 383.72c3.33.12 6.2-1.06 8.52-5.26-2.06-4.11-4.8-5.73-8.12-5.84a5.57 5.57 0 1 0-.4 11.1Z"
          style={{
            fill: "#187032",
            transformOrigin: "77.4216px 378.156px"
          }}
          className="ai"
        />
        <path
          d="M88.09 396.1c2.42-2.28 3.59-5.16 2.23-9.76-4.37-1.42-7.45-.6-9.86 1.69a5.57 5.57 0 1 0 7.63 8.07Z"
          style={{
            fill: "#187032",
            transformOrigin: "84.6424px 391.786px"
          }}
          className="ai"
        />
        <path
          d="M87.39 389.21a5 5 0 0 1 1.61-2.32 14.53 14.53 0 0 1 1.85-1.65 19.66 19.66 0 0 1 4.69-2.63 19.93 19.93 0 0 1 4.91-1.32c-.56-.19-1.12-.38-1.68-.55a39.3 39.3 0 0 0-6.25-1.34c-2-.25-4-.25-6.09-.34a11.44 11.44 0 0 1-5.45-1.12c-.13-.08-.07-.19 0-.22a14.66 14.66 0 0 1 3.31 0c1 0 2 0 3.07.05a45.62 45.62 0 0 1 5.71.46l-1.2-.73a15.21 15.21 0 0 1-5.57-5.61c-.16-.31.42-.18.53-.09 1.7 1.44 3.22 3.06 5.06 4.36a38.83 38.83 0 0 0 5.38 2.91c2 .51 8.94 1.45 10.74 2.42l-.62 1.45c-.6 0-6.19-.21-6.78-.12a19.71 19.71 0 0 0-4.86 1.45 23.5 23.5 0 0 0-4.22 2.34c-.62.44-1.21.91-1.8 1.39s-1.19 1.26-1.9 1.43c-.14.08-.46-.03-.44-.22Z"
          style={{
            fill: "#187032",
            transformOrigin: "94.4567px 380.576px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.35,
            transformOrigin: "89.1366px 384.814px"
          }}
          className="ai"
        >
          <path
            d="M76.06 383.72c3.33.12 6.2-1.06 8.52-5.26-2.06-4.11-4.8-5.73-8.12-5.84a5.57 5.57 0 1 0-.4 11.1Z"
            style={{
              fill: "#fff",
              transformOrigin: "77.4216px 378.156px"
            }}
            className="ai"
          />
          <path
            d="M88.09 396.1c2.42-2.28 3.59-5.16 2.23-9.76-4.37-1.42-7.45-.6-9.86 1.69a5.57 5.57 0 1 0 7.63 8.07Z"
            style={{
              fill: "#fff",
              transformOrigin: "84.6424px 391.786px"
            }}
            className="ai"
          />
          <path
            d="M87.39 389.21a5 5 0 0 1 1.61-2.32 14.53 14.53 0 0 1 1.85-1.65 19.66 19.66 0 0 1 4.69-2.63 19.93 19.93 0 0 1 4.91-1.32c-.56-.19-1.12-.38-1.68-.55a39.3 39.3 0 0 0-6.25-1.34c-2-.25-4-.25-6.09-.34a11.44 11.44 0 0 1-5.45-1.12c-.13-.08-.07-.19 0-.22a14.66 14.66 0 0 1 3.31 0c1 0 2 0 3.07.05a45.62 45.62 0 0 1 5.71.46l-1.2-.73a15.21 15.21 0 0 1-5.57-5.61c-.16-.31.42-.18.53-.09 1.7 1.44 3.22 3.06 5.06 4.36a38.83 38.83 0 0 0 5.38 2.91c2 .51 8.94 1.45 10.74 2.42l-.62 1.45c-.6 0-6.19-.21-6.78-.12a19.71 19.71 0 0 0-4.86 1.45 23.5 23.5 0 0 0-4.22 2.34c-.62.44-1.21.91-1.8 1.39s-1.19 1.26-1.9 1.43c-.14.08-.46-.03-.44-.22Z"
            style={{
              fill: "#fff",
              transformOrigin: "94.4567px 380.576px"
            }}
            className="ai"
          />
        </g>
      </g>
      <g
        className="snow-globe__plants ai"
        style={{
          transformOrigin: "429.73px 340.044px"
        }}
      >
        <path
          d="M440.56 306.33c-2.54 2.16-3.87 5-2.76 9.63 4.3 1.65 7.41 1 9.95-1.15a5.58 5.58 0 1 0-7.19-8.48Z"
          style={{
            fill: "#187032",
            transformOrigin: "443.755px 310.783px"
          }}
          className="ai"
        />
        <path
          d="M443.52 324.87c-3.32-.25-6.23.82-8.72 4.92 1.9 4.2 4.58 5.92 7.89 6.16a5.57 5.57 0 1 0 .83-11.08Z"
          style={{
            fill: "#187032",
            transformOrigin: "441.938px 330.423px"
          }}
          className="ai"
        />
        <path
          d="M422.33 341.45c-2.83 1.75-4.57 4.33-4.17 9.1 4 2.28 7.18 2.1 10 .36a5.58 5.58 0 1 0-5.84-9.46Z"
          style={{
            fill: "#187032",
            transformOrigin: "424.669px 346.291px"
          }}
          className="ai"
        />
        <path
          d="M414.87 312.44c0 3.33 1.25 6.17 5.51 8.36 4.05-2.19 5.58-5 5.6-8.3a5.57 5.57 0 1 0-11.11-.06Z"
          style={{
            fill: "#187032",
            transformOrigin: "420.427px 313.646px"
          }}
          className="ai"
        />
        <path
          d="M398.53 329.13c.46 3.29 2.13 5.91 6.66 7.47 3.69-2.74 4.81-5.72 4.35-9a5.58 5.58 0 1 0-11 1.54Z"
          style={{
            fill: "#187032",
            transformOrigin: "404.011px 329.427px"
          }}
          className="ai"
        />
        <path
          d="M430.12 323.5a95.84 95.84 0 0 0-9.61 9.72 79 79 0 0 0-8.36 11.86 68.06 68.06 0 0 0-3.59 7.14c-.53 1.22-.93 2.57-1.4 3.88l1.21-.68c2.07-1.18 4.15-2.34 6.23-3.51a59.79 59.79 0 0 0 5.31-3.1c.38-.26.31.43.14.6a24.86 24.86 0 0 1-5.51 3.76 922.44 922.44 0 0 1-6.23 3.56l-1.81 1c-.57.33-.86.48-1.32.72-2 1.06-8.65 3.94-10.75 4.71l-.5-1.07a103.64 103.64 0 0 0 11.58-5.09c.13-.41.28-.82.41-1.21.42-1.2.88-2.4 1.37-3.57a68.38 68.38 0 0 1 3.16-6.57 76.5 76.5 0 0 1 8.32-12.19 94.66 94.66 0 0 1 9.62-10c1.87-1.69 3.81-3.3 5.8-4.84a34.68 34.68 0 0 1 6.67-4.4c.12-.06.1.22.05.27-3.38 3.25-7.29 5.93-10.79 9.01Z"
          style={{
            fill: "#187032",
            transformOrigin: "417.44px 338.686px"
          }}
          className="ai"
        />
        <path
          d="M433.58 306.15c.13-.17.29 0 .27.15a21.14 21.14 0 0 1-1.05 3.7c-.49 1.51-1 3-1.49 4.52-1 3.24-1.7 6.53-2.53 9.81-.15.59-1.27.94-1.17.1a50.67 50.67 0 0 1 2-8.93c1.01-3.15 2.03-6.63 3.97-9.35Z"
          style={{
            fill: "#187032",
            transformOrigin: "430.728px 315.504px"
          }}
          className="ai"
        />
        <path
          d="M421.13 317.71c.11-.22.36-.14.32.1-.14.73-.18 1.46-.35 2.19s-.37 1.58-.55 2.36c-.36 1.49-.71 3-1 4.48-.53 2.93-.88 5.89-1.34 8.83-.06.38-.77.75-.8.17a37.28 37.28 0 0 1 .8-9.06c.3-1.48.61-3 1-4.43a30.65 30.65 0 0 1 1.92-4.64Z"
          style={{
            fill: "#187032",
            transformOrigin: "419.421px 326.861px"
          }}
          className="ai"
        />
        <path
          d="M422.15 331.77A31.13 31.13 0 0 1 427 330a28.49 28.49 0 0 1 5.27-.83 13.91 13.91 0 0 1 5.38.4c.29.1 0 .62-.21.63-1.73.12-3.46 0-5.19.16a31.67 31.67 0 0 0-4.75.72 28.71 28.71 0 0 0-4.61 1.46c-.68.28-1.35.59-2 .92a9.78 9.78 0 0 0-1.06.57c-.32.23-.56.53-.88.76-.13.09-.36.14-.42-.07-.35-1.41 2.65-2.5 3.62-2.95Z"
          style={{
            fill: "#187032",
            transformOrigin: "428.137px 331.968px"
          }}
          className="ai"
        />
        <path
          d="M405 334.66a.16.16 0 0 1 .15 0c.52.22.37 1.05.41 1.52.06.69.19 1.38.31 2.07a29.1 29.1 0 0 0 1 4 31.41 31.41 0 0 0 1.58 4c.48 1 1.57 2.55 1 3.65-.11.2-.49.53-.72.3-.46-.43-.55-1.23-.81-1.8s-.61-1.23-.91-1.86a29.14 29.14 0 0 1-1.52-4 27.37 27.37 0 0 1-.89-4.09c-.25-1.14-.6-2.96.4-3.79Z"
          style={{
            fill: "#187032",
            transformOrigin: "406.971px 342.46px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.55,
            transformOrigin: "422.017px 333.918px"
          }}
          className="ai"
        >
          <path
            d="M440.56 306.33c-2.54 2.16-3.87 5-2.76 9.63 4.3 1.65 7.41 1 9.95-1.15a5.58 5.58 0 1 0-7.19-8.48Z"
            style={{
              fill: "#fff",
              transformOrigin: "443.755px 310.783px"
            }}
            className="ai"
          />
          <path
            d="M443.52 324.87c-3.32-.25-6.23.82-8.72 4.92 1.9 4.2 4.58 5.92 7.89 6.16a5.57 5.57 0 1 0 .83-11.08Z"
            style={{
              fill: "#fff",
              transformOrigin: "441.938px 330.423px"
            }}
            className="ai"
          />
          <path
            d="M422.33 341.45c-2.83 1.75-4.57 4.33-4.17 9.1 4 2.28 7.18 2.1 10 .36a5.58 5.58 0 1 0-5.84-9.46Z"
            style={{
              fill: "#fff",
              transformOrigin: "424.669px 346.291px"
            }}
            className="ai"
          />
          <path
            d="M414.87 312.44c0 3.33 1.25 6.17 5.51 8.36 4.05-2.19 5.58-5 5.6-8.3a5.57 5.57 0 1 0-11.11-.06Z"
            style={{
              fill: "#fff",
              transformOrigin: "420.427px 313.646px"
            }}
            className="ai"
          />
          <path
            d="M398.53 329.13c.46 3.29 2.13 5.91 6.66 7.47 3.69-2.74 4.81-5.72 4.35-9a5.58 5.58 0 1 0-11 1.54Z"
            style={{
              fill: "#fff",
              transformOrigin: "404.011px 329.427px"
            }}
            className="ai"
          />
          <path
            d="M430.12 323.5a95.84 95.84 0 0 0-9.61 9.72 79 79 0 0 0-8.36 11.86 68.06 68.06 0 0 0-3.59 7.14c-.53 1.22-.93 2.57-1.4 3.88l1.21-.68c2.07-1.18 4.15-2.34 6.23-3.51a59.79 59.79 0 0 0 5.31-3.1c.38-.26.31.43.14.6a24.86 24.86 0 0 1-5.51 3.76 922.44 922.44 0 0 1-6.23 3.56l-1.81 1c-.57.33-.86.48-1.32.72-2 1.06-8.65 3.94-10.75 4.71l-.5-1.07a103.64 103.64 0 0 0 11.58-5.09c.13-.41.28-.82.41-1.21.42-1.2.88-2.4 1.37-3.57a68.38 68.38 0 0 1 3.16-6.57 76.5 76.5 0 0 1 8.32-12.19 94.66 94.66 0 0 1 9.62-10c1.87-1.69 3.81-3.3 5.8-4.84a34.68 34.68 0 0 1 6.67-4.4c.12-.06.1.22.05.27-3.38 3.25-7.29 5.93-10.79 9.01Z"
            style={{
              fill: "#fff",
              transformOrigin: "417.44px 338.686px"
            }}
            className="ai"
          />
          <path
            d="M433.58 306.15c.13-.17.29 0 .27.15a21.14 21.14 0 0 1-1.05 3.7c-.49 1.51-1 3-1.49 4.52-1 3.24-1.7 6.53-2.53 9.81-.15.59-1.27.94-1.17.1a50.67 50.67 0 0 1 2-8.93c1.01-3.15 2.03-6.63 3.97-9.35Z"
            style={{
              fill: "#fff",
              transformOrigin: "430.728px 315.504px"
            }}
            className="ai"
          />
          <path
            d="M421.13 317.71c.11-.22.36-.14.32.1-.14.73-.18 1.46-.35 2.19s-.37 1.58-.55 2.36c-.36 1.49-.71 3-1 4.48-.53 2.93-.88 5.89-1.34 8.83-.06.38-.77.75-.8.17a37.28 37.28 0 0 1 .8-9.06c.3-1.48.61-3 1-4.43a30.65 30.65 0 0 1 1.92-4.64Z"
            style={{
              fill: "#fff",
              transformOrigin: "419.421px 326.861px"
            }}
            className="ai"
          />
          <path
            d="M422.15 331.77A31.13 31.13 0 0 1 427 330a28.49 28.49 0 0 1 5.27-.83 13.91 13.91 0 0 1 5.38.4c.29.1 0 .62-.21.63-1.73.12-3.46 0-5.19.16a31.67 31.67 0 0 0-4.75.72 28.71 28.71 0 0 0-4.61 1.46c-.68.28-1.35.59-2 .92a9.78 9.78 0 0 0-1.06.57c-.32.23-.56.53-.88.76-.13.09-.36.14-.42-.07-.35-1.41 2.65-2.5 3.62-2.95Z"
            style={{
              fill: "#fff",
              transformOrigin: "428.137px 331.968px"
            }}
            className="ai"
          />
          <path
            d="M405 334.66a.16.16 0 0 1 .15 0c.52.22.37 1.05.41 1.52.06.69.19 1.38.31 2.07a29.1 29.1 0 0 0 1 4 31.41 31.41 0 0 0 1.58 4c.48 1 1.57 2.55 1 3.65-.11.2-.49.53-.72.3-.46-.43-.55-1.23-.81-1.8s-.61-1.23-.91-1.86a29.14 29.14 0 0 1-1.52-4 27.37 27.37 0 0 1-.89-4.09c-.25-1.14-.6-2.96.4-3.79Z"
            style={{
              fill: "#fff",
              transformOrigin: "406.971px 342.46px"
            }}
            className="ai"
          />
        </g>
        <path
          d="M433 348.39a74.54 74.54 0 0 0-9.85 6c-6 4.35-23.16 23-28 28.54l-1.18-.59v-.05c4.26-6.26 22.63-25.19 28.86-29.43a80 80 0 0 1 9.69-5.64c3.5-1.72 7.37-3.53 11.25-4.11.28 0 .11.47 0 .53-3.58 1.72-7.26 2.99-10.77 4.75Z"
          style={{
            fill: "#187032",
            transformOrigin: "418.946px 363.02px"
          }}
          className="ai"
        />
        <path
          d="M436.77 346.36c3.7.81 9 1.28 15.68-3.22s13.12-7.85 13.83-9.55-10-1.63-18.13 1.25-10.01 7.22-11.38 11.52Z"
          style={{
            fill: "#187032",
            transformOrigin: "451.542px 339.674px"
          }}
          className="ai"
        />
        <path
          d="M435 352.81c.4-1.36 4.68-2.46 11.2-.89s14.08 6 14.08 7-9.59 2-16.21-.35-9.46-4.49-9.07-5.76Z"
          style={{
            fill: "#187032",
            transformOrigin: "447.622px 355.581px"
          }}
          className="ai"
        />
        <path
          d="M435.53 317.2c1.18.08 1.6 11.16-1.64 18.58-2.82 6.45-5.15 9.6-6.65 10 0 .09 0 .19-.07.28a18.45 18.45 0 0 1-1.36 3.58c-1.1 2.18-2.61 4.66-4.83 5.84-.14.07-.13-.17-.09-.23 1.17-2 2.58-3.76 3.6-5.8a27.07 27.07 0 0 0 1.65-4c-1.22-1.34-1.74-6 .31-12.57 2.3-7.41 7.91-15.75 9.08-15.68Z"
          style={{
            fill: "#187032",
            transformOrigin: "428.632px 336.346px"
          }}
          className="ai"
        />
        <path
          d="M417.94 361.68c.26-1.39 4.41-2.9 11.05-2s14.6 4.55 14.7 5.57-9.34 3-16.17 1.23-9.83-3.48-9.58-4.8Z"
          style={{
            fill: "#187032",
            transformOrigin: "430.808px 363.299px"
          }}
          className="ai"
        />
        <path
          d="M424.87 351.7a4.47 4.47 0 0 1 2.73-.71 17.51 17.51 0 0 1 3.39.29 32.22 32.22 0 0 1 6.92 2.13c.23.09 0 .63-.18.6-2.3-.35-4.54-.94-6.82-1.37a17.12 17.12 0 0 0-3.2-.34h-1.53c-.53 0-1.05.19-1.42-.08-.16-.08 0-.42.11-.52Z"
          style={{
            fill: "#187032",
            transformOrigin: "431.35px 352.496px"
          }}
          className="ai"
        />
        <path
          d="M417.29 360.82a14.72 14.72 0 0 1 2.72.31 9.69 9.69 0 0 1 2.54.93c.2.1.07.52-.15.48a48.94 48.94 0 0 0-5.13-.5 16.58 16.58 0 0 0-2.44.07 3.8 3.8 0 0 0-1.85.64c-.45.31-.91 0-.61-.53.89-1.53 3.4-1.42 4.92-1.4Z"
          style={{
            fill: "#187032",
            transformOrigin: "417.462px 361.842px"
          }}
          className="ai"
        />
        <path
          d="m408.84 366.68-1.27.15c-1.54-1.43-3.29-8-2.32-14.38 1.14-7.44 4.68-12.86 5.58-13.25s3.93 8.3 2.35 16.79c-.93 5.01-3.09 9.37-4.34 10.69Z"
          style={{
            fill: "#187032",
            transformOrigin: "409.3px 353.009px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.15,
            transformOrigin: "430.142px 350.065px"
          }}
          className="ai"
        >
          <path
            d="M433 348.39a74.54 74.54 0 0 0-9.85 6c-6 4.35-23.16 23-28 28.54l-1.18-.59v-.05c4.26-6.26 22.63-25.19 28.86-29.43a80 80 0 0 1 9.69-5.64c3.5-1.72 7.37-3.53 11.25-4.11.28 0 .11.47 0 .53-3.58 1.72-7.26 2.99-10.77 4.75Z"
            className="ai"
            style={{
              transformOrigin: "418.946px 363.02px"
            }}
          />
          <path
            d="M436.77 346.36c3.7.81 9 1.28 15.68-3.22s13.12-7.85 13.83-9.55-10-1.63-18.13 1.25-10.01 7.22-11.38 11.52Z"
            className="ai"
            style={{
              transformOrigin: "451.542px 339.674px"
            }}
          />
          <path
            d="M435 352.81c.4-1.36 4.68-2.46 11.2-.89s14.08 6 14.08 7-9.59 2-16.21-.35-9.46-4.49-9.07-5.76Z"
            className="ai"
            style={{
              transformOrigin: "447.622px 355.581px"
            }}
          />
          <path
            d="M435.53 317.2c1.18.08 1.6 11.16-1.64 18.58-2.82 6.45-5.15 9.6-6.65 10 0 .09 0 .19-.07.28a18.45 18.45 0 0 1-1.36 3.58c-1.1 2.18-2.61 4.66-4.83 5.84-.14.07-.13-.17-.09-.23 1.17-2 2.58-3.76 3.6-5.8a27.07 27.07 0 0 0 1.65-4c-1.22-1.34-1.74-6 .31-12.57 2.3-7.41 7.91-15.75 9.08-15.68Z"
            className="ai"
            style={{
              transformOrigin: "428.632px 336.346px"
            }}
          />
          <path
            d="M417.94 361.68c.26-1.39 4.41-2.9 11.05-2s14.6 4.55 14.7 5.57-9.34 3-16.17 1.23-9.83-3.48-9.58-4.8Z"
            className="ai"
            style={{
              transformOrigin: "430.808px 363.299px"
            }}
          />
          <path
            d="M424.87 351.7a4.47 4.47 0 0 1 2.73-.71 17.51 17.51 0 0 1 3.39.29 32.22 32.22 0 0 1 6.92 2.13c.23.09 0 .63-.18.6-2.3-.35-4.54-.94-6.82-1.37a17.12 17.12 0 0 0-3.2-.34h-1.53c-.53 0-1.05.19-1.42-.08-.16-.08 0-.42.11-.52Z"
            className="ai"
            style={{
              transformOrigin: "431.35px 352.496px"
            }}
          />
          <path
            d="M417.29 360.82a14.72 14.72 0 0 1 2.72.31 9.69 9.69 0 0 1 2.54.93c.2.1.07.52-.15.48a48.94 48.94 0 0 0-5.13-.5 16.58 16.58 0 0 0-2.44.07 3.8 3.8 0 0 0-1.85.64c-.45.31-.91 0-.61-.53.89-1.53 3.4-1.42 4.92-1.4Z"
            className="ai"
            style={{
              transformOrigin: "417.462px 361.842px"
            }}
          />
          <path
            d="m408.84 366.68-1.27.15c-1.54-1.43-3.29-8-2.32-14.38 1.14-7.44 4.68-12.86 5.58-13.25s3.93 8.3 2.35 16.79c-.93 5.01-3.09 9.37-4.34 10.69Z"
            className="ai"
            style={{
              transformOrigin: "409.3px 353.009px"
            }}
          />
        </g>
        <path
          d="M414.44 309.56c3.52-1.39 8.16-3.94 11.23-11.41s6.52-13.83 6.17-15.64-9.22 4.22-14.36 11.14-4.31 11.57-3.04 15.91Z"
          style={{
            fill: "#187032",
            transformOrigin: "422.673px 295.872px"
          }}
          className="ai"
        />
        <path
          d="M407.31 315.31c-1.38.68-4.46-5.93-4.23-12.58s2.8-11.74 3.56-12.16 4.26 9.37 3.8 14.52-2.24 9.78-3.13 10.22Z"
          style={{
            fill: "#187032",
            transformOrigin: "406.775px 302.958px"
          }}
          className="ai"
        />
        <path
          d="M401.26 329.65c-1.36 1.08-6.25-5.53-7.49-12.95s.44-13.68 1.17-14.33 6.82 9.4 7.47 15.22-.26 11.41-1.15 12.06Z"
          style={{
            fill: "#187032",
            transformOrigin: "397.979px 316.053px"
          }}
          className="ai"
        />
        <path
          d="M408.76 326.32c-1.32-1.13 4.19-7.22 11.22-9.9s13.5-2.28 14.28-1.68-5.29 7.51-13.45 10.32c-5.54 1.94-11.2 1.99-12.05 1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "421.446px 320.527px"
          }}
          className="ai"
        />
        <path
          d="M396.22 347.31c.52-1.76 2.6-8 3.42-10.25.09-.31.22-.82.31-1.13a23.57 23.57 0 0 0 .4-3.64c0-1.18-.06-2.35-.14-3.52a17.55 17.55 0 0 1-.29-3.52c0-.16.27-.54.45-.33a3.42 3.42 0 0 1 .62 1.51c.18.64.33 1.28.46 1.93a16.54 16.54 0 0 1 .29 3.58q1.17-2.61 2.44-5.15c.5-2 1-4.07 1.37-6.15.24-1.49.46-3 .61-4.51a30.18 30.18 0 0 1 .53-5.21c0-.16.29-.43.41-.19.68 1.36.54 3.31.51 4.8a32.78 32.78 0 0 1-.47 4.94c-.05.31-.12.63-.18.95a105.39 105.39 0 0 1 10.88-17c.21-.25.44-.05.31.23-3.22 6.58-7.38 12.68-10.77 19.18-.64 1.24-1.24 2.49-1.85 3.74a21.06 21.06 0 0 1 3.09-2.39 10.7 10.7 0 0 1 3.83-1.69c.41-.06.2.54 0 .66-1.15.81-2.42 1.44-3.56 2.26a22.17 22.17 0 0 0-2.84 2.46c-.8.81-1.58 1.63-2.3 2.52-.74 1.67-1.78 4.32-2.83 7.11a87.72 87.72 0 0 0-5.58 26.82 141.78 141.78 0 0 0 .22 17.56l-1.91.48s-2.19-19.09 2.57-36.05Z"
          style={{
            fill: "#187032",
            transformOrigin: "405.667px 343.833px"
          }}
          className="ai"
        />
        <path
          d="M426.2 383.72c-3.32.12-6.2-1.06-8.52-5.26 2.06-4.11 4.8-5.73 8.12-5.84a5.57 5.57 0 1 1 .4 11.1Z"
          style={{
            fill: "#187032",
            transformOrigin: "424.838px 378.156px"
          }}
          className="ai"
        />
        <path
          d="M414.17 396.1c-2.41-2.28-3.59-5.16-2.23-9.76 4.38-1.42 7.45-.6 9.87 1.69a5.57 5.57 0 1 1-7.64 8.07Z"
          style={{
            fill: "#187032",
            transformOrigin: "417.604px 391.77px"
          }}
          className="ai"
        />
        <path
          d="M414.87 389.21a5 5 0 0 0-1.55-2.32 15.34 15.34 0 0 0-1.86-1.65 21.13 21.13 0 0 0-9.59-3.95c.55-.19 1.11-.38 1.68-.55a39.18 39.18 0 0 1 6.24-1.34c2-.25 4-.25 6.09-.34a11.44 11.44 0 0 0 5.45-1.12c.13-.08.07-.19-.05-.22a14.66 14.66 0 0 0-3.31 0c-1 0-2 0-3.07.05a45.31 45.31 0 0 0-5.7.46c.41-.24.81-.49 1.19-.73a15.32 15.32 0 0 0 5.58-5.61c.15-.31-.43-.18-.53-.09-1.71 1.44-3.23 3.06-5.07 4.36a38.83 38.83 0 0 1-5.37 2.94c-2 .51-8.94 1.45-10.73 2.42l.62 1.45c.59 0 6.18-.21 6.77-.12a19.71 19.71 0 0 1 4.86 1.45 23.5 23.5 0 0 1 4.22 2.34c.62.44 1.21.91 1.8 1.39s1.19 1.26 1.9 1.43c.13.05.45-.06.43-.25Z"
          style={{
            fill: "#187032",
            transformOrigin: "407.837px 380.583px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.35,
            transformOrigin: "413.133px 384.799px"
          }}
          className="ai"
        >
          <path
            d="M426.2 383.72c-3.32.12-6.2-1.06-8.52-5.26 2.06-4.11 4.8-5.73 8.12-5.84a5.57 5.57 0 1 1 .4 11.1Z"
            style={{
              fill: "#fff",
              transformOrigin: "424.838px 378.156px"
            }}
            className="ai"
          />
          <path
            d="M414.17 396.1c-2.41-2.28-3.59-5.16-2.23-9.76 4.38-1.42 7.45-.6 9.87 1.69a5.57 5.57 0 1 1-7.64 8.07Z"
            style={{
              fill: "#fff",
              transformOrigin: "417.604px 391.77px"
            }}
            className="ai"
          />
          <path
            d="M414.87 389.21a5 5 0 0 0-1.55-2.32 15.34 15.34 0 0 0-1.86-1.65 21.13 21.13 0 0 0-9.59-3.95c.55-.19 1.11-.38 1.68-.55a39.18 39.18 0 0 1 6.24-1.34c2-.25 4-.25 6.09-.34a11.44 11.44 0 0 0 5.45-1.12c.13-.08.07-.19-.05-.22a14.66 14.66 0 0 0-3.31 0c-1 0-2 0-3.07.05a45.31 45.31 0 0 0-5.7.46c.41-.24.81-.49 1.19-.73a15.32 15.32 0 0 0 5.58-5.61c.15-.31-.43-.18-.53-.09-1.71 1.44-3.23 3.06-5.07 4.36a38.83 38.83 0 0 1-5.37 2.94c-2 .51-8.94 1.45-10.73 2.42l.62 1.45c.59 0 6.18-.21 6.77-.12a19.71 19.71 0 0 1 4.86 1.45 23.5 23.5 0 0 1 4.22 2.34c.62.44 1.21.91 1.8 1.39s1.19 1.26 1.9 1.43c.13.05.45-.06.43-.25Z"
            style={{
              fill: "#fff",
              transformOrigin: "407.837px 380.583px"
            }}
            className="ai"
          />
        </g>
      </g>
    </g>
    <g
      className="ai"
      style={{
        transformOrigin: "251.13px 211.125px"
      }}
    >
      <g
        className="ai"
        style={{
          transformOrigin: "251.13px 211.125px"
        }}
      >
        <path
          d="M251.13 273.55c-57.13 0-92.57 11.4-123.86 42.39-22.41 22.19-23.74 52.34-23.75 57.66 0 21.81 14.41 43.62 43.23 60.26s66.6 25 104.38 25 75.56-8.32 104.38-25 43.24-38.45 43.24-60.26c0-5.32-1.34-35.47-23.74-57.66-31.31-30.94-66.74-42.39-123.88-42.39Z"
          style={{
            fill: "#37474f",
            transformOrigin: "251.135px 366.205px"
          }}
          className="ai"
        />
        <path
          d="M368.08 288.24c.05-17.31-11.37-34.64-34.25-47.85-45.68-26.37-119.73-26.37-165.4 0-22.83 13.18-34.25 30.46-34.25 47.75v35c0 17.28 11.42 34.56 34.25 47.75 45.68 26.37 119.73 26.37 165.4 0 22.79-13.16 34.21-30.39 34.25-47.64.01-3.96-.01-31.12 0-35.01Z"
          style={{
            fill: "#455a64",
            transformOrigin: "251.131px 305.64px"
          }}
          className="ai"
        />
        <path
          d="M168.43 240.39c-22.84 13.18-34.25 30.46-34.25 47.75s11.42 34.56 34.25 47.74c45.68 26.37 119.73 26.37 165.4 0 22.79-13.16 34.21-30.39 34.25-47.64s-11.37-34.64-34.25-47.85c-45.68-26.39-119.73-26.39-165.4 0Z"
          style={{
            fill: "#37474f",
            transformOrigin: "251.13px 288.128px"
          }}
          className="ai"
        />
        <g
          className="ai"
          style={{
            transformOrigin: "251.13px 196.89px"
          }}
        >
          <path
            d="M251.13 23.31a173.58 173.58 0 1 0 173.58 173.58A173.57 173.57 0 0 0 251.13 23.31Zm0 338.69a165.11 165.11 0 1 1 165.11-165.11A165.11 165.11 0 0 1 251.13 362Z"
            style={{
              fill: "#187032",
              transformOrigin: "251.13px 196.89px"
            }}
            className="ai"
          />
          <path
            d="M251.13 23.31a173.58 173.58 0 1 0 173.58 173.58A173.57 173.57 0 0 0 251.13 23.31Zm0 338.69a165.11 165.11 0 1 1 165.11-165.11A165.11 165.11 0 0 1 251.13 362Z"
            style={{
              fill: "#fff",
              opacity: 0.7,
              transformOrigin: "251.13px 196.89px"
            }}
            className="ai"
          />
          <circle
            cx={251.13}
            cy={196.89}
            r={165.11}
            style={{
              fill: "#187032",
              opacity: 0.1,
              transformOrigin: "251.13px 196.89px",
              transform: "rotate(-45deg)"
            }}
            className="ai"
          />
        </g>
        <path
          d="M377.58 288.59c0-39.07-48.84-80.35-126.45-80.35s-126.45 41.28-126.45 80.35a44.85 44.85 0 0 0 4.37 19.47 164.63 164.63 0 0 0 117.61 53.87c1.48 0 3 .06 4.45.06 1.49 0 3 0 4.46-.07a164.66 164.66 0 0 0 117.63-53.85 44.89 44.89 0 0 0 4.37-19.48Z"
          style={{
            fill: "#fff",
            transformOrigin: "251.13px 285.115px"
          }}
          className="ai"
        />
        <path
          d="m303.22 407.16 4 21.26c-6.51 1.24-11.12 6.74-10.24 12.58a9.83 9.83 0 0 0 .28 1.22c-15.82 3.2-31.62 5.4-47.31 5.4s-31.49-2.2-47.31-5.4A8.31 8.31 0 0 0 203 441c.88-5.84-3.73-11.34-10.24-12.58l4.05-21.26a10.62 10.62 0 0 0 12.33-7.49c14 2.95 27.21 5.13 40.89 5.13s26.92-2.18 40.89-5.13a10.62 10.62 0 0 0 12.3 7.49Z"
          style={{
            fill: "#187032",
            transformOrigin: "249.99px 423.645px"
          }}
          className="ai"
        />
        <path
          d="m303.22 407.16 4 21.26c-6.51 1.24-11.12 6.74-10.24 12.58a9.83 9.83 0 0 0 .28 1.22c-15.82 3.2-31.62 5.4-47.31 5.4s-31.49-2.2-47.31-5.4A8.31 8.31 0 0 0 203 441c.88-5.84-3.73-11.34-10.24-12.58l4.05-21.26a10.62 10.62 0 0 0 12.33-7.49c14 2.95 27.21 5.13 40.89 5.13s26.92-2.18 40.89-5.13a10.62 10.62 0 0 0 12.3 7.49Z"
          style={{
            fill: "#fff",
            opacity: 0.5,
            transformOrigin: "249.99px 423.645px"
          }}
          className="ai"
        />
        <path
          d="m208 438.16-.43-.07a20.57 20.57 0 0 0-10.14-13.49c.77-4.66 1.16-7 1.93-11.64a18 18 0 0 0 10.71-6.7c13.55 2.49 26.61 4.36 39.89 4.36s26.33-1.88 39.89-4.37A18 18 0 0 0 300.6 413l1.92 11.64a20.57 20.57 0 0 0-10.14 13.49l-.43.07a259.11 259.11 0 0 1-83.95-.04Z"
          style={{
            fill: "#187032",
            transformOrigin: "249.975px 423.926px"
          }}
          className="ai"
        />
        <path
          d="m208 438.16-.43-.07a20.57 20.57 0 0 0-10.14-13.49c.77-4.66 1.16-7 1.93-11.64a18 18 0 0 0 10.71-6.7c13.55 2.49 26.61 4.36 39.89 4.36s26.33-1.88 39.89-4.37A18 18 0 0 0 300.6 413l1.92 11.64a20.57 20.57 0 0 0-10.14 13.49l-.43.07a259.11 259.11 0 0 1-83.95-.04Z"
          style={{
            fill: "#fff",
            opacity: 0.2,
            transformOrigin: "249.975px 423.926px"
          }}
          className="ai"
        />
        <path
          d="M303.22 407.16c-8.89 3.5-13.21-6-13.21-6-11.44 2.52-27.75 5.07-41.2 4.71-13-.35-26.87-1.8-39.7-6.17 14 2.95 27.21 5.13 40.89 5.13s26.92-2.18 40.89-5.13a10.62 10.62 0 0 0 12.33 7.46Z"
          style={{
            fill: "#fff",
            transformOrigin: "256.165px 403.817px"
          }}
          className="ai"
        />
        <path
          d="M237.35 423.13c-1.15-3.15-1.7-4.73-2.76-7.89-.15 2.4-.23 3.61-.38 6a3.85 3.85 0 0 0 0 .63.63.63 0 0 0 .21.39 1 1 0 0 0 .61.2h.09a.28.28 0 0 1 .2.06s.07.13.07.27a.52.52 0 0 1-.08.23.26.26 0 0 1-.21.09h-.36l-1-.09c-.33 0-.81 0-1.44-.06a.25.25 0 0 1-.19-.11.4.4 0 0 1 0-.24c0-.15.06-.24.11-.27a.55.55 0 0 1 .28 0 1 1 0 0 0 .63-.12.63.63 0 0 0 .26-.36 4.21 4.21 0 0 0 .1-.62l.45-6a1.84 1.84 0 0 0 0-.71.6.6 0 0 0-.26-.35 2 2 0 0 0-.54-.22l-.17-.05a.19.19 0 0 1-.14-.1.49.49 0 0 1 0-.22.27.27 0 0 1 .1-.24.61.61 0 0 1 .29 0 6.94 6.94 0 0 1 1.35.2 2.05 2.05 0 0 1 .9.53 2.59 2.59 0 0 1 .6 1.07c.83 2.39 1.25 3.59 2.13 6 1.29-2.88 1.91-4.33 3.12-7.23H243.79a.22.22 0 0 1 .18.11.41.41 0 0 1 .06.23c0 .15 0 .24-.1.27a.45.45 0 0 1-.26 0 1 1 0 0 0-.61.14.7.7 0 0 0-.25.39 3.81 3.81 0 0 0-.09.61c-.07 2.41-.11 3.61-.18 6a3.36 3.36 0 0 0 0 .62.67.67 0 0 0 .24.4 1 1 0 0 0 .62.18h.08a.28.28 0 0 1 .2.06.37.37 0 0 1 .07.26.4.4 0 0 1-.07.24.23.23 0 0 1-.2.09c-.62 0-1.2-.08-1.75-.1s-1.16 0-1.79 0a.2.2 0 0 1-.19-.11.31.31 0 0 1-.06-.23c0-.15.05-.24.1-.27a.61.61 0 0 1 .28 0 1 1 0 0 0 .63-.13.68.68 0 0 0 .27-.38 3.81 3.81 0 0 0 .09-.61c.08-2.41.13-3.61.22-6-1.28 3-1.94 4.56-3.29 7.58a.28.28 0 0 1-.3.13.31.31 0 0 1-.34-.27Z"
          style={{
            fill: "#fff",
            transformOrigin: "238.061px 418.427px"
          }}
          className="ai"
        />
        <path
          d="M245.7 423.19a3.08 3.08 0 0 1-1.13-1.34 4.35 4.35 0 0 1-.35-1.86 4.16 4.16 0 0 1 .43-1.71 3.58 3.58 0 0 1 1.15-1.28 2.87 2.87 0 0 1 1.66-.49 2.66 2.66 0 0 1 1.94.7 2.8 2.8 0 0 1 .78 1.86.74.74 0 0 1-.25.68 1.32 1.32 0 0 1-.75.18h-3.49a3.92 3.92 0 0 0 .52 2.24 1.92 1.92 0 0 0 1.73.84 1.82 1.82 0 0 0 1.06-.27 2.75 2.75 0 0 0 .83-.95.21.21 0 0 1 .14-.07.28.28 0 0 1 .18.06.31.31 0 0 1 .14.27.26.26 0 0 1 0 .14 3.28 3.28 0 0 1-1.14 1.17 2.86 2.86 0 0 1-1.57.41 3.38 3.38 0 0 1-1.88-.58Zm2.34-3.88c.48 0 .76-.22.77-.63a1.92 1.92 0 0 0-.35-1.19 1.2 1.2 0 0 0-1-.49 1.48 1.48 0 0 0-1.21.6 3.07 3.07 0 0 0-.55 1.68Z"
          style={{
            fill: "#fff",
            transformOrigin: "247.259px 420.139px"
          }}
          className="ai"
        />
        <path
          d="M251.41 423.47a.38.38 0 0 1-.07-.21c0-.12 0-.21.09-.23a.54.54 0 0 1 .26 0 .85.85 0 0 0 .54-.14.58.58 0 0 0 .18-.32 4.91 4.91 0 0 0 0-.58v-3.47a3.85 3.85 0 0 0 0-.57.48.48 0 0 0-.18-.32.81.81 0 0 0-.53-.13.52.52 0 0 1-.25 0c-.06 0-.09-.12-.09-.25a.41.41 0 0 1 0-.18.27.27 0 0 1 .17-.1 6.35 6.35 0 0 0 1.51-.41l.33-.09c.15 0 .23.1.23.29v.94a5.07 5.07 0 0 1 1-.86 1.84 1.84 0 0 1 .91-.34.89.89 0 0 1 .76.35 1.44 1.44 0 0 1 .3.85 1.19 1.19 0 0 1-.22.76.77.77 0 0 1-.66.36.78.78 0 0 1-.35-.08c-.1-.06-.16-.11-.17-.15a1 1 0 0 1 .14-.19.64.64 0 0 0 .12-.38.75.75 0 0 0-.15-.44.44.44 0 0 0-.37-.15 1.14 1.14 0 0 0-.6.26 5.18 5.18 0 0 0-.72.64c0 1.48 0 2.22.07 3.7a3.44 3.44 0 0 0 0 .57.56.56 0 0 0 .19.32.87.87 0 0 0 .55.13h.08a.3.3 0 0 1 .18.05s.07.1.08.22a.37.37 0 0 1-.06.21.18.18 0 0 1-.17.1h-1.53l-1.52.06a.17.17 0 0 1-.05-.22Z"
          style={{
            fill: "#fff",
            transformOrigin: "253.956px 420.08px"
          }}
          className="ai"
        />
        <path
          d="M257.61 423.34a.3.3 0 0 1-.07-.21c0-.12 0-.21.07-.23a.54.54 0 0 1 .26 0 .83.83 0 0 0 .54-.16.48.48 0 0 0 .17-.32 5.4 5.4 0 0 0 0-.58c-.06-1.38-.08-2.08-.14-3.46a5.35 5.35 0 0 0 0-.58.49.49 0 0 0-.19-.31.85.85 0 0 0-.53-.12.49.49 0 0 1-.26 0c-.06 0-.09-.12-.09-.25a.26.26 0 0 1 .05-.17.24.24 0 0 1 .17-.11 6.48 6.48 0 0 0 1.49-.45 2 2 0 0 1 .33-.1c.15 0 .23.09.24.28v.94a4.93 4.93 0 0 1 1-.89 1.93 1.93 0 0 1 .9-.36.9.9 0 0 1 .76.33 1.42 1.42 0 0 1 .33.84 1.21 1.21 0 0 1-.2.77.76.76 0 0 1-.65.37.63.63 0 0 1-.36-.07c-.1 0-.16-.1-.17-.14l.14-.2a.61.61 0 0 0 .11-.38.72.72 0 0 0-.16-.43.5.5 0 0 0-.37-.15 1.3 1.3 0 0 0-.6.28 4.36 4.36 0 0 0-.7.67c.07 1.47.1 2.21.17 3.69a4.76 4.76 0 0 0 .05.57.48.48 0 0 0 .2.31.82.82 0 0 0 .55.11h.08a.3.3 0 0 1 .18 0s.07.1.08.22a.36.36 0 0 1 0 .21.19.19 0 0 1-.17.1h-1.54c-.46 0-.94.05-1.51.1a.2.2 0 0 1-.16-.12Z"
          style={{
            fill: "#fff",
            transformOrigin: "260.007px 419.859px"
          }}
          className="ai"
        />
        <path
          d="M263.72 426a1.28 1.28 0 0 1-.56-1c0-.45.08-.75.32-.89a1.26 1.26 0 0 1 .56-.22.67.67 0 0 1 .41.08c.12.06.17.12.18.18a.47.47 0 0 1-.11.16.64.64 0 0 0-.09.43.77.77 0 0 0 .22.52.62.62 0 0 0 .53.19c.62 0 1.18-.73 1.67-2.07a1 1 0 0 0-.07-.7c-.22-.44-.62-1.26-1.15-2.45s-.85-1.89-1.13-2.45a2.83 2.83 0 0 0-.5-.78 1.16 1.16 0 0 0-.64-.24h-.16a.16.16 0 0 1-.15-.07.58.58 0 0 1 0-.21.23.23 0 0 1 .08-.23.64.64 0 0 1 .22-.06h.18a2.32 2.32 0 0 1 1.37.17 2.27 2.27 0 0 1 .9 1c.25.49.47 1 .66 1.42a23.83 23.83 0 0 0 1.12 2.32c.44-1.63.66-2.44 1.08-4.07a1.16 1.16 0 0 0 0-.37.24.24 0 0 0-.16-.22 1.3 1.3 0 0 0-.47 0H268a.34.34 0 0 1-.17 0 .34.34 0 0 1-.08-.23.36.36 0 0 1 0-.2.16.16 0 0 1 .14-.11c.54 0 1 0 1.37-.07l.88-.11h.29a.18.18 0 0 1 .17.08.37.37 0 0 1 .07.2c0 .12 0 .2-.05.23a.2.2 0 0 1-.16.06 1.44 1.44 0 0 0-.79.26 1.36 1.36 0 0 0-.42.75c-.17.59-.44 1.54-.83 2.85s-.68 2.28-.87 2.9a9.14 9.14 0 0 1-1.07 2.31 2.05 2.05 0 0 1-1.63 1 1.71 1.71 0 0 1-1.13-.36Z"
          style={{
            fill: "#fff",
            transformOrigin: "266.855px 421.04px"
          }}
          className="ai"
        />
        <path
          d="M217.4 434a4.49 4.49 0 0 1-1.48-2 5.31 5.31 0 0 1-.27-2.72 5.92 5.92 0 0 1 1-2.58 4.45 4.45 0 0 1 2-1.57 5.29 5.29 0 0 1 2.66-.31 5.12 5.12 0 0 1 2.22.79 1.73 1.73 0 0 1 .83 1.71 1.65 1.65 0 0 1-.53 1.11 1.45 1.45 0 0 1-1.19.35 1 1 0 0 1-.45-.15.7.7 0 0 1-.25-.27c-.05-.1-.07-.17-.05-.22s.08-.07.23-.14a1.27 1.27 0 0 0 .42-.33 1 1 0 0 0 .23-.57 1.39 1.39 0 0 0-.33-1.13 1.93 1.93 0 0 0-1.22-.55 2.71 2.71 0 0 0-1.68.32 3.34 3.34 0 0 0-1.31 1.37 6.52 6.52 0 0 0-.72 2.33 5.36 5.36 0 0 0 .14 2.24 3.72 3.72 0 0 0 1 1.72 3 3 0 0 0 1.76.81 2.8 2.8 0 0 0 1.47-.17 3.05 3.05 0 0 0 1.2-1 .23.23 0 0 1 .23-.11.62.62 0 0 1 .27.12q.22.19.21.36a.18.18 0 0 1-.08.14 5.59 5.59 0 0 1-1.22 1 3.19 3.19 0 0 1-1.19.38 6.84 6.84 0 0 1-1.53-.05 4.9 4.9 0 0 1-2.37-.88Z"
          style={{
            fill: "#fff",
            transformOrigin: "219.978px 429.86px"
          }}
          className="ai"
        />
        <path
          d="M224.48 435.11a.34.34 0 0 1 0-.22c0-.13.06-.21.11-.23a.71.71 0 0 1 .27 0 .93.93 0 0 0 .59-.07.51.51 0 0 0 .23-.3 4.92 4.92 0 0 0 .09-.57c.29-2.94.44-4.41.73-7.36v-.57a.46.46 0 0 0-.15-.34.91.91 0 0 0-.54-.19.58.58 0 0 1-.27-.08c-.05 0-.07-.13-.06-.26a.27.27 0 0 1 .08-.16.22.22 0 0 1 .19-.08 5.59 5.59 0 0 0 1.14-.13l.45-.1a1.92 1.92 0 0 1 .4-.07.27.27 0 0 1 .26.32c-.18 1.94-.27 2.91-.46 4.85.32-.25.58-.43.77-.56a2.54 2.54 0 0 1 .74-.32 3.08 3.08 0 0 1 1-.07 2.12 2.12 0 0 1 2.07 2.43c-.09 1.28-.14 1.93-.23 3.22a4.18 4.18 0 0 0 0 .57.49.49 0 0 0 .17.33 1 1 0 0 0 .56.18h.1a.31.31 0 0 1 .19.05.36.36 0 0 1 .05.24.35.35 0 0 1-.07.2.19.19 0 0 1-.18.08c-.61-.07-1.15-.13-1.63-.16s-1-.07-1.62-.09a.19.19 0 0 1-.18-.1.34.34 0 0 1-.05-.22.33.33 0 0 1 .11-.23.71.71 0 0 1 .27 0 1 1 0 0 0 .59-.09.51.51 0 0 0 .22-.29c0-.12 0-.31.07-.58l.25-3.21a1.32 1.32 0 0 0-.35-1.1 1.5 1.5 0 0 0-1-.45 2.1 2.1 0 0 0-1.23.24 1.92 1.92 0 0 0-.74.63c-.13 1.44-.2 2.16-.34 3.6v.57a.49.49 0 0 0 .17.33.84.84 0 0 0 .56.19h.09a.32.32 0 0 1 .19.06.31.31 0 0 1 0 .24.3.3 0 0 1-.08.2.22.22 0 0 1-.18.08 29.47 29.47 0 0 0-3.25-.31.21.21 0 0 1-.1-.1Z"
          style={{
            fill: "#fff",
            transformOrigin: "228.713px 430.141px"
          }}
          className="ai"
        />
        <path
          d="M233.72 435.89a.35.35 0 0 1-.06-.22.28.28 0 0 1 .11-.23.71.71 0 0 1 .28 0 .87.87 0 0 0 .58-.1.46.46 0 0 0 .22-.3q0-.18.06-.57c.08-1.39.12-2.08.21-3.47a3.8 3.8 0 0 0 0-.57.42.42 0 0 0-.17-.33.89.89 0 0 0-.55-.18.48.48 0 0 1-.27-.07c-.06 0-.08-.12-.08-.25a.3.3 0 0 1 .08-.17.26.26 0 0 1 .18-.09A7.72 7.72 0 0 0 236 429a2.27 2.27 0 0 1 .35-.07h.05c.16 0 .23.11.22.3l-.05.94h.05a5.16 5.16 0 0 1 1.12-.78 1.94 1.94 0 0 1 1-.26 1 1 0 0 1 .78.4 1.35 1.35 0 0 1 .25.87 1.13 1.13 0 0 1-.29.75.87.87 0 0 1-.73.3.76.76 0 0 1-.37-.11c-.1-.06-.16-.12-.17-.16l.17-.18a.59.59 0 0 0 .15-.37.62.62 0 0 0-.12-.44.5.5 0 0 0-.41-.19 1.44 1.44 0 0 0-.67.22 4.61 4.61 0 0 0-.81.59l-.2 3.69a3.8 3.8 0 0 0 0 .57.51.51 0 0 0 .19.33.86.86 0 0 0 .56.17h.1a.38.38 0 0 1 .18.06.3.3 0 0 1 .07.23.38.38 0 0 1-.07.21.23.23 0 0 1-.2.08c-.6-.06-1.14-.11-1.63-.14l-1.62-.05a.21.21 0 0 1-.18-.07Z"
          style={{
            fill: "#fff",
            transformOrigin: "236.716px 432.541px"
          }}
          className="ai"
        />
        <path
          d="M240.33 436.23a.37.37 0 0 1-.06-.21c0-.13 0-.21.1-.23a.94.94 0 0 1 .28 0 1 1 0 0 0 .58-.11.49.49 0 0 0 .21-.31 5.57 5.57 0 0 0 0-.58c.05-1.39.08-2.08.12-3.47a5.24 5.24 0 0 0 0-.57.5.5 0 0 0-.18-.33.94.94 0 0 0-.56-.15.59.59 0 0 1-.27-.07c-.06 0-.08-.12-.08-.25a.36.36 0 0 1 .07-.17.22.22 0 0 1 .19-.09 7.59 7.59 0 0 0 1.65-.34 1.53 1.53 0 0 1 .35-.08q.24 0 .24.3c-.06 2.1-.09 3.16-.16 5.27a4.21 4.21 0 0 0 0 .57.52.52 0 0 0 .19.33 1 1 0 0 0 .57.15h.09a.31.31 0 0 1 .19.05.3.3 0 0 1 .07.23.31.31 0 0 1-.07.21.2.2 0 0 1-.18.09c-.61-.05-1.15-.08-1.64-.1h-1.62a.21.21 0 0 1-.08-.14Zm1.31-9a.85.85 0 0 1-.26-.66.78.78 0 0 1 .36-.61 1.2 1.2 0 0 1 .79-.25.78.78 0 0 1 .6.28.93.93 0 0 1 .22.68.78.78 0 0 1-.3.62 1 1 0 0 1-.71.23 1 1 0 0 1-.7-.27Z"
          style={{
            fill: "#fff",
            transformOrigin: "242.096px 431.09px"
          }}
          className="ai"
        />
        <path
          d="M245.73 436.19A1.31 1.31 0 0 1 245 435a1 1 0 0 1 .29-.71.94.94 0 0 1 .7-.26.69.69 0 0 1 .44.15c.13.09.19.16.19.21l-.18.19a.75.75 0 0 0-.19.52.82.82 0 0 0 .42.72 2.28 2.28 0 0 0 1.23.28 1.87 1.87 0 0 0 1.15-.3 1 1 0 0 0 .41-.87.84.84 0 0 0-.23-.62 1.56 1.56 0 0 0-.57-.39c-.24-.09-.56-.2-1-.32a9.65 9.65 0 0 1-1.22-.44 2.13 2.13 0 0 1-.81-.65 1.78 1.78 0 0 1-.31-1.11 1.62 1.62 0 0 1 .41-1.08 2.5 2.5 0 0 1 1-.67 3.67 3.67 0 0 1 1.33-.21 3.1 3.1 0 0 1 1.55.38 1.18 1.18 0 0 1 .66 1.06 1 1 0 0 1-.25.7.86.86 0 0 1-.67.29.74.74 0 0 1-.43-.14c-.13-.09-.19-.16-.19-.21s0-.05.12-.12a1.31 1.31 0 0 0 .22-.25.6.6 0 0 0 .11-.34.77.77 0 0 0-.41-.64 1.72 1.72 0 0 0-.9-.23 1.87 1.87 0 0 0-1 .25.8.8 0 0 0-.44.73 1.09 1.09 0 0 0 .17.68 1.25 1.25 0 0 0 .55.4 11.62 11.62 0 0 0 1.16.42 11.9 11.9 0 0 1 1.27.49 1.85 1.85 0 0 1 .73.59 1.59 1.59 0 0 1 .27 1 1.82 1.82 0 0 1-.37 1.13 2.43 2.43 0 0 1-1 .79 3.44 3.44 0 0 1-1.47.27 4.22 4.22 0 0 1-2.01-.5Z"
          style={{
            fill: "#fff",
            transformOrigin: "247.792px 433.065px"
          }}
          className="ai"
        />
        <path
          d="M253.32 436.16a2.12 2.12 0 0 1-.57-1.55v-4.46h-.88a.21.21 0 0 1-.16-.08.33.33 0 0 1-.06-.19.6.6 0 0 1 0-.24.23.23 0 0 1 .16-.11 1.83 1.83 0 0 0 .82-.35 1.48 1.48 0 0 0 .43-.52 8.47 8.47 0 0 0 .32-.86.32.32 0 0 1 .33-.2.41.41 0 0 1 .21.05.17.17 0 0 1 .11.16c0 .69 0 1-.08 1.73l1.77-.15c.13 0 .2.09.2.29a.8.8 0 0 1 0 .31c0 .09-.08.14-.13.14h-1.7c0 1.85 0 2.77.07 4.61a1.62 1.62 0 0 0 .23.83.75.75 0 0 0 .7.32 2.08 2.08 0 0 0 .85-.19l.24-.08a.23.23 0 0 1 .18.08 1.42 1.42 0 0 1 .13.22.64.64 0 0 1 0 .07.21.21 0 0 1-.09.14 2.94 2.94 0 0 1-.84.41 4.23 4.23 0 0 1-1 .12 1.74 1.74 0 0 1-1.24-.5Z"
          style={{
            fill: "#fff",
            transformOrigin: "254.064px 432.129px"
          }}
          className="ai"
        />
        <path
          d="M257.77 436.3a.3.3 0 0 1-.08-.21c0-.12 0-.21.08-.23a.71.71 0 0 1 .27-.05 1 1 0 0 0 .57-.15.52.52 0 0 0 .19-.33 3.32 3.32 0 0 0 0-.58c-.05-1.38-.07-2.08-.12-3.46v-.58a.49.49 0 0 0-.21-.31.91.91 0 0 0-.56-.12.55.55 0 0 1-.28 0c-.06 0-.09-.12-.1-.25a.27.27 0 0 1 .06-.17.25.25 0 0 1 .18-.11 7.74 7.74 0 0 0 1.61-.45 2.32 2.32 0 0 1 .34-.1h.05c.16 0 .24.09.25.28v1l.44-.46a2.53 2.53 0 0 1 .8-.58 3.06 3.06 0 0 1 1.05-.23 2.58 2.58 0 0 1 1.27.24 2 2 0 0 1 .84.81 5.84 5.84 0 0 1 1.08-.93 2.84 2.84 0 0 1 1.36-.4 2.24 2.24 0 0 1 1.68.5 2.28 2.28 0 0 1 .74 1.59l.25 3.22a3.65 3.65 0 0 0 .08.57.5.5 0 0 0 .22.3 1 1 0 0 0 .59.09h.09a.27.27 0 0 1 .19 0 .34.34 0 0 1 .1.22.3.3 0 0 1-.06.22.19.19 0 0 1-.17.1c-.6 0-1.15 0-1.63.09s-1 .09-1.62.16a.2.2 0 0 1-.19-.08.49.49 0 0 1-.08-.21.32.32 0 0 1 .07-.24 1 1 0 0 1 .27-.05 1 1 0 0 0 .57-.18.6.6 0 0 0 .18-.33 4.36 4.36 0 0 0 0-.58c-.09-1.28-.14-1.92-.23-3.2a1.33 1.33 0 0 0-.52-1 1.5 1.5 0 0 0-1-.3 1.84 1.84 0 0 0-1.12.4 2.06 2.06 0 0 0-.58.75c.08 1.44.13 2.16.21 3.6v.41a.59.59 0 0 0 .2.46.9.9 0 0 0 .59.11h.1a.25.25 0 0 1 .19 0 .32.32 0 0 1 .09.22.33.33 0 0 1-.05.22.21.21 0 0 1-.18.1l-1.59.05-1.56.13a.2.2 0 0 1-.19-.08.38.38 0 0 1-.07-.21.32.32 0 0 1 .07-.24 1 1 0 0 1 .27-.05q.51 0 .63-.24a.92.92 0 0 0 .09-.47v-.36c-.07-1.28-.1-1.92-.17-3.2a1.35 1.35 0 0 0-.49-1 1.53 1.53 0 0 0-1-.32 1.89 1.89 0 0 0-1.13.39 2.29 2.29 0 0 0-.61.74l.15 3.6a3 3 0 0 0 .06.57.46.46 0 0 0 .21.31.86.86 0 0 0 .58.11h.1a.3.3 0 0 1 .18 0 .28.28 0 0 1 .09.22.34.34 0 0 1-.05.22.24.24 0 0 1-.18.1h-1.64c-.49 0-1 0-1.62.1a.23.23 0 0 1-.2-.16Z"
          style={{
            fill: "#fff",
            transformOrigin: "264.166px 432.688px"
          }}
          className="ai"
        />
        <path
          d="M272.19 435.15a1.81 1.81 0 0 1-.16-2.72 3.55 3.55 0 0 1 1.47-.76c.58-.15 1.34-.3 2.27-.45l-.12-1.19a1.61 1.61 0 0 0-.49-1.08 1.65 1.65 0 0 0-1.23-.27 1.36 1.36 0 0 0-.79.37 1 1 0 0 0-.27.8.62.62 0 0 0 .15.39 1 1 0 0 0 .27.24.53.53 0 0 1 .14.11.39.39 0 0 1-.17.25.77.77 0 0 1-.45.19.9.9 0 0 1-.76-.26 1.18 1.18 0 0 1-.33-.77 1.22 1.22 0 0 1 .6-1.22 3.44 3.44 0 0 1 1.66-.54 3.92 3.92 0 0 1 2.06.26 1.85 1.85 0 0 1 1 1.64c.16 1.41.24 2.11.39 3.51a1.29 1.29 0 0 0 .27.58.68.68 0 0 0 .64.22.32.32 0 0 1 .18 0s.06.09.08.19-.08.22-.26.3a1.94 1.94 0 0 1-.56.15 1.75 1.75 0 0 1-1.08-.22 1.24 1.24 0 0 1-.6-.83 2.09 2.09 0 0 1-.91 1 3.26 3.26 0 0 1-1.42.46 2.14 2.14 0 0 1-1.58-.35Zm2.92-.84a1.88 1.88 0 0 0 .64-.78 1.91 1.91 0 0 0 .16-1c0-.35-.06-.52-.09-.86a5.81 5.81 0 0 0-2.22.74 1.39 1.39 0 0 0-.54 1.33.89.89 0 0 0 1.1.93 1.89 1.89 0 0 0 .95-.36Z"
          style={{
            fill: "#fff",
            transformOrigin: "275.046px 431.865px"
          }}
          className="ai"
        />
        <path
          d="M280.23 434.39a1.31 1.31 0 0 1-.92-1.1 1 1 0 0 1 .19-.73.91.91 0 0 1 .66-.36.79.79 0 0 1 .46.08c.14.07.21.14.22.19a1.52 1.52 0 0 1-.15.21.69.69 0 0 0-.12.54.82.82 0 0 0 .51.66 2.39 2.39 0 0 0 1.27.1 1.77 1.77 0 0 0 1.09-.46 1 1 0 0 0 .29-.91.88.88 0 0 0-.31-.59 1.6 1.6 0 0 0-.63-.3c-.24-.06-.57-.12-1-.19a8.48 8.48 0 0 1-1.27-.26 2.14 2.14 0 0 1-.89-.53 1.77 1.77 0 0 1-.47-1.06 1.63 1.63 0 0 1 .26-1.13 2.47 2.47 0 0 1 .94-.8 3.81 3.81 0 0 1 1.29-.4 3.08 3.08 0 0 1 1.59.17 1.2 1.2 0 0 1 .81 1 1 1 0 0 1-.16.72.84.84 0 0 1-.62.38.7.7 0 0 1-.45-.08c-.14-.07-.21-.13-.22-.18l.11-.14a1.12 1.12 0 0 0 .18-.28.52.52 0 0 0 .06-.35.81.81 0 0 0-.5-.57 1.73 1.73 0 0 0-.92-.11 2 2 0 0 0-1 .39.85.85 0 0 0-.34.79 1.15 1.15 0 0 0 .27.64 1.24 1.24 0 0 0 .6.33c.25.07.66.16 1.21.26a12.07 12.07 0 0 1 1.33.3 1.85 1.85 0 0 1 .8.48 1.61 1.61 0 0 1 .41.93 1.84 1.84 0 0 1-.21 1.17 2.43 2.43 0 0 1-.91.92 3.62 3.62 0 0 1-1.42.48 4.24 4.24 0 0 1-2.04-.21Z"
          style={{
            fill: "#fff",
            transformOrigin: "281.988px 430.978px"
          }}
          className="ai"
        />
        <path
          d="M280.37 418.28a1.58 1.58 0 1 1-.86-2.07 1.57 1.57 0 0 1 .86 2.07Z"
          style={{
            fill: "#fff",
            transformOrigin: "278.913px 417.672px"
          }}
          className="ai"
        />
        <path
          d="M279.07 421.66a1.44 1.44 0 1 1-.78-1.88 1.43 1.43 0 0 1 .78 1.88Z"
          style={{
            fill: "#fff",
            transformOrigin: "277.741px 421.11px"
          }}
          className="ai"
        />
        <path
          d="M283.27 421.39a1.82 1.82 0 1 1-1-2.38 1.81 1.81 0 0 1 1 2.38Z"
          style={{
            fill: "#fff",
            transformOrigin: "281.589px 420.697px"
          }}
          className="ai"
        />
        <path
          d="M281.39 417.61a4.8 4.8 0 0 0-1.69-3.22c1.19-.3 1.82-1.12 1.48-2.73 1.3-.25 2.1-1 3-2.54a4.69 4.69 0 0 0 .74 3.81 2 2 0 0 0-.33 2.9c-1.51.32-2.72.78-3.2 1.78Z"
          style={{
            fill: "#fff",
            transformOrigin: "282.31px 413.365px"
          }}
          className="ai"
        />
        <path
          d="M282.07 418.23a5.65 5.65 0 0 0 3.26-2.33c.49 1.13 1.41 1.62 2.94 1 .45 1.24 1.33 1.91 3 2.51a4.69 4.69 0 0 0-3.64 1.35 2.36 2.36 0 0 0-3.22.16 3.06 3.06 0 0 0-2.34-2.69Z"
          style={{
            fill: "#fff",
            transformOrigin: "286.67px 418.41px"
          }}
          className="ai"
        />
        <path
          d="M221.89 418.28a1.58 1.58 0 1 0 .87-2.07 1.59 1.59 0 0 0-.87 2.07Z"
          style={{
            fill: "#fff",
            transformOrigin: "223.351px 417.676px"
          }}
          className="ai"
        />
        <path
          d="M223.19 421.66a1.44 1.44 0 1 0 .79-1.88 1.43 1.43 0 0 0-.79 1.88Z"
          style={{
            fill: "#fff",
            transformOrigin: "224.521px 421.114px"
          }}
          className="ai"
        />
        <path
          d="M219 421.39a1.82 1.82 0 1 0 1-2.38 1.83 1.83 0 0 0-1 2.38Z"
          style={{
            fill: "#fff",
            transformOrigin: "220.684px 420.697px"
          }}
          className="ai"
        />
        <path
          d="M220.87 417.61a4.77 4.77 0 0 1 1.7-3.22c-1.2-.3-1.82-1.12-1.48-2.73-1.3-.25-2.11-1-3-2.54a4.66 4.66 0 0 1-.74 3.81 2 2 0 0 1 .33 2.9c1.5.32 2.71.78 3.19 1.78Z"
          style={{
            fill: "#fff",
            transformOrigin: "219.96px 413.365px"
          }}
          className="ai"
        />
        <path
          d="M220.19 418.23a5.65 5.65 0 0 1-3.26-2.33c-.49 1.13-1.4 1.62-2.94 1-.45 1.24-1.33 1.91-3 2.51a4.67 4.67 0 0 1 3.64 1.35 2.37 2.37 0 0 1 3.23.16 3 3 0 0 1 2.33-2.69Z"
          style={{
            fill: "#fff",
            transformOrigin: "215.59px 418.41px"
          }}
          className="ai"
        />
      </g>
    </g>
    <g
      className="snow-globe__snow ai"
      style={{
        transformOrigin: "254.483px 133.728px"
      }}
    >
      <g
        className="snow-globe__snow ai"
        style={{
          transformOrigin: "254.483px 133.728px"
        }}
      >
        <path
          d="M384.78 207.41a1.07 1.07 0 0 0-1 .74h-2.91l3.65-4.34a.33.33 0 1 0-.51-.43l-3.59 4.28v-4.21a1.08 1.08 0 1 0-.67 0v4.21l-3.59-4.28a.34.34 0 0 0-.48 0 .33.33 0 0 0 0 .47l3.65 4.34h-2.91a1.08 1.08 0 1 0 0 .67h3.06l-3.8 4.53a.33.33 0 0 0 0 .47.37.37 0 0 0 .22.08.35.35 0 0 0 .26-.12l3.59-4.28v4a1.07 1.07 0 1 0 .67 0v-4l3.59 4.28a.32.32 0 0 0 .25.12.35.35 0 0 0 .22-.08.33.33 0 0 0 0-.47l-3.8-4.53h3.06a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "380.069px 208.485px"
          }}
          className="ai"
        />
        <path
          d="M336.28 83.34a1.07 1.07 0 0 0-1 .74h-2.9l3.62-4.35a.33.33 0 0 0 0-.47.34.34 0 0 0-.47 0l-3.59 4.28v-4.2a1.08 1.08 0 1 0-.67 0v4.21l-3.59-4.29a.33.33 0 1 0-.51.43l3.64 4.35h-2.9a1.08 1.08 0 1 0 0 .67h3.05l-3.79 4.52a.33.33 0 0 0 0 .47.29.29 0 0 0 .21.08.34.34 0 0 0 .26-.12l3.59-4.28v4a1.08 1.08 0 1 0 .67 0v-4l3.59 4.28a.34.34 0 0 0 .26.12.29.29 0 0 0 .21-.08.33.33 0 0 0 0-.47l-3.79-4.52h3.05a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "331.554px 84.36px"
          }}
          className="ai"
        />
        <path
          d="M354.61 138.54a1.08 1.08 0 0 0-1 .74h-2.9l3.65-4.34a.34.34 0 0 0-.52-.43l-3.59 4.28v-4.21a1.07 1.07 0 1 0-1.41-1 1.08 1.08 0 0 0 .74 1v4.21l-3.58-4.28a.33.33 0 0 0-.51.43l3.64 4.34h-2.9a1.08 1.08 0 1 0 0 .67h3.06l-3.8 4.53a.33.33 0 0 0 0 .47.35.35 0 0 0 .22.08.32.32 0 0 0 .25-.12l3.59-4.28v4a1.07 1.07 0 1 0 1.41 1 1.07 1.07 0 0 0-.74-1v-4l3.59 4.28a.34.34 0 0 0 .26.12.32.32 0 0 0 .21-.08.33.33 0 0 0 0-.47l-3.8-4.53h3a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "349.844px 139.605px"
          }}
          className="ai"
        />
        <path
          d="M283.79 105.94a1.07 1.07 0 0 0-1 .73h-2.91l3.65-4.34a.33.33 0 1 0-.51-.43l-3.59 4.28V102a1.08 1.08 0 1 0-.67 0v4.21l-3.6-4.28a.33.33 0 0 0-.51.43l3.65 4.34h-2.91a1.08 1.08 0 1 0 0 .67h3.06l-3.8 4.53a.33.33 0 0 0 0 .47.37.37 0 0 0 .22.08.32.32 0 0 0 .25-.12l3.6-4.28v4a1.07 1.07 0 1 0 .67 0v-4l3.61 4.25a.32.32 0 0 0 .25.12.37.37 0 0 0 .22-.08.33.33 0 0 0 0-.47l-3.8-4.53h3.06a1.07 1.07 0 1 0 1-1.4Z"
          style={{
            fill: "#187032",
            transformOrigin: "279.051px 107.015px"
          }}
          className="ai"
        />
        <path
          d="M132.84 133.46a1.07 1.07 0 0 0-1 .74h-2.91l3.65-4.34a.33.33 0 1 0-.51-.43l-3.6 4.28v-4.21a1.07 1.07 0 1 0-.67 0v4.21l-3.59-4.28a.33.33 0 0 0-.51.43l3.65 4.34h-2.91a1.08 1.08 0 1 0 0 .67h3.06l-3.8 4.53a.33.33 0 0 0 0 .47.35.35 0 0 0 .22.08.32.32 0 0 0 .25-.12l3.59-4.28v4a1.08 1.08 0 1 0 .67 0v-4l3.6 4.28a.32.32 0 0 0 .25.12.37.37 0 0 0 .22-.08.33.33 0 0 0 0-.47l-3.8-4.53h3.06a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "128.089px 134.535px"
          }}
          className="ai"
        />
        <path
          d="M117.11 206.81a1.06 1.06 0 0 0-1 .74h-2.91l3.65-4.35a.34.34 0 0 0-.52-.43l-3.59 4.28v-4.2a1.07 1.07 0 0 0-.33-2.09 1.07 1.07 0 0 0-.34 2.09v4.2l-3.59-4.28a.33.33 0 0 0-.51.43l3.64 4.35h-2.9a1.07 1.07 0 1 0-1 1.41 1.08 1.08 0 0 0 1-.74h3.06l-3.8 4.52a.33.33 0 0 0 0 .47.3.3 0 0 0 .22.08.32.32 0 0 0 .25-.12l3.59-4.28v4a1.07 1.07 0 1 0 1.41 1 1.07 1.07 0 0 0-.74-1v-4l3.59 4.28a.34.34 0 0 0 .26.12.28.28 0 0 0 .21-.08.33.33 0 0 0 0-.47l-3.8-4.52h3.06a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "112.365px 207.867px"
          }}
          className="ai"
        />
        <path
          d="M152.05 177.92a1.08 1.08 0 0 0-1 .74h-2.9l3.64-4.34a.33.33 0 1 0-.51-.43l-3.59 4.28V174a1.08 1.08 0 1 0-.67 0v4.21l-3.59-4.28a.33.33 0 1 0-.51.43l3.64 4.34h-2.9a1.07 1.07 0 1 0-1 1.41 1.07 1.07 0 0 0 1-.74h3.06l-3.8 4.53a.33.33 0 0 0 0 .47.34.34 0 0 0 .21.08.34.34 0 0 0 .26-.12L147 180v4a1.08 1.08 0 1 0 .67 0v-4l3.59 4.28a.34.34 0 0 0 .26.12.34.34 0 0 0 .21-.08.33.33 0 0 0 0-.47l-3.73-4.52h3a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "147.33px 179px"
          }}
          className="ai"
        />
        <path
          d="M241.85 60.82a1.07 1.07 0 0 0-1 .74h-2.91l3.65-4.35a.33.33 0 0 0 0-.47.34.34 0 0 0-.48 0l-3.59 4.28v-4.2a1.07 1.07 0 1 0-.67 0v4.21l-3.59-4.29a.33.33 0 0 0-.51.43l3.65 4.35h-2.91a1.07 1.07 0 1 0-1 1.41 1.08 1.08 0 0 0 1-.74h3.06l-3.8 4.52a.33.33 0 0 0 0 .47.3.3 0 0 0 .22.08.32.32 0 0 0 .25-.12l3.59-4.28v4a1.08 1.08 0 1 0 .67 0v-4l3.59 4.28a.35.35 0 0 0 .26.12.32.32 0 0 0 .22-.08.33.33 0 0 0 0-.47l-3.8-4.52h3.06a1.07 1.07 0 1 0 1-1.41Z"
          style={{
            fill: "#187032",
            transformOrigin: "237.15px 61.8503px"
          }}
          className="ai"
        />
        <path
          d="M247.48 92.55a1.26 1.26 0 1 1 1.25 1.26 1.25 1.25 0 0 1-1.25-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "248.74px 92.55px"
          }}
          className="ai"
        />
        <path
          d="M249.87 131.58a1.26 1.26 0 1 1 1.26 1.26 1.26 1.26 0 0 1-1.26-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "251.13px 131.58px"
          }}
          className="ai"
        />
        <path
          d="M313.73 122.37a1.26 1.26 0 1 1 1.25 1.25 1.25 1.25 0 0 1-1.25-1.25Z"
          style={{
            fill: "#187032",
            transformOrigin: "314.99px 122.36px"
          }}
          className="ai"
        />
        <path
          d="M175.72 72.63a1.26 1.26 0 1 1 1.28 1.26 1.26 1.26 0 0 1-1.28-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "176.98px 72.6302px"
          }}
          className="ai"
        />
        <path
          d="M116.23 172.7a1.26 1.26 0 1 1 1.26 1.26 1.26 1.26 0 0 1-1.26-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "117.49px 172.7px"
          }}
          className="ai"
        />
        <path
          d="M162.17 132.84a1.26 1.26 0 1 1 1.26 1.25 1.26 1.26 0 0 1-1.26-1.25Z"
          style={{
            fill: "#187032",
            transformOrigin: "163.43px 132.83px"
          }}
          className="ai"
        />
        <circle
          cx={152.5}
          cy={98.91}
          r={1.26}
          style={{
            fill: "#187032",
            transformOrigin: "152.5px 98.91px"
          }}
          className="ai"
        />
        <path
          d="M289.7 76.55a1.26 1.26 0 1 1 1.3 1.26 1.27 1.27 0 0 1-1.3-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "290.96px 76.5505px"
          }}
          className="ai"
        />
        <path
          d="M269 53.1a1.26 1.26 0 1 1 1.26 1.25A1.26 1.26 0 0 1 269 53.1Z"
          style={{
            fill: "#187032",
            transformOrigin: "270.26px 53.09px"
          }}
          className="ai"
        />
        <path
          d="M372.25 114.36a1.26 1.26 0 1 1 1.26 1.25 1.25 1.25 0 0 1-1.26-1.25Z"
          style={{
            fill: "#187032",
            transformOrigin: "373.51px 114.35px"
          }}
          className="ai"
        />
        <path
          d="M259.66 181a1.26 1.26 0 1 1 1.26 1.26 1.26 1.26 0 0 1-1.26-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "260.92px 181px"
          }}
          className="ai"
        />
        <path
          d="M393 157.47a1.26 1.26 0 1 1 1.26 1.26 1.25 1.25 0 0 1-1.26-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "394.26px 157.47px"
          }}
          className="ai"
        />
        <path
          d="M399.82 192.53a1.26 1.26 0 1 1 1.25 1.26 1.25 1.25 0 0 1-1.25-1.26Z"
          style={{
            fill: "#187032",
            transformOrigin: "401.08px 192.53px"
          }}
          className="ai"
        />
      </g>
    </g>
    <g
      className="ai"
      style={{
        transformOrigin: "250.085px 249.279px"
      }}
    >
      <g
        className="ai"
        style={{
          transformOrigin: "118.177px 252.419px"
        }}
      >
        <path
          d="M128.57 265.35a4.14 4.14 0 0 0-3.33 2.24 8 8 0 0 1-2.63 2.56 6.8 6.8 0 0 1-1.42.6c-.49.13-1.65.6-1.88-.19-.08-.24.11-.49.29-.67a11.87 11.87 0 0 0 1.07-1.18c.18-.26.72-1.22.37-1.49s-.79.33-1 .49c-.38.29-.77.58-1.16.85a6.94 6.94 0 0 1-1.73.79 9.79 9.79 0 0 1-3 .65.63.63 0 0 1-.33-1.17 4.27 4.27 0 0 0 1.47-1.27 1.13 1.13 0 0 0 .22-.68.53.53 0 0 0-.44-.5.72.72 0 0 0-.4.1 11 11 0 0 1-5.66.91.63.63 0 0 1-.3-1.13c.29-.23.77-.56.91-.91.31-.79-.7-1.45-1.22-1.85a15 15 0 0 0-2.17-1.33 7 7 0 0 1-1.18-.83c-.12-.11-.26-.22-.27-.38a.4.4 0 0 1 .27-.39 1.06 1.06 0 0 1 .5 0c.67.06 1.32.22 2 .33s2.27.26 2.77-.56a1.33 1.33 0 0 0 .14-.92 2.87 2.87 0 0 0-.47-1.09.63.63 0 0 1 .63-1 10.52 10.52 0 0 1 4.67 2.53 1.75 1.75 0 0 0 .48.34.48.48 0 0 0 .55-.11.69.69 0 0 0 .07-.53 5.79 5.79 0 0 0-.74-2.1c-.32-.54.08-1.2.67-1 3.19 1 4.14 4.45 4.69 5.5.66 1.24 1.69.51 1.7-.54a5.59 5.59 0 0 0-1.11-3 .64.64 0 0 1 .76-.95 6.93 6.93 0 0 1 3.79 4.77c.49 1.98 1.49 3.08 2.42 3.11Z"
          style={{
            fill: "#187032",
            transformOrigin: "116.675px 263.715px"
          }}
          className="ai"
        />
        <path
          d="M128.57 265.35a4.14 4.14 0 0 0-3.33 2.24 8 8 0 0 1-2.63 2.56 6.8 6.8 0 0 1-1.42.6c-.49.13-1.65.6-1.88-.19-.08-.24.11-.49.29-.67a11.87 11.87 0 0 0 1.07-1.18c.18-.26.72-1.22.37-1.49s-.79.33-1 .49c-.38.29-.77.58-1.16.85a6.94 6.94 0 0 1-1.73.79 9.79 9.79 0 0 1-3 .65.63.63 0 0 1-.33-1.17 4.27 4.27 0 0 0 1.47-1.27 1.13 1.13 0 0 0 .22-.68.53.53 0 0 0-.44-.5.72.72 0 0 0-.4.1 11 11 0 0 1-5.66.91.63.63 0 0 1-.3-1.13c.29-.23.77-.56.91-.91.31-.79-.7-1.45-1.22-1.85a15 15 0 0 0-2.17-1.33 7 7 0 0 1-1.18-.83c-.12-.11-.26-.22-.27-.38a.4.4 0 0 1 .27-.39 1.06 1.06 0 0 1 .5 0c.67.06 1.32.22 2 .33s2.27.26 2.77-.56a1.33 1.33 0 0 0 .14-.92 2.87 2.87 0 0 0-.47-1.09.63.63 0 0 1 .63-1 10.52 10.52 0 0 1 4.67 2.53 1.75 1.75 0 0 0 .48.34.48.48 0 0 0 .55-.11.69.69 0 0 0 .07-.53 5.79 5.79 0 0 0-.74-2.1c-.32-.54.08-1.2.67-1 3.19 1 4.14 4.45 4.69 5.5.66 1.24 1.69.51 1.7-.54a5.59 5.59 0 0 0-1.11-3 .64.64 0 0 1 .76-.95 6.93 6.93 0 0 1 3.79 4.77c.49 1.98 1.49 3.08 2.42 3.11Z"
          style={{
            fill: "#fff",
            opacity: 0.6,
            transformOrigin: "116.675px 263.715px"
          }}
          className="ai"
        />
        <path
          d="M126.53 265.65c-7.17 0-17.4-3-17.52-3a.22.22 0 0 1-.15-.27.22.22 0 0 1 .28-.15c.12 0 12.41 3.54 19.41 2.9a.22.22 0 0 1 0 .44 19.5 19.5 0 0 1-2.02.08Z"
          style={{
            fill: "#fff",
            transformOrigin: "118.811px 263.935px"
          }}
          className="ai"
        />
        <path
          d="M130.45 259.19c-1-.75-2.7-1.38-5.42-.52s-6 .87-8.3-1.05a.86.86 0 0 1-.31-1c.21-.33.67-.35 1.06-.35a13.93 13.93 0 0 0 1.94-.1 3.13 3.13 0 0 0 1.74-.61.85.85 0 0 0 .33-.71.69.69 0 0 0-.48-.5 2.16 2.16 0 0 0-.72-.09c-3-.06-6.57-.61-9-2.59a.86.86 0 0 1 .66-1.5c1 .11 2.37.65 3.22-.06a.57.57 0 0 0 .24-.46c0-.27-.28-.43-.51-.55a25.49 25.49 0 0 1-6.76-4.57c-.29-.31-.35-1 .09-1.16a4.25 4.25 0 0 1 1.75.2 1.79 1.79 0 0 0 1.51-.11c.44-.35.4-1 .31-1.57-.38-2.67-2.26-5-3.8-7.14a.58.58 0 0 1 .87-.76 21.74 21.74 0 0 0 6.43 4.59 1 1 0 0 0 .66.11 1 1 0 0 0 .47-.51 6.59 6.59 0 0 0 .61-2.31.82.82 0 0 1 1.15-.76c.53.24 1 1.28 1.37 1.75a10 10 0 0 1 1.65 5 2.47 2.47 0 0 0 .13.82.71.71 0 0 0 .63.47c.36 0 .58-.38.73-.71a11.24 11.24 0 0 0 .85-2.9c.07-.47.23-1.07.71-1.11a.92.92 0 0 1 .67.34c2.68 2.66 1.3 8 .82 11.14a.32.32 0 0 0 0 .24.26.26 0 0 0 .16.11 1.26 1.26 0 0 0 1-.16 3 3 0 0 0 .75-.71 5.85 5.85 0 0 0 1.39-3.26c.06-.82 1.11-1.57 1.62-.59a9.32 9.32 0 0 1-.12 8.25c-1.15 2.56-.87 4.44-.1 5.4Z"
          style={{
            fill: "#187032",
            transformOrigin: "119.732px 246.527px"
          }}
          className="ai"
        />
        <path
          d="M130.45 259.19c-1-.75-2.7-1.38-5.42-.52s-6 .87-8.3-1.05a.86.86 0 0 1-.31-1c.21-.33.67-.35 1.06-.35a13.93 13.93 0 0 0 1.94-.1 3.13 3.13 0 0 0 1.74-.61.85.85 0 0 0 .33-.71.69.69 0 0 0-.48-.5 2.16 2.16 0 0 0-.72-.09c-3-.06-6.57-.61-9-2.59a.86.86 0 0 1 .66-1.5c1 .11 2.37.65 3.22-.06a.57.57 0 0 0 .24-.46c0-.27-.28-.43-.51-.55a25.49 25.49 0 0 1-6.76-4.57c-.29-.31-.35-1 .09-1.16a4.25 4.25 0 0 1 1.75.2 1.79 1.79 0 0 0 1.51-.11c.44-.35.4-1 .31-1.57-.38-2.67-2.26-5-3.8-7.14a.58.58 0 0 1 .87-.76 21.74 21.74 0 0 0 6.43 4.59 1 1 0 0 0 .66.11 1 1 0 0 0 .47-.51 6.59 6.59 0 0 0 .61-2.31.82.82 0 0 1 1.15-.76c.53.24 1 1.28 1.37 1.75a10 10 0 0 1 1.65 5 2.47 2.47 0 0 0 .13.82.71.71 0 0 0 .63.47c.36 0 .58-.38.73-.71a11.24 11.24 0 0 0 .85-2.9c.07-.47.23-1.07.71-1.11a.92.92 0 0 1 .67.34c2.68 2.66 1.3 8 .82 11.14a.32.32 0 0 0 0 .24.26.26 0 0 0 .16.11 1.26 1.26 0 0 0 1-.16 3 3 0 0 0 .75-.71 5.85 5.85 0 0 0 1.39-3.26c.06-.82 1.11-1.57 1.62-.59a9.32 9.32 0 0 1-.12 8.25c-1.15 2.56-.87 4.44-.1 5.4Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
            transformOrigin: "119.732px 246.527px"
          }}
          className="ai"
        />
        <path
          d="M130.44 259.49a.33.33 0 0 1-.16-.05c-6.26-4-15.56-14.8-17.67-19.58a.3.3 0 0 1 .15-.4.3.3 0 0 1 .4.15c2.08 4.72 11.26 15.33 17.45 19.32a.31.31 0 0 1 .09.42.31.31 0 0 1-.26.14Z"
          style={{
            fill: "#fff",
            transformOrigin: "121.664px 249.461px"
          }}
          className="ai"
        />
      </g>
      <g
        className="ai"
        style={{
          transformOrigin: "377.798px 246.81px"
        }}
      >
        <path
          d="M371.11 258.34a3.66 3.66 0 0 1 2.26 2.77A7 7 0 0 0 375 264a5.22 5.22 0 0 0 1.05.88c.38.24 1.24.94 1.65.33a.67.67 0 0 0-.08-.65 8.77 8.77 0 0 1-.6-1.28c-.09-.27-.31-1.22.07-1.37s.58.49.72.68c.24.35.5.69.76 1a6 6 0 0 0 1.27 1.13 8.86 8.86 0 0 0 2.43 1.33.56.56 0 0 0 .58-.9 3.89 3.89 0 0 1-.92-1.47 1 1 0 0 1 0-.63.48.48 0 0 1 .5-.32.69.69 0 0 1 .31.2 9.87 9.87 0 0 0 4.59 2.23.56.56 0 0 0 .55-.88 2.6 2.6 0 0 1-.55-1c-.06-.74 1-1.05 1.52-1.26a15 15 0 0 1 2.19-.57 5.63 5.63 0 0 0 1.22-.4.53.53 0 0 0 .33-.25.35.35 0 0 0-.13-.4 1.23 1.23 0 0 0-.41-.17c-.59-.12-1.19-.15-1.78-.23s-2-.36-2.22-1.19a1.23 1.23 0 0 1 .12-.82 2.4 2.4 0 0 1 .68-.8.56.56 0 0 0-.28-1 9.38 9.38 0 0 0-4.63.94 1.29 1.29 0 0 1-.5.17.42.42 0 0 1-.44-.3.61.61 0 0 1 .07-.47 5 5 0 0 1 1.13-1.53.57.57 0 0 0-.32-1c-3 .06-4.67 2.72-5.42 3.47s-1.56 0-1.3-.9a5 5 0 0 1 1.73-2.29.56.56 0 0 0-.4-1 6.13 6.13 0 0 0-4.46 3.08c-1.03 1.52-2.12 2.19-2.92 1.98Z"
          style={{
            fill: "#187032",
            transformOrigin: "381.859px 259.674px"
          }}
          className="ai"
        />
        <path
          d="M371.11 258.34a3.66 3.66 0 0 1 2.26 2.77A7 7 0 0 0 375 264a5.22 5.22 0 0 0 1.05.88c.38.24 1.24.94 1.65.33a.67.67 0 0 0-.08-.65 8.77 8.77 0 0 1-.6-1.28c-.09-.27-.31-1.22.07-1.37s.58.49.72.68c.24.35.5.69.76 1a6 6 0 0 0 1.27 1.13 8.86 8.86 0 0 0 2.43 1.33.56.56 0 0 0 .58-.9 3.89 3.89 0 0 1-.92-1.47 1 1 0 0 1 0-.63.48.48 0 0 1 .5-.32.69.69 0 0 1 .31.2 9.87 9.87 0 0 0 4.59 2.23.56.56 0 0 0 .55-.88 2.6 2.6 0 0 1-.55-1c-.06-.74 1-1.05 1.52-1.26a15 15 0 0 1 2.19-.57 5.63 5.63 0 0 0 1.22-.4.53.53 0 0 0 .33-.25.35.35 0 0 0-.13-.4 1.23 1.23 0 0 0-.41-.17c-.59-.12-1.19-.15-1.78-.23s-2-.36-2.22-1.19a1.23 1.23 0 0 1 .12-.82 2.4 2.4 0 0 1 .68-.8.56.56 0 0 0-.28-1 9.38 9.38 0 0 0-4.63.94 1.29 1.29 0 0 1-.5.17.42.42 0 0 1-.44-.3.61.61 0 0 1 .07-.47 5 5 0 0 1 1.13-1.53.57.57 0 0 0-.32-1c-3 .06-4.67 2.72-5.42 3.47s-1.56 0-1.3-.9a5 5 0 0 1 1.73-2.29.56.56 0 0 0-.4-1 6.13 6.13 0 0 0-4.46 3.08c-1.03 1.52-2.12 2.19-2.92 1.98Z"
          style={{
            fill: "#fff",
            opacity: 0.6,
            transformOrigin: "381.859px 259.674px"
          }}
          className="ai"
        />
        <path
          d="M372.76 259.12c6.12 1.82 15.59 2 15.69 2a.2.2 0 0 0 .2-.2.19.19 0 0 0-.19-.2c-.11 0-11.49-.19-17.28-2.54a.2.2 0 0 0-.15.37c.54.19 1.12.39 1.73.57Z"
          style={{
            fill: "#fff",
            transformOrigin: "379.783px 259.645px"
          }}
          className="ai"
        />
        <path
          d="M366.54 253.78c1.19-.42 3-.54 5.33 1.07s5.53 2.6 8.25 1.42a.88.88 0 0 0 .59-.9c-.1-.38-.54-.53-.92-.64a15.25 15.25 0 0 1-1.82-.66 3.21 3.21 0 0 1-1.49-1.09.8.8 0 0 1-.1-.77.69.69 0 0 1 .6-.35 2.46 2.46 0 0 1 .71.12c2.92.83 6.47 1.33 9.36.13a.85.85 0 0 0-.2-1.62c-.94-.17-2.46-.06-3.06-1a.56.56 0 0 1-.09-.51c.09-.25.39-.33.65-.38a25.14 25.14 0 0 0 7.78-2.41c.38-.21.62-.82.26-1.14s-1.33-.27-1.74-.31a1.82 1.82 0 0 1-1.41-.54 1.69 1.69 0 0 1 .16-1.6c1.14-2.44 3.61-4.1 5.71-5.73a.58.58 0 0 0-.61-1 21.87 21.87 0 0 1-7.48 2.53 1 1 0 0 1-.66-.09.91.91 0 0 1-.31-.62 6.63 6.63 0 0 1 .09-2.39c.1-.54-.19-1.15-.88-1.06s-1.38.91-1.82 1.27a10.09 10.09 0 0 0-3 4.3 2.46 2.46 0 0 1-.35.75.73.73 0 0 1-.74.27c-.34-.12-.45-.54-.5-.9a11.23 11.23 0 0 1 0-3c.07-.48.09-1.1-.36-1.28a.94.94 0 0 0-.74.13c-3.34 1.77-3.56 7.23-4 10.43a.34.34 0 0 1-.09.22.3.3 0 0 1-.19.05 1.26 1.26 0 0 1-.89-.44 2.91 2.91 0 0 1-.51-.89 5.79 5.79 0 0 1-.38-3.52c.18-.8-.61-1.82-1.38-1a9.31 9.31 0 0 0-2.28 7.93c.33 2.8-.48 4.52-1.49 5.22Z"
          style={{
            fill: "#187032",
            transformOrigin: "380.965px 245.51px"
          }}
          className="ai"
        />
        <path
          d="M366.54 253.78c1.19-.42 3-.54 5.33 1.07s5.53 2.6 8.25 1.42a.88.88 0 0 0 .59-.9c-.1-.38-.54-.53-.92-.64a15.25 15.25 0 0 1-1.82-.66 3.21 3.21 0 0 1-1.49-1.09.8.8 0 0 1-.1-.77.69.69 0 0 1 .6-.35 2.46 2.46 0 0 1 .71.12c2.92.83 6.47 1.33 9.36.13a.85.85 0 0 0-.2-1.62c-.94-.17-2.46-.06-3.06-1a.56.56 0 0 1-.09-.51c.09-.25.39-.33.65-.38a25.14 25.14 0 0 0 7.78-2.41c.38-.21.62-.82.26-1.14s-1.33-.27-1.74-.31a1.82 1.82 0 0 1-1.41-.54 1.69 1.69 0 0 1 .16-1.6c1.14-2.44 3.61-4.1 5.71-5.73a.58.58 0 0 0-.61-1 21.87 21.87 0 0 1-7.48 2.53 1 1 0 0 1-.66-.09.91.91 0 0 1-.31-.62 6.63 6.63 0 0 1 .09-2.39c.1-.54-.19-1.15-.88-1.06s-1.38.91-1.82 1.27a10.09 10.09 0 0 0-3 4.3 2.46 2.46 0 0 1-.35.75.73.73 0 0 1-.74.27c-.34-.12-.45-.54-.5-.9a11.23 11.23 0 0 1 0-3c.07-.48.09-1.1-.36-1.28a.94.94 0 0 0-.74.13c-3.34 1.77-3.56 7.23-4 10.43a.34.34 0 0 1-.09.22.3.3 0 0 1-.19.05 1.26 1.26 0 0 1-.89-.44 2.91 2.91 0 0 1-.51-.89 5.79 5.79 0 0 1-.38-3.52c.18-.8-.61-1.82-1.38-1a9.31 9.31 0 0 0-2.28 7.93c.33 2.8-.48 4.52-1.49 5.22Z"
          style={{
            fill: "#fff",
            opacity: 0.4,
            transformOrigin: "380.965px 245.51px"
          }}
          className="ai"
        />
        <path
          d="M366.45 254.07a.48.48 0 0 0 .17 0c7.17-2 19.19-9.64 22.6-13.61a.3.3 0 0 0-.45-.39c-3.37 3.91-15.24 11.4-22.32 13.42a.29.29 0 0 0-.2.37.32.32 0 0 0 .2.21Z"
          style={{
            fill: "#fff",
            transformOrigin: "377.753px 247.035px"
          }}
          className="ai"
        />
        <path
          d="M365.28 248.75a3.69 3.69 0 0 0-2-3 6.9 6.9 0 0 1-2.29-2.33 5.14 5.14 0 0 1-.54-1.25c-.13-.44-.55-1.47.15-1.68.22-.07.44.1.61.26a9.39 9.39 0 0 0 1.05.94c.23.16 1.08.64 1.33.33s-.3-.7-.44-.88c-.27-.34-.52-.68-.77-1a6.16 6.16 0 0 1-.71-1.54 8.76 8.76 0 0 1-.58-2.71.56.56 0 0 1 1-.3 3.88 3.88 0 0 0 1.14 1.31 1 1 0 0 0 .6.19.48.48 0 0 0 .45-.39.7.7 0 0 0-.1-.36 9.89 9.89 0 0 1-.83-5 .56.56 0 0 1 1-.27 2.75 2.75 0 0 0 .82.81c.7.27 1.29-.64 1.64-1.1a14.65 14.65 0 0 0 1.18-1.93 5.88 5.88 0 0 1 .73-1.06.54.54 0 0 1 .34-.24.36.36 0 0 1 .34.24.93.93 0 0 1 0 .44c-.05.6-.19 1.18-.28 1.78s-.23 2 .51 2.46a1.22 1.22 0 0 0 .82.12 2.45 2.45 0 0 0 1-.43.57.57 0 0 1 .88.57 9.43 9.43 0 0 1-2.23 4.16 1.31 1.31 0 0 0-.31.43.43.43 0 0 0 .11.48.58.58 0 0 0 .47.06 5.13 5.13 0 0 0 1.86-.67c.48-.28 1.07.07.9.6-.92 2.84-4 3.7-4.88 4.2-1.1.59-.45 1.5.49 1.51a5 5 0 0 0 2.69-1 .56.56 0 0 1 .84.66 6.09 6.09 0 0 1-4.22 3.39c-1.77.45-2.74 1.37-2.77 2.2Z"
          style={{
            fill: "#187032",
            transformOrigin: "366.683px 238.15px"
          }}
          className="ai"
        />
        <path
          d="M365.28 248.75a3.69 3.69 0 0 0-2-3 6.9 6.9 0 0 1-2.29-2.33 5.14 5.14 0 0 1-.54-1.25c-.13-.44-.55-1.47.15-1.68.22-.07.44.1.61.26a9.39 9.39 0 0 0 1.05.94c.23.16 1.08.64 1.33.33s-.3-.7-.44-.88c-.27-.34-.52-.68-.77-1a6.16 6.16 0 0 1-.71-1.54 8.76 8.76 0 0 1-.58-2.71.56.56 0 0 1 1-.3 3.88 3.88 0 0 0 1.14 1.31 1 1 0 0 0 .6.19.48.48 0 0 0 .45-.39.7.7 0 0 0-.1-.36 9.89 9.89 0 0 1-.83-5 .56.56 0 0 1 1-.27 2.75 2.75 0 0 0 .82.81c.7.27 1.29-.64 1.64-1.1a14.65 14.65 0 0 0 1.18-1.93 5.88 5.88 0 0 1 .73-1.06.54.54 0 0 1 .34-.24.36.36 0 0 1 .34.24.93.93 0 0 1 0 .44c-.05.6-.19 1.18-.28 1.78s-.23 2 .51 2.46a1.22 1.22 0 0 0 .82.12 2.45 2.45 0 0 0 1-.43.57.57 0 0 1 .88.57 9.43 9.43 0 0 1-2.23 4.16 1.31 1.31 0 0 0-.31.43.43.43 0 0 0 .11.48.58.58 0 0 0 .47.06 5.13 5.13 0 0 0 1.86-.67c.48-.28 1.07.07.9.6-.92 2.84-4 3.7-4.88 4.2-1.1.59-.45 1.5.49 1.51a5 5 0 0 0 2.69-1 .56.56 0 0 1 .84.66 6.09 6.09 0 0 1-4.22 3.39c-1.77.45-2.74 1.37-2.77 2.2Z"
          style={{
            fill: "#fff",
            opacity: 0.6,
            transformOrigin: "366.683px 238.15px"
          }}
          className="ai"
        />
        <path
          d="M365.22 248.94h.08a.19.19 0 0 0 .17-.21c-.59-6.22 2.48-17.17 2.51-17.28a.2.2 0 0 0-.14-.25.19.19 0 0 0-.24.14c0 .11-3.12 11.14-2.52 17.43a.21.21 0 0 0 .14.17Z"
          style={{
            fill: "#fff",
            transformOrigin: "366.496px 240.066px"
          }}
          className="ai"
        />
      </g>
    </g>
    <g
      className="snow-globe__tree ai"
      style={{
        transformOrigin: "206.055px 173.109px"
      }}
    >
      <g
        className="ai"
        style={{
          transformOrigin: "206.055px 173.109px"
        }}
      >
        <path
          d="M184.85 260.32C173 267 172.89 278 184.59 285s30.8 7.13 42.66.46 12-17.7.26-24.63-30.81-7.18-42.66-.51Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "206.059px 272.915px"
          }}
          className="ai"
        />
        <path
          d="M216.27 251.1c0-1.52-1-3-3-4.2a15.85 15.85 0 0 0-14.44 0c-2 1.16-3 2.68-3 4.2V273c0 1.52 1 3 3 4.19a15.85 15.85 0 0 0 14.44 0c2-1.16 3-2.67 3-4.19Z"
          style={{
            fill: "#263238",
            transformOrigin: "206.05px 262.045px"
          }}
          className="ai"
        />
        <path
          d="M272.25 241.92c-5.78-6.45-7.78-6.42-13.24-11.43 10.38-1.35 15.87 2.13 3.86-10.82-10.82-11.67-18.9-33.43-21.65-51 8.08 2.93 12.09-1.68 6.69-7.57-12.52-13.67-22.4-29.35-26.59-46.43 4.34-1.16 11.49 4.13 7.56-5.32-1.94-4.67-9.38-20.16-11.75-25.8-3.2-7.59-9-27.36-13-27.69-5.18 0-9.84 20.1-13 27.69-2.36 5.64-9.81 21.13-11.73 25.78-3.9 9.44 3.2 4.17 7.55 5.35-4.25 17-13.11 30.19-22.37 45.88-3.18 5.41-1.21 11.23 7 8.28-3 17.79-16.17 38.71-26.26 50.88-11.19 13.48-5.22 9.9 5.51 10.75-6 4.4-16.5 9.85-14.15 12 6.32 5.84 32.39 5.67 37.22 2.74-2.93 3.47-8.75 8.76-1.86 10.8s17.39-1.54 21.93-5.79c1.43 6.24 4 11.86 11.49 12.36s12.18-5.31 13.07-11.58c4.54 4.3 12.24 8.92 19 6.57s3.89-8.93.93-12.36c4.82 3.03 39.54 3.17 33.79-3.29Z"
          style={{
            fill: "#37474f",
            transformOrigin: "204.617px 159.235px"
          }}
          className="ai"
        />
        <path
          d="M276.93 229.38c-5.68-6.08-7.31-7-12.74-10.56 9.7-.74 14.64 2.27 3.33-9.33-10.21-10.49-22.52-30.65-25.77-46.72 7.73 2.39 9.4-3 6.12-7.89-9.54-14.17-18.17-26.07-22.81-41.78 4-1.24 10.86 3.41 6.82-5.25-2-4.28-9.58-18.45-12-23.62-3.29-7-10.54-25.81-13.3-25.34-2.5 0-8.33 19.09-11 26.28-2 5.34-8.25 20-9.86 24.46-3.24 9 4 5.16 7.5.6-3.26 16-11.06 32.69-19.24 47.58-2.82 5.12-.68 10.49 6.82 7.44-2 16.35-12.39 37.79-22.05 49-10.71 12.45-3.53 12 5.24 10.08-5.93 5.85-12.87 8.17-14.79 12.08-3.91 7.94 32.66 1.31 39.35-4.42-3.3 3.67-8.82 12.13-1.21 13.84 6.7 1.5 14-1.68 16.83-4.43 1.42 5.49 4.67 11.62 11.77 10.44s8.71-7.17 9.59-12.82c3.39 2.43 11.2 5.26 17.5 2.53s.25-9.35-2.57-12.82c4.36 2.92 42.14 6.72 36.47.65Z"
          style={{
            fill: "#455a64",
            transformOrigin: "213.208px 155.434px"
          }}
          className="ai"
        />
        <path
          d="M179.44 109.08c-.78 2.56-.56 3.7 2.28 3.5s9.57-3.85 11.5-6.34c-3 5.4-6.2 8.92-12 9.26s-4.94-3.41-1.78-6.42Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "185.091px 110.881px"
          }}
          className="ai"
        />
        <path
          d="M194.05 122.23a8.73 8.73 0 0 0 7.5 1.84c3.08-.5 5.41-2.4 5.7-5.45a6.55 6.55 0 0 0 4.79 4.27 5.69 5.69 0 0 0 5.86-1.43c.2 2.6-1.19 4.57-4.09 4.7a7 7 0 0 1-6.31-3.16c-1.12 4.24-4.13 6-8.88 5.35a5 5 0 0 1-4.57-6.12Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "205.92px 123.551px"
          }}
          className="ai"
        />
        <path
          d="M207.21 84.79c1.47 2 6.4 5.18 9 5.19s3.48-.63 2.33-3.32c1.67 1.82 4.29 5.25.14 5.48-4.39.24-9.56-3.57-11.47-7.35Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "214.057px 88.4704px"
          }}
          className="ai"
        />
        <path
          d="M230.27 138.12c1.2 2.29 1.41 3.59-2.13 3.86s-9-3.09-10.94-6.49c.72 3.58 4.49 8.35 10.53 9.64s6.94-3.78 2.54-7.01Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "225.111px 140.413px"
          }}
          className="ai"
        />
        <path
          d="M180.9 131.78c.36 2 .2 3.3 3.34 3.46s7.33-2.25 9.54-4.56c-2.28 4.23-5.79 8.09-11.22 7.68s-4.42-3.78-1.66-6.58Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "186.217px 134.535px"
          }}
          className="ai"
        />
        <path
          d="M164.43 160.71c-1 2.6-.88 4.59 4 4s11.57-5.19 15.23-8.71c-3.14 6.52-8.43 14.56-16.66 15s-5-6.34-2.57-10.29Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "172.687px 163.51px"
          }}
          className="ai"
        />
        <path
          d="M198.37 163a13.59 13.59 0 0 0 11.6 7.73c4.87.1 6.58-1.72 7.05-5.65 3.75 3.57 8.95 7.4 12 1.3 1.75 2.52 2.39 5.23-1.64 5.92-3.32.57-6.49-1.38-8.93-3.3-1.17 4.06-3.42 5.73-8.53 5.06-6.09-.75-10.79-5.06-11.55-11.06Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "214.377px 168.602px"
          }}
          className="ai"
        />
        <path
          d="M220.63 108.88a11.34 11.34 0 0 0 9.94 5.91c4.18 0 3.45-2.89 1.94-5.47.51 1.76-.55 3.51-3.14 3.32s-6.37-2.1-8.74-3.76Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "227.164px 111.835px"
          }}
          className="ai"
        />
        <path
          d="M248.51 156c.91 6.95-5.88 5.51-10.42 3.7 2.24 2.68 5.31 4.51 9 4.06 4.75-.61 3.91-4.65 1.42-7.76Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "244.305px 159.914px"
          }}
          className="ai"
        />
        <path
          d="M167 184.45c2.42 8.82 13.91 1.61 19.18-1.53-3.11 6-8.81 10-15.9 9.71s-7.06-4.1-3.28-8.18Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "175.326px 187.782px"
          }}
          className="ai"
        />
        <path
          d="M199.71 197.19a12.19 12.19 0 0 0 9.38 7c4.69.92 7.36-1.34 8.18-5.61 1.74 2.76 4.69 5.5 8.21 5.55s4.51-1.64 4.84-4.44c3 2.45 6.68 5.7 10.05 2.13 1.42 2.18 1.26 4.76-2 4.43s-5.2-2-7.05-4.26c1 3.66-.23 7.41-5 6.9s-7.14-3.59-8.68-7.42c.21 4-.9 7.39-6 7.1-7.37-.44-10.88-4.93-11.93-11.38Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "220.465px 203.063px"
          }}
          className="ai"
        />
        <path
          d="M144.22 221c-2 2.51-4.61 6.59-1.08 6.68 6.85.18 11.16-2.88 16.67-5.68-6.17 7-12.32 13.07-22.85 11.63-7.55-1.08 5.27-10.16 7.26-12.63Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "147.206px 227.422px"
          }}
          className="ai"
        />
        <path
          d="M165.49 209.32c.17-.14-2.22 4.4 3.75 4.91 5.21.45 13.58-5.49 17.58-10.77-3.37 6.47-10.24 14.2-17.56 14.18-7.63-.01-7.71-4.89-3.77-8.32Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "174.867px 210.55px"
          }}
          className="ai"
        />
        <path
          d="M176.29 243.24c-2.6 2.78-6.39 8.16-.55 10.32s13.94 0 18.17-3.35c-5 5.28-14.26 8.71-23.31 6.42s-.12-10.23 5.69-13.39Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "180.324px 250.296px"
          }}
          className="ai"
        />
        <path
          d="M203.64 237.53c.44 7.31 3 15.31 12.28 15.26 7.32 0 10.12-7.53 9.61-13.74-.92 3.83-2.26 9.15-9.45 9.41s-12.08-4.82-12.44-10.93Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "214.614px 245.16px"
          }}
          className="ai"
        />
        <path
          d="M269.8 211.79c3 2.41 10.86 8 6.31 9.06-8.13 1.87-13.88-2.36-20.19-6.46 4 1.28 8.85 3.55 13.1 3s2.75-4.03.78-5.6Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "266.708px 216.548px"
          }}
          className="ai"
        />
        <path
          d="M236.6 244.65c11.06 1.9 20 1.76 24.81 1.12 6.46-.87 10.65-3.78 5.8-8.78 2.87 1.71 5.65 2.89 7.42 5.85s-3.11 5.21-11.65 5.77c-6.42.39-21.74-.84-26.38-3.96Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "255.795px 242.835px"
          }}
          className="ai"
        />
        <path
          d="M187.24 92.35c1.25 5.55 9 0 11.8-2.07-2.39 4.2-7.21 7.44-11 7s-2.81-3.15-.8-4.93Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "192.252px 93.8px"
          }}
          className="ai"
        />
        <path
          d="M202.22 98.75c3.13 4.63 14.86 9.73 11.37.92 2.78 2.31 4.84 6.71-.44 7.27-6.02.64-9.21-3.29-10.93-8.19Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "209.321px 102.879px"
          }}
          className="ai"
        />
        <path
          d="M192 146.53c4.68 4.57 9.87.36 13.73-3.14-1.08 4.64-4.31 8.85-9.64 8.52-3.87-.24-4.15-2.56-4.09-5.38Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "198.862px 147.659px"
          }}
          className="ai"
        />
        <path
          d="M195 72c-1.32 4 5.2 2.22 9-4.29-1.11 3.68-3.59 7.75-7.73 8.47S193.39 73.71 195 72Z"
          style={{
            fill: "#fafafa",
            transformOrigin: "198.739px 71.9971px"
          }}
          className="ai"
        />
      </g>
    </g>
    <g
      className="snow-globe__snowman ai"
      style={{
        transformOrigin: "309.151px 225.134px"
      }}
    >
      <g
        className="snow-globe__snowman ai"
        style={{
          transformOrigin: "309.151px 225.134px"
        }}
      >
        <path
          d="M267.8 278.84c-18.83 11.12-19 29.47-.44 41s48.92 11.85 67.76.73 19-29.46.44-41-48.93-11.85-67.76-.73Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "301.462px 299.703px"
          }}
          className="ai"
        />
        <path
          d="M355.05 201.37c-7 4-14.44 7.6-22.13 11.36-1.55.6-2.53.16-3-1.16s-.22-2.23 1.35-2.87c7.58-3.84 14.8-7.47 22.12-11.44a1.24 1.24 0 0 0 .76-1.23c.47-4.32 1.33-9.06 1.89-13.27a3.34 3.34 0 0 0-1.25-3.15 13.78 13.78 0 0 1-4.19-7c-.25-1.15.08-1.68 1.13-1.91s1.58 0 2 1.61c.08.28 1.1 3.64 2.2 4 .24.07.95.28 1-.4.38-3.07.81-6.43 1.28-9.45.23-1.44.52-1.86 1.75-2.1a2.84 2.84 0 0 1 1.93.43 2.07 2.07 0 0 1 .68 2.13c-.36 3-.84 6.34-1.25 9.41-.09.69.64.65.91.62 1.5-.16 3.2-4.07 3.38-4.47.82-1.87 1-2.11 2-2.19a1.53 1.53 0 0 1 1.26.61c.24.32.38.92-.32 2.75-.46 1.22-3.28 6.4-6.16 6.93a3.54 3.54 0 0 0-2 2.76c-.52 3.8-1.14 8.59-1.82 12.54-.5 2.85-.73 3.91-3.52 5.49Z"
          style={{
            fill: "#263238",
            transformOrigin: "349.387px 188.664px"
          }}
          className="ai"
        />
        <path
          d="M301.46 315.19a49.13 49.13 0 0 1-32-11.33c-9.22-7.9-14.09-18.72-14.09-31.27s4.87-23.37 14.09-31.27a51 51 0 0 1 64.1 0c9.22 7.91 14.09 18.72 14.09 31.27s-4.87 23.37-14.09 31.27a49.09 49.09 0 0 1-32.1 11.33Z"
          style={{
            fill: "#f5f5f5",
            transformOrigin: "301.51px 272.593px"
          }}
          className="ai"
        />
        <path
          d="M301.46 230.46a48.58 48.58 0 0 1 31.75 11.22c9.11 7.81 13.93 18.5 13.93 30.91s-4.82 23.1-13.93 30.92a48.63 48.63 0 0 1-31.75 11.21c-12 0-23.31-4-31.74-11.21-9.12-7.82-13.93-18.51-13.93-30.92s4.81-23.1 13.93-30.91c8.43-7.24 19.71-11.22 31.74-11.22m0-.93c-24.13 0-46.6 16.15-46.6 43.06s22.47 43.06 46.6 43.06 46.61-16.15 46.61-43.06-22.48-43.06-46.61-43.06Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "301.465px 272.59px"
          }}
          className="ai"
        />
        <path
          d="M301.45 229.53c13.07 0 25.65 4.75 34.44 13.37-2.35 11.13-20.24 20-34.44 20s-31.95-8.9-34.45-20c8.81-8.62 21.38-13.37 34.45-13.37Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "301.445px 246.215px"
          }}
          className="ai"
        />
        <path
          d="M301.45 257.48a39.44 39.44 0 0 1-25.78-9.11 33.55 33.55 0 0 1 0-50.29 41 41 0 0 1 51.57 0 33.57 33.57 0 0 1 0 50.29 39.49 39.49 0 0 1-25.79 9.11Z"
          style={{
            fill: "#f5f5f5",
            transformOrigin: "301.45px 223.22px"
          }}
          className="ai"
        />
        <path
          d="M301.45 189.43a39 39 0 0 1 25.48 9 33.1 33.1 0 0 1 0 49.59 40.57 40.57 0 0 1-51 0 33.1 33.1 0 0 1 0-49.59 39 39 0 0 1 25.48-9m0-.93c-19.46 0-37.58 13-37.58 34.73S282 258 301.45 258s37.58-13 37.58-34.72-18.12-34.73-37.58-34.73Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "301.43px 223.25px"
          }}
          className="ai"
        />
        <path
          d="M325.29 216.16a2.49 2.49 0 0 0 .51 1.74c1 1.25 3.18 1.38 3.57-.45a2.81 2.81 0 0 0-.7-2.35 2 2 0 0 0-1.87-.62 1.79 1.79 0 0 0-1.18.68 2 2 0 0 0-.3.74 1.15 1.15 0 0 0-.03.26Z"
          className="ai"
          style={{
            transformOrigin: "327.344px 216.638px"
          }}
        />
        <path
          d="M328.59 225.25a2.54 2.54 0 0 0 0 1.82c.61 1.47 2.68 2.2 3.56.54a2.82 2.82 0 0 0 0-2.44 2 2 0 0 0-1.63-1.11 1.74 1.74 0 0 0-1.31.33 1.79 1.79 0 0 0-.5.63c-.06.08-.09.16-.12.23Z"
          className="ai"
          style={{
            transformOrigin: "330.424px 226.3px"
          }}
        />
        <path
          d="M328.82 234.68a2.51 2.51 0 0 0-.95 1.54c-.28 1.57 1.08 3.29 2.71 2.37a2.84 2.84 0 0 0 1.28-2.08 2 2 0 0 0-.78-1.82 1.75 1.75 0 0 0-1.29-.42 2.08 2.08 0 0 0-.76.26Z"
          className="ai"
          style={{
            transformOrigin: "329.853px 236.556px"
          }}
        />
        <path
          d="M278.71 238.6a3.15 3.15 0 0 0 .09-.52c0-.07 0-.41-.17-.4s-.2.22-.24.36l-.12.48a7.65 7.65 0 0 0-.17 1 9.07 9.07 0 0 0 0 1 2.06 2.06 0 0 0 .23 1.15c0 .05.11.1.16 0a.64.64 0 0 0 .11-.47v-.58a7.94 7.94 0 0 1 0-1.09 9.31 9.31 0 0 1 .11-.93Z"
          style={{
            fill: "#187032",
            transformOrigin: "278.443px 239.704px"
          }}
          className="ai"
        />
        <path
          d="M280.29 243.13a3.91 3.91 0 0 1-.19-1.77c0-.74 0-1.47-.1-2.21a.21.21 0 0 0-.11-.2.11.11 0 0 0-.14.11c-.08.78-.12 1.56-.13 2.35a2.59 2.59 0 0 0 .42 2c.18.18.34-.1.25-.28Z"
          style={{
            fill: "#187032",
            transformOrigin: "279.942px 241.207px"
          }}
          className="ai"
        />
        <path
          d="M281.64 243.73a1.74 1.74 0 0 1-.05-.72v-1.06a12.39 12.39 0 0 0-.07-1.83c0-.16-.24-.35-.28-.09a14.54 14.54 0 0 0-.09 2.19v1a.88.88 0 0 0 .27.74c.17.23.32-.04.22-.23Z"
          style={{
            fill: "#187032",
            transformOrigin: "281.407px 241.975px"
          }}
          className="ai"
        />
        <path
          d="M283.79 243.56v-1.65a3.11 3.11 0 0 0-.1-.86.09.09 0 0 0-.16 0 .89.89 0 0 0-.07.36v1.97a2.38 2.38 0 0 0 .08.88c.05.1.22.26.31.1a1.47 1.47 0 0 0-.06-.8Z"
          style={{
            fill: "#187032",
            transformOrigin: "283.664px 242.715px"
          }}
          className="ai"
        />
        <path
          d="M285.37 241.68c0-.21-.26-.31-.27-.05 0 .78 0 1.57-.07 2.35v1.09a4.24 4.24 0 0 0 0 1.18c0 .11.24.37.33.16a2.89 2.89 0 0 0 .12-1.1v-1.27c-.01-.79-.05-1.58-.11-2.36Z"
          style={{
            fill: "#187032",
            transformOrigin: "285.239px 243.982px"
          }}
          className="ai"
        />
        <path
          d="M286.59 245.17v-1.79a1.85 1.85 0 0 0-.15-.74s-.05 0-.07 0a.45.45 0 0 0-.15.33v1.86a1.27 1.27 0 0 0 .11.77c.05.06.12.13.2.11a.12.12 0 0 0 .07 0s.06-.12 0-.12-.01-.34-.01-.42Z"
          style={{
            fill: "#187032",
            transformOrigin: "286.414px 244.178px"
          }}
          className="ai"
        />
        <path
          d="M288.75 242.55c0-.18-.21-.17-.2 0a20.59 20.59 0 0 1-.08 2.22c0 .34 0 .68-.08 1a5.56 5.56 0 0 0-.07 1.15c0 .12.2.34.31.16a3 3 0 0 0 .22-1.15 11.45 11.45 0 0 0 .07-1.16 18.94 18.94 0 0 0-.17-2.22Z"
          style={{
            fill: "#187032",
            transformOrigin: "288.617px 244.783px"
          }}
          className="ai"
        />
        <path
          d="M290.55 246.12c0-.28 0-.57-.05-.85a8.45 8.45 0 0 0-.3-1.74c-.05-.17-.23-.12-.23 0 0 .51 0 1 .07 1.54v.8a1.88 1.88 0 0 0 .12.91c0 .07.17.16.25.08s.15-.48.14-.74Z"
          style={{
            fill: "#187032",
            transformOrigin: "290.26px 245.156px"
          }}
          className="ai"
        />
        <path
          d="M292.19 246.2v-.75a9.68 9.68 0 0 0-.25-1.7c0-.13-.18-.13-.19 0V246.18a1.62 1.62 0 0 0 .11.79.15.15 0 0 0 .11.11h.11c.04 0 .11-.38.11-.48s.01-.27 0-.4Z"
          style={{
            fill: "#187032",
            transformOrigin: "291.967px 245.366px"
          }}
          className="ai"
        />
        <path
          d="M294.56 215.21c-3.78 9.8-2 29.14-2 29.14s-10.6-.16-14.93-6.18c-1.12-8.67 3.77-27.29 3.77-27.29Z"
          style={{
            fill: "#187032",
            transformOrigin: "286.012px 227.615px"
          }}
          className="ai"
        />
        <path
          d="M294.56 215.21c-3.78 9.8-2 29.14-2 29.14s-10.6-.16-14.93-6.18c-1.12-8.67 3.77-27.29 3.77-27.29Z"
          style={{
            opacity: 0.15,
            transformOrigin: "286.012px 227.615px"
          }}
          className="ai"
        />
        <path
          d="M275.14 188.29c-1.33-.84-2 2.26-1.56 5.75.4 2.91-2 3.11-3 5.61-1.69 4.16 7.09 17.51 32 18.34 24.06.81 30-14.81 30-17.15a5.72 5.72 0 0 0-2.29-4.92c-3.25-2.87-1.22-4.82-3.66-5.55Z"
          style={{
            fill: "#187032",
            transformOrigin: "301.484px 203.086px"
          }}
          className="ai"
        />
        <path
          d="M307.65 217.91a11.41 11.41 0 0 0-.28-4 16.89 16.89 0 0 1-.59-2.53c-.17-1.76.35-3.54.39-5.3a25.68 25.68 0 0 0-.47-5.32c0-.12-.15-.1-.15 0 .06 1.81.29 3.61.23 5.42a46 46 0 0 0-.4 5c.12 1.56.78 3 .9 4.58a12 12 0 0 1 0 2.16Z"
          style={{
            fill: "#fff",
            transformOrigin: "307.047px 209.299px"
          }}
          className="ai"
        />
        <path
          d="M329.81 204.94a11.57 11.57 0 0 0-2.82-4.22 6 6 0 0 1-1.65-2.69c-.22-.92-.22-1.88-.42-2.81s-1.06-2.8-2.4-2.71c-.09 0-.11.19 0 .22a2.91 2.91 0 0 1 1.8 1.63 8.34 8.34 0 0 1 .49 2.38 7.28 7.28 0 0 0 .54 2.3 7.2 7.2 0 0 0 1.23 1.77 12.24 12.24 0 0 1 2.8 3.93 8.31 8.31 0 0 1 .5 2.26l.33-.47a9.42 9.42 0 0 0-.4-1.59Z"
          style={{
            fill: "#fff",
            transformOrigin: "326.327px 199.753px"
          }}
          className="ai"
        />
        <path
          d="M297.13 201.52c-1 1-2.57 1.37-2.83 2.92-.29 1.79 1.46 3.72.47 5.39-.77 1.29-2.59 1.8-2.92 3.39a4.81 4.81 0 0 0 .44 2.29 2.91 2.91 0 0 1 .08 1.31l.45.09a4.6 4.6 0 0 0-.32-2 2.45 2.45 0 0 1-.17-1.66 2.94 2.94 0 0 1 .77-1.08 22.43 22.43 0 0 0 1.78-1.59c1.27-1.53.16-3.21-.12-4.87a2.37 2.37 0 0 1 1-2.51 5.23 5.23 0 0 0 1.62-1.33c.06-.13-.09-.51-.25-.35Z"
          style={{
            fill: "#fff",
            transformOrigin: "294.618px 209.196px"
          }}
          className="ai"
        />
        <path
          d="M322.83 213.45v-.35a3 3 0 0 0-.94-1.83c-.7-.67-1.69-1.12-1.72-2.22s.63-2 .77-3.05a3.93 3.93 0 0 0-1.88-3.79 5.51 5.51 0 0 1-1.84-1.58c-.59-1.08.08-2.31.59-3.27.05-.09-.05-.37-.15-.24-.89 1.22-1.47 2.86-.41 4.15a8.22 8.22 0 0 0 1.73 1.37 3.36 3.36 0 0 1 1.6 2.57c.1 1.79-1.63 3.55-.24 5.21.52.62 1.3 1 1.75 1.64a2.3 2.3 0 0 1 .32 1.64Z"
          style={{
            fill: "#fff",
            transformOrigin: "319.755px 205.393px"
          }}
          className="ai"
        />
        <path
          d="M281.47 211.5a12.75 12.75 0 0 0 2.12-3.61 33 33 0 0 0 .81-3.93 10.5 10.5 0 0 1 .7-2.4 8.69 8.69 0 0 1 .44-.86c.4-.66 1-1.19 1.42-1.83.05-.08 0-.33-.13-.26-1.26.68-2.11 2.43-2.51 3.73-.46 1.51-.55 3.09-.94 4.62a13.75 13.75 0 0 1-2.56 4.53c-.21.29-.43.6-.63.93l.47.26c.25-.4.52-.8.81-1.18Z"
          style={{
            fill: "#fff",
            transformOrigin: "283.585px 205.639px"
          }}
          className="ai"
        />
        <path
          d="m274.61 208.32.35.33a2.85 2.85 0 0 0 1.2-1.59 7.74 7.74 0 0 0-.47-2.76 2.31 2.31 0 0 1 1.13-2.69 5.86 5.86 0 0 0 2-1.19 2.68 2.68 0 0 0 .4-2.22c-.2-1.12-.9-2.33-.36-3.45a3.31 3.31 0 0 1 2.18-1.54c.12 0 .07-.32-.06-.31a2.79 2.79 0 0 0-2.71 2.37c-.13 1.57 1.49 3.73 0 5-1 .85-2.51.89-2.9 2.37a4.39 4.39 0 0 0 .09 2.13c.23.95.71 1.95 0 2.81a6.78 6.78 0 0 1-.85.74Z"
          style={{
            fill: "#fff",
            transformOrigin: "277.859px 200.775px"
          }}
          className="ai"
        />
        <path
          d="M312.94 208.4c.18-1.17-9.06 3.23-21.61 0a24.43 24.43 0 0 1-17.89-16.94 14.53 14.53 0 0 0 .14 2.58c.31 2.3-1.1 2.91-2.21 4.29 2 3.62 7.22 9.32 16.73 12.49 12.37 4.12 24.66-1.26 24.84-2.42Z"
          style={{
            opacity: 0.15,
            transformOrigin: "292.156px 201.905px"
          }}
          className="ai"
        />
        <path
          d="M285.58 229.49a39.42 39.42 0 0 1-7.39-2.87l-.06.38a33.29 33.29 0 0 0 14 4.11v-.56a35.16 35.16 0 0 1-6.55-1.06Z"
          style={{
            fill: "#fff",
            transformOrigin: "285.13px 228.865px"
          }}
          className="ai"
        />
        <path
          d="M292.22 238.77a2 2 0 0 1-1.19.33 2.42 2.42 0 0 1-1.57-.91c-1.14-1.3-1.78-3.6-4-2.88-1 .33-1.88 1.19-3 1.2-1.29 0-1.61-1.3-2.08-2.23a2.46 2.46 0 0 0-1.89-1.5 1.46 1.46 0 0 0-1 .36v.46a1.62 1.62 0 0 1 2.51.59 11.57 11.57 0 0 0 1.1 2.09c1.39 1.59 3.09-.05 4.56-.52 2.29-.75 2.79 2.33 4.28 3.36a2.17 2.17 0 0 0 2.1.29l.2-.1c0-.18-.01-.31-.02-.54Z"
          style={{
            fill: "#fff",
            transformOrigin: "284.865px 236.169px"
          }}
          className="ai"
        />
        <path
          d="M283 220.92c1 1.28 1.8 3.13 3.75 2.77s3.44-2.78 5.47-2.07a3 3 0 0 1 .72.4c0-.16 0-.32.07-.48a3 3 0 0 0-1.33-.56c-2.15-.24-3.4 2.18-5.46 2.24-1.16 0-1.85-.94-2.43-1.8a5.23 5.23 0 0 0-1.79-1.86 3.38 3.38 0 0 0-2.48-.28l-.09.41a3.35 3.35 0 0 1 3.57 1.23Z"
          style={{
            fill: "#fff",
            transformOrigin: "286.22px 221.448px"
          }}
          className="ai"
        />
        <path
          d="M301.45 202.58c-13.92 0-28.72-9.29-28.72-26.5s14.8-26.49 28.72-26.49 28.72 9.28 28.72 26.49-14.8 26.5-28.72 26.5Z"
          style={{
            fill: "#f5f5f5",
            transformOrigin: "301.45px 176.085px"
          }}
          className="ai"
        />
        <path
          d="M301.45 150.05A30.06 30.06 0 0 1 321.1 157a25.49 25.49 0 0 1 0 38.18 31.29 31.29 0 0 1-39.3 0 25.49 25.49 0 0 1 0-38.18 30.06 30.06 0 0 1 19.65-6.94m0-.93c-15.11 0-29.18 10.11-29.18 27s14.07 27 29.18 27 29.18-10.11 29.18-27-14.07-27-29.18-27Z"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "301.45px 176.13px"
          }}
          className="ai"
        />
        <path
          d="M314.91 183.56a8.09 8.09 0 0 1 2.38-4.22c1.53-1.43 3-1.7 3.89-1.1 1.64.88 8.66 3.51 9.45 3.72a54.3 54.3 0 0 0 11.61 1.81 3.82 3.82 0 0 1 .9.1c.37.18.38.56.27 1a2.83 2.83 0 0 1-.9 1.41 12.39 12.39 0 0 1-5.19 2.17 43.7 43.7 0 0 1-14.06.36 34.61 34.61 0 0 1-6.86-1.3h-.06c-1.21-.33-1.89-1.75-1.43-3.95Z"
          style={{
            fill: "#187032",
            transformOrigin: "329.118px 183.577px"
          }}
          className="ai"
        />
        <path
          d="M343.14 183.87a3.82 3.82 0 0 0-.9-.1h-.28c1.1.73.16 2.56-3.41 3.22a58.1 58.1 0 0 1-16.45 0c-4.25-.81-6.63-2.63-6.64-5a8.59 8.59 0 0 0-.55 1.62c-.46 2.2.22 3.62 1.43 3.9h.06a34.61 34.61 0 0 0 6.86 1.3 43.7 43.7 0 0 0 14.06-.36 12.39 12.39 0 0 0 5.19-2.17 2.83 2.83 0 0 0 .9-1.41c.11-.44.1-.82-.27-1Z"
          style={{
            opacity: 0.15,
            transformOrigin: "329.118px 185.602px"
          }}
          className="ai"
        />
        <g
          style={{
            opacity: 0.15,
            transformOrigin: "328.66px 184.545px"
          }}
          className="ai"
        >
          <path
            d="m326.29 180.38-1.09-.38c-2 .19-3.65 2.15-4.32 4.05a6 6 0 0 0-.34 3.57 3.09 3.09 0 0 0 .47.92l.55.07a1.58 1.58 0 0 1-.3-.28 2.53 2.53 0 0 1-.52-1.61 7.57 7.57 0 0 1 .82-3.22 5.09 5.09 0 0 1 4.73-3.12Z"
            className="ai"
            style={{
              transformOrigin: "323.35px 184.305px"
            }}
          />
          <path
            d="M335.87 183.08a3.76 3.76 0 0 0-.91.57 3.15 3.15 0 0 0-.82 1 4.22 4.22 0 0 0-.43 1.4 2.75 2.75 0 0 0 .48 2.22 3.1 3.1 0 0 0 .54.55l.64-.09a2.21 2.21 0 0 1-.8-.55 2.15 2.15 0 0 1-.47-2.06 4.48 4.48 0 0 1 1.06-2 2.89 2.89 0 0 1 1-.63 3.58 3.58 0 0 1 .55-.19h.2Z"
            className="ai"
            style={{
              transformOrigin: "335.279px 185.95px"
            }}
          />
          <path
            d="M327.14 187.93a4.72 4.72 0 0 1 .05-2.66 5 5 0 0 1 1.38-2.2 4.06 4.06 0 0 1 1.22-.79 2.92 2.92 0 0 1 1.43-.18c-.19 0-.39-.08-.59-.14l-.72-.24a4.16 4.16 0 0 0-1.68 1 5.36 5.36 0 0 0-1.5 2.67 5 5 0 0 0 .24 3.06 2.5 2.5 0 0 0 .41.64h.51a2.18 2.18 0 0 1-.75-1.16Z"
            className="ai"
            style={{
              transformOrigin: "328.907px 185.405px"
            }}
          />
        </g>
        <path
          d="M321 172.2a3.37 3.37 0 0 0 .71 2.37c1.34 1.71 4.33 1.89 4.87-.61a3.87 3.87 0 0 0-1-3.2 2.76 2.76 0 0 0-2.56-.85 2.28 2.28 0 0 0-2 1.94 2.11 2.11 0 0 0-.02.35Z"
          className="ai"
          style={{
            transformOrigin: "323.804px 172.854px"
          }}
        />
        <path
          d="M304 178.34a3.81 3.81 0 0 0-.17 1.3 2.9 2.9 0 0 0 1.78 2.29 3.75 3.75 0 0 0 3.67-.24 3.37 3.37 0 0 0 .36-4.42c-1.64-2.39-4.77-1.59-5.64 1.07Z"
          className="ai"
          style={{
            transformOrigin: "307.064px 179.073px"
          }}
        />
        <path
          d="M309.71 194.49a11.82 11.82 0 0 1-4.27-.19 5.68 5.68 0 0 1-1.77-.76 3.06 3.06 0 0 1-1.06-1 .32.32 0 0 1 0-.09c0-.06.18-.17.23-.22a3.11 3.11 0 0 0 .16-.23 3.4 3.4 0 0 0 .38-.63 3.15 3.15 0 0 0 .28-1.39c0-.46-.86-.58-.85-.05a2.76 2.76 0 0 1-.94 2 3.58 3.58 0 0 1-2.7.77c-.15 0-.21.16-.19.28a.42.42 0 0 0 .3.32 3.84 3.84 0 0 0 1.31 0 3.5 3.5 0 0 0 .64-.15 2 2 0 0 1 .53-.16c.09 0 .16.08.22.14a5.32 5.32 0 0 0 1.07 1.05 6.76 6.76 0 0 0 2 .93 10 10 0 0 0 4.88.06c.66-.1.32-.73-.22-.68Z"
          className="ai"
          style={{
            transformOrigin: "304.635px 192.512px"
          }}
        />
        <path
          d="M311.67 159.47c6-9.73 1.64-17.91-9.8-18.26s-25.6 7.26-31.63 17-1.63 17.91 9.81 18.26 25.6-7.27 31.62-17Z"
          style={{
            fill: "#455a64",
            transformOrigin: "290.949px 158.84px"
          }}
          className="ai"
        />
        <path
          d="M264.94 148.8 297 131.58l7 19.55c.7 1.89.34 4.31-1.27 6.91-3.6 5.82-12.08 10.38-18.92 10.17-3.06-.1-5.28-1.13-6.47-2.76Z"
          style={{
            fill: "#37474f",
            transformOrigin: "284.64px 149.898px"
          }}
          className="ai"
        />
        <path
          d="M276.33 162.16a11.48 11.48 0 0 0 3.78.69 19 19 0 0 0 2.25-.08 26.34 26.34 0 0 0 16.64-8.94 17.52 17.52 0 0 0 1.3-1.83 11.48 11.48 0 0 0 1.51-3.53 6.76 6.76 0 0 0-.1-3.7l2.3 6.37c.7 1.89.33 4.3-1.28 6.91-3.6 5.82-12.07 10.38-18.92 10.17-3.06-.1-5.28-1.13-6.47-2.76l-4-5.42a6.68 6.68 0 0 0 2.99 2.12Z"
          style={{
            fill: "#187032",
            transformOrigin: "288.844px 156.498px"
          }}
          className="ai"
        />
        <ellipse
          cx={280.55}
          cy={139.47}
          rx={18.23}
          ry={10.53}
          style={{
            fill: "#455a64",
            transformOrigin: "280.55px 139.47px",
            transform: "rotate(-28.25deg)"
          }}
          className="ai"
        />
        <path
          d="M250.83 232.23c5.45-6.58 12.7-13.9 18.34-20.41a2 2 0 0 1 3.17-.28c.93.82 1.35 1.78.16 3.15-5.8 6.69-12.75 13.68-18.42 20.72A1.15 1.15 0 0 0 254 237c2.24 4.28 5 9.22 7.07 13.42a3.31 3.31 0 0 0 2.94 2 12.56 12.56 0 0 1 7.45 3.32 1.44 1.44 0 0 1 .18 2.24c-.61.69-1.31.92-2.46-.09-.21-.18-3.07-2.31-4.25-2.12-.26 0-1 .33-.65.94 1.47 2.76 3.1 5.78 4.45 8.5.65 1.3.58 2-.31 2.79a3 3 0 0 1-1.63.66 2 2 0 0 1-1.92-1.31c-1.42-2.69-3-5.69-4.44-8.46-.33-.62-.95-.2-1.14 0-1 1.2-.15 5.5 0 5.88.5 1.79.42 2.06-.36 2.7a2.11 2.11 0 0 1-1.11.33c-.73-.09-1-.24-1.56-2.13-.29-1.07-1.24-7.34.91-9.67a3.62 3.62 0 0 0 .06-3.56c-1.88-3.88-5.11-9.13-6.93-13.1-1.36-2.85-1.65-4.48.53-7.11Z"
          style={{
            fill: "#263238",
            transformOrigin: "261.233px 239.774px"
          }}
          className="ai"
        />
      </g>
    </g>
    <g
      className="snow-globe__gifts ai"
      style={{
        transformOrigin: "199.84px 298.68px"
      }}
    >
      <g
        className="ai"
        style={{
          transformOrigin: "176.315px 294.655px"
        }}
      >
        <path
          style={{
            opacity: 0.1,
            transformOrigin: "176.315px 310.34px"
          }}
          className="ai"
          d="m142.22 307.76 34.1-14.53 34.09 14.53-34.09 19.69-34.1-19.69z"
        />
        <g
          className="ai"
          style={{
            transformOrigin: "176.32px 293.135px"
          }}
        >
          <path
            style={{
              fill: "#187032",
              transformOrigin: "160.95px 302.17px"
            }}
            className="ai"
            d="m176.32 324.41-29.94-17.28v-17.87l-.8-.46v-8.87l30.74 17.75v26.73z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "191.69px 302.17px"
            }}
            className="ai"
            d="m176.32 324.41 30.1-17.4v-17.84l.64-.37v-8.87l-30.74 17.75v26.73z"
          />
          <path
            style={{
              opacity: 0.1,
              transformOrigin: "160.95px 302.17px"
            }}
            className="ai"
            d="m176.32 324.41-30.11-17.4v-17.84l-.63-.37v-8.87l30.74 17.75v26.73z"
          />
          <path
            style={{
              opacity: 0.15,
              transformOrigin: "191.37px 298.495px"
            }}
            className="ai"
            d="m176.32 306.55 11.52-6.65 18.58-10.73v1.28l-30.1 17.37v-1.27z"
          />
          <path
            style={{
              opacity: 0.1,
              transformOrigin: "161.35px 298.54px"
            }}
            className="ai"
            d="m146.38 289.26 29.94 17.29v1.27l-29.94-17.27v-1.29z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "176.32px 279.93px"
            }}
            className="ai"
            d="m145.58 279.93 30.74-17.75 30.74 17.75-30.74 17.75-30.74-17.75z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.3,
              transformOrigin: "176.32px 279.93px"
            }}
            className="ai"
            d="m145.58 279.93 30.74-17.75 30.74 17.75-30.74 17.75-30.74-17.75z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "161.335px 302.395px"
            }}
            className="ai"
            d="M163.83 317.2v-26.73l-4.99-2.88v26.73l4.99 2.88z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.65,
              transformOrigin: "161.335px 302.395px"
            }}
            className="ai"
            d="M163.83 317.2v-26.73l-4.99-2.88v26.73l4.99 2.88z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "191.3px 302.395px"
            }}
            className="ai"
            d="M188.8 317.2v-26.73l5-2.88v26.73l-5 2.88z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.75,
              transformOrigin: "191.3px 302.395px"
            }}
            className="ai"
            d="M188.8 317.2v-26.73l5-2.88v26.73l-5 2.88z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "176.705px 280.155px"
            }}
            className="ai"
            d="m158.84 287.59 30.74-17.75 4.99 2.88-30.74 17.75-4.99-2.88z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.85,
              transformOrigin: "176.705px 280.155px"
            }}
            className="ai"
            d="m158.84 287.59 30.74-17.75 4.99 2.88-30.74 17.75-4.99-2.88z"
          />
          <path
            style={{
              opacity: 0.25,
              transformOrigin: "175.91px 280.615px"
            }}
            className="ai"
            d="m175.91 283.5-4.99-2.88 4.99-2.89 4.99 2.89-4.99 2.88z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "175.93px 280.155px"
            }}
            className="ai"
            d="m188.8 290.47-30.74-17.75 5-2.88 30.74 17.75-5 2.88z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.85,
              transformOrigin: "175.93px 280.155px"
            }}
            className="ai"
            d="m188.8 290.47-30.74-17.75 5-2.88 30.74 17.75-5 2.88z"
          />
          <path
            d="M157.22 272.21a10.27 10.27 0 0 0 6.49 6.8 54.72 54.72 0 0 0 11.84 2.64l-12.06-15.35Z"
            style={{
              fill: "#187032",
              transformOrigin: "166.385px 273.975px"
            }}
            className="ai"
          />
          <path
            d="M157.22 272.21a10.27 10.27 0 0 0 6.49 6.8 54.72 54.72 0 0 0 11.84 2.64l-12.06-15.35Z"
            style={{
              fill: "#fff",
              opacity: 0.5,
              transformOrigin: "166.385px 273.975px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65a15.58 15.58 0 0 0-2-8.29c-2.31-3.62-10-7.06-10-7.06a33 33 0 0 0-6.27 5.91 24.22 24.22 0 0 1 17.29 9.25Z"
            style={{
              fill: "#187032",
              transformOrigin: "166.421px 273.975px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65a15.58 15.58 0 0 0-2-8.29c-2.31-3.62-10-7.06-10-7.06a33 33 0 0 0-6.27 5.91 24.22 24.22 0 0 1 17.29 9.25Z"
            style={{
              fill: "#fff",
              opacity: 0.75,
              transformOrigin: "166.421px 273.975px"
            }}
            className="ai"
          />
          <path
            d="M163.71 273.62a24.33 24.33 0 0 1 5.44 2.77l3.93-3.64a20.4 20.4 0 0 0-5.18-4.13Z"
            style={{
              fill: "#fafafa",
              transformOrigin: "168.395px 272.505px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65a11.27 11.27 0 0 1-5.3 4.65c-3.82 1.71-10.12 1.41-10.12 1.41l-.35-7.95a8 8 0 0 0 6.52 3.76 11.65 11.65 0 0 0 7.8-2.59Z"
            style={{
              fill: "#187032",
              transformOrigin: "167.665px 283.745px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65a11.27 11.27 0 0 1-5.3 4.65c-3.82 1.71-10.12 1.41-10.12 1.41l-.35-7.95a8 8 0 0 0 6.52 3.76 11.65 11.65 0 0 0 7.8-2.59Z"
            style={{
              fill: "#fff",
              opacity: 0.5,
              transformOrigin: "167.665px 283.745px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65c-3.81-4.14-7.48-4.64-10.87-4-3.75.75-4.9 2.07-4.9 2.07-1 2.25-.59 5 .35 7.95a13 13 0 0 1 7.54-5.38c5.42-1.53 7.88-.64 7.88-.64Z"
            style={{
              fill: "#187032",
              transformOrigin: "167.375px 282.545px"
            }}
            className="ai"
          />
          <path
            d="M175.55 281.65c-3.81-4.14-7.48-4.64-10.87-4-3.75.75-4.9 2.07-4.9 2.07-1 2.25-.59 5 .35 7.95a13 13 0 0 1 7.54-5.38c5.42-1.53 7.88-.64 7.88-.64Z"
            style={{
              fill: "#fff",
              opacity: 0.75,
              transformOrigin: "167.375px 282.545px"
            }}
            className="ai"
          />
          <path
            d="M161.67 278.6a527.9 527.9 0 0 1 3.13 4.92 14.66 14.66 0 0 1 2.87-1.19 25 25 0 0 1 2.87-.65l-3.29-4.24a11.55 11.55 0 0 0-2.57.25 14.43 14.43 0 0 0-3.01.91Z"
            style={{
              fill: "#fafafa",
              transformOrigin: "166.105px 280.479px"
            }}
            className="ai"
          />
          <path
            d="M175.36 280.76a17.62 17.62 0 0 0 9.53-4.6 20.38 20.38 0 0 0 5-9.7l-6.84-4.6a13 13 0 0 1-1.59 10.74 10.74 10.74 0 0 1-6.09 4.92Z"
            style={{
              fill: "#187032",
              transformOrigin: "182.625px 271.31px"
            }}
            className="ai"
          />
          <path
            d="M175.36 280.76a17.62 17.62 0 0 0 9.53-4.6 20.38 20.38 0 0 0 5-9.7l-6.84-4.6a13 13 0 0 1-1.59 10.74 10.74 10.74 0 0 1-6.09 4.92Z"
            style={{
              fill: "#fff",
              opacity: 0.5,
              transformOrigin: "182.625px 271.31px"
            }}
            className="ai"
          />
          <path
            d="m175.36 280.76 1-.18a17.86 17.86 0 0 1 5.8-10.29c5.23-4.5 7.67-3.83 7.67-3.83a20.94 20.94 0 0 0-6.84-4.6s-4.4 2.39-6.43 5.91c-2.4 4.23-1.85 10.59-1.2 12.99Z"
            style={{
              fill: "#187032",
              transformOrigin: "182.316px 271.31px"
            }}
            className="ai"
          />
          <path
            d="m175.36 280.76 1-.18a17.86 17.86 0 0 1 5.8-10.29c5.23-4.5 7.67-3.83 7.67-3.83a20.94 20.94 0 0 0-6.84-4.6s-4.4 2.39-6.43 5.91c-2.4 4.23-1.85 10.59-1.2 12.99Z"
            style={{
              fill: "#fff",
              opacity: 0.75,
              transformOrigin: "182.316px 271.31px"
            }}
            className="ai"
          />
          <path
            d="m186.09 267.52-5.78-3.81c-.29.22-.57.46-.87.72l5.64 3.69c.35-.23.69-.43 1.01-.6Z"
            style={{
              fill: "#fafafa",
              transformOrigin: "182.765px 265.915px"
            }}
            className="ai"
          />
          <path
            d="m184.18 268.72-5.59-3.49a13.42 13.42 0 0 0-1.53 1.81c-.15.22-.3.45-.43.68s-.13.29-.2.43l4.67 3.16a12.82 12.82 0 0 1 1.13-1.07c.68-.57 1.34-1.08 1.95-1.52Z"
            style={{
              fill: "#fafafa",
              transformOrigin: "180.305px 268.27px"
            }}
            className="ai"
          />
          <path
            d="M175.52 281.64s1.74 1.85 5.91 2.89 9.45.42 11.72-2.09l-.46-7.38s-3.52 4.73-8.62 5.53a21.54 21.54 0 0 1-8.54-.93Z"
            style={{
              fill: "#187032",
              transformOrigin: "184.335px 280.036px"
            }}
            className="ai"
          />
          <path
            d="M175.52 281.64s1.74 1.85 5.91 2.89 9.45.42 11.72-2.09l-.46-7.38s-3.52 4.73-8.62 5.53a21.54 21.54 0 0 1-8.54-.93Z"
            style={{
              fill: "#fff",
              opacity: 0.5,
              transformOrigin: "184.335px 280.036px"
            }}
            className="ai"
          />
          <path
            d="M175.26 280s4.05-4.23 8.41-4.89c5.91-.89 9 0 9 0a10.94 10.94 0 0 1 .46 7.38s-2.55-2.74-8.53-3a20.24 20.24 0 0 0-9.1 2.16Z"
            style={{
              fill: "#187032",
              transformOrigin: "184.412px 278.602px"
            }}
            className="ai"
          />
          <path
            d="M175.26 280s4.05-4.23 8.41-4.89c5.91-.89 9 0 9 0a10.94 10.94 0 0 1 .46 7.38s-2.55-2.74-8.53-3a20.24 20.24 0 0 0-9.1 2.16Z"
            style={{
              fill: "#fff",
              opacity: 0.75,
              transformOrigin: "184.412px 278.602px"
            }}
            className="ai"
          />
          <path
            d="M191.9 281.5a13.43 13.43 0 0 0-4.14-1.61l-.76-5.17a24.65 24.65 0 0 1 4.4.11 17.09 17.09 0 0 1 .5 6.67Z"
            style={{
              fill: "#fafafa",
              transformOrigin: "189.514px 278.084px"
            }}
            className="ai"
          />
        </g>
      </g>
      <g
        className="ai"
        style={{
          transformOrigin: "229.255px 308.595px"
        }}
      >
        <path
          style={{
            opacity: 0.1,
            transformOrigin: "229.255px 319.215px"
          }}
          className="ai"
          d="m201.05 319.21 28.21-16.28 28.2 16.28-28.2 16.29-28.21-16.29z"
        />
        <g
          className="ai"
          style={{
            transformOrigin: "229.255px 307.51px"
          }}
        >
          <path
            style={{
              fill: "#187032",
              transformOrigin: "241.99px 315.14px"
            }}
            className="ai"
            d="m229.26 333.33 24.94-14.42v-14.3l.52-.31v-7.35l-25.46 14.7v21.68z"
          />
          <path
            style={{
              opacity: 0.1,
              transformOrigin: "241.99px 315.14px"
            }}
            className="ai"
            d="m229.26 333.33 24.94-14.42v-14.3l.52-.31v-7.35l-25.46 14.7v21.68z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.3,
              transformOrigin: "241.99px 315.14px"
            }}
            className="ai"
            d="m229.26 333.33 24.94-14.42v-14.3l.52-.31v-7.35l-25.46 14.7v21.68z"
          />
          <path
            style={{
              opacity: 0.15,
              transformOrigin: "241.73px 312.335px"
            }}
            className="ai"
            d="m229.26 319.01 9.55-5.52 15.39-8.88v1.05l-24.94 14.4v-1.05z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "216.525px 315.14px"
            }}
            className="ai"
            d="m229.26 333.33-24.81-14.31v-14.34l-.66-.38v-7.35l25.47 14.7v21.68z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.45,
              transformOrigin: "216.525px 315.14px"
            }}
            className="ai"
            d="m229.26 333.33-24.81-14.31v-14.34l-.66-.38v-7.35l25.47 14.7v21.68z"
          />
          <path
            style={{
              opacity: 0.1,
              transformOrigin: "216.855px 312.37px"
            }}
            className="ai"
            d="m204.45 304.68 24.81 14.33v1.05l-24.81-14.31v-1.07z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "229.255px 296.95px"
            }}
            className="ai"
            d="m203.79 296.95 25.47-14.7 25.46 14.7-25.46 14.7-25.47-14.7z"
          />
          <path
            style={{
              fill: "#fff",
              opacity: 0.6,
              transformOrigin: "229.255px 296.95px"
            }}
            className="ai"
            d="m203.79 296.95 25.47-14.7 25.46 14.7-25.46 14.7-25.47-14.7z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "216.845px 315.325px"
            }}
            className="ai"
            d="M218.91 327.36v-21.68l-4.13-2.39v21.68l4.13 2.39z"
          />
          <path
            style={{
              opacity: 0.15,
              transformOrigin: "216.845px 315.325px"
            }}
            className="ai"
            d="M218.91 327.36v-21.68l-4.13-2.39v21.68l4.13 2.39z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "241.67px 315.325px"
            }}
            className="ai"
            d="M239.6 327.36v-21.68l4.14-2.39v21.68l-4.14 2.39z"
          />
          <path
            style={{
              opacity: 0.4,
              transformOrigin: "241.67px 315.325px"
            }}
            className="ai"
            d="M239.6 327.36v-21.68l4.14-2.39v21.68l-4.14 2.39z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "229.58px 297.135px"
            }}
            className="ai"
            d="m214.78 303.29 25.46-14.7 4.14 2.39-25.47 14.7-4.13-2.39z"
          />
          <path
            style={{
              opacity: 0.25,
              transformOrigin: "228.92px 297.52px"
            }}
            className="ai"
            d="m228.92 299.91-4.14-2.39 4.14-2.39 4.14 2.39-4.14 2.39z"
          />
          <path
            style={{
              fill: "#187032",
              transformOrigin: "228.935px 297.135px"
            }}
            className="ai"
            d="m239.6 305.68-25.47-14.7 4.14-2.39 25.47 14.7-4.14 2.39z"
          />
          <path
            d="M248.85 289.67s.25 3.54-3.61 6.12c-6.2 4.14-13.52 4-16 3.84v-2.24s5.79-.64 9.52-4.19c3.36-3.19 3.9-10.63 3.9-10.63Z"
            style={{
              fill: "#187032",
              transformOrigin: "239.047px 291.13px"
            }}
            className="ai"
          />
          <path
            d="M248.85 289.67s.25 3.54-3.61 6.12c-6.2 4.14-13.52 4-16 3.84v-2.24s5.79-.64 9.52-4.19c3.36-3.19 3.9-10.63 3.9-10.63Z"
            style={{
              opacity: 0.5,
              transformOrigin: "239.047px 291.13px"
            }}
            className="ai"
          />
          <path
            d="M229.23 297.67a17.46 17.46 0 0 1 5.08-10.27c4.61-4.34 8.37-4.83 8.37-4.83a13.82 13.82 0 0 1 6.17 7.1s-3.9-.89-10.22 1.85c-5.62 2.43-9.4 8.11-9.4 8.11Z"
            style={{
              fill: "#187032",
              transformOrigin: "239.04px 291.1px"
            }}
            className="ai"
          />
          <path
            d="M229.23 297.67a17.46 17.46 0 0 1 5.08-10.27c4.61-4.34 8.37-4.83 8.37-4.83a13.82 13.82 0 0 1 6.17 7.1s-3.9-.89-10.22 1.85c-5.62 2.43-9.4 8.11-9.4 8.11Z"
            style={{
              opacity: 0.15,
              transformOrigin: "239.04px 291.1px"
            }}
            className="ai"
          />
          <path
            d="M243.52 290a24.48 24.48 0 0 0-4.89 1.57 16 16 0 0 0-1.55.78l-3-4.63.26-.27a22.1 22.1 0 0 1 4.6-3.39c1.16 1.46 3.2 4.09 4.58 5.94Z"
            style={{
              fill: "#187032",
              transformOrigin: "238.8px 288.205px"
            }}
            className="ai"
          />
          <path
            d="M212.05 287.78s-.78 3.47 2.65 6.6c5.51 5 12 5.07 14.53 5.25l.3-2.22a13 13 0 0 1-8.06-4.62c-2.84-3.66-2.25-11.1-2.25-11.1Z"
            style={{
              fill: "#187032",
              transformOrigin: "220.752px 290.66px"
            }}
            className="ai"
          />
          <path
            d="M212.05 287.78s-.78 3.47 2.65 6.6c5.51 5 12 5.07 14.53 5.25l.3-2.22a13 13 0 0 1-8.06-4.62c-2.84-3.66-2.25-11.1-2.25-11.1Z"
            style={{
              opacity: 0.5,
              transformOrigin: "220.752px 290.66px"
            }}
            className="ai"
          />
          <path
            d="M229.52 297.69a12.69 12.69 0 0 0-2.76-10c-3.9-5-7.54-6-7.54-6a13.88 13.88 0 0 0-7.17 6.09s4-.29 9.83 3.37a20.28 20.28 0 0 1 7.35 8.48Z"
            style={{
              fill: "#187032",
              transformOrigin: "220.858px 290.66px"
            }}
            className="ai"
          />
          <path
            d="M229.52 297.69a12.69 12.69 0 0 0-2.76-10c-3.9-5-7.54-6-7.54-6a13.88 13.88 0 0 0-7.17 6.09s4-.29 9.83 3.37a20.28 20.28 0 0 1 7.35 8.48Z"
            style={{
              opacity: 0.15,
              transformOrigin: "220.858px 290.66px"
            }}
            className="ai"
          />
          <path
            d="M225.45 286.18c-1.83.93-4.48 2.3-6.55 3.37a18.5 18.5 0 0 0-5.59-1.7 21.1 21.1 0 0 1 7.13-5.63 18.3 18.3 0 0 1 5.01 3.96Z"
            style={{
              fill: "#187032",
              transformOrigin: "219.38px 285.885px"
            }}
            className="ai"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SnowGlobe
