import { useState, useCallback, useEffect } from "react"
import ReactDOM from "react-dom"

const defaultPortal = {
  render: () => null,
  remove: () => null,
  isDefault: true,
  element: null,
  createdAt: null
}

const getElementFromSelector = (selector) => {
  if (typeof selector === "string") {
    return document.querySelector(selector)
  }
  else if (selector instanceof HTMLElement) {
    return selector
  }
  else {
    throw new Error("Invalid selector")
  }
}

const usePortal = (element) => {

  const [portal, setPortal] = useState({ ...defaultPortal })

  const createPortal = useCallback((node) => {
    return {
      render: ({ children }) => ReactDOM.createPortal(children, node),
      remove: () => ReactDOM.unmountComponentAtNode(node),
      isDefault: false,
      element: node,
      createdAt: Date.now()
    }
  }, [])

  useEffect(() => {
    if (element) portal?.remove?.()
    const newPortal = createPortal(getElementFromSelector(element))
    setPortal(newPortal)
    return () => newPortal?.remove?.()
  }, [element])

  return [portal.render, portal]

}

export default usePortal