import { useRef, useCallback, useMemo } from "react"
import { useAssets } from "./useAssetLoader"

class SlideVideoPlayer {
  constructor(ref, config) {
    this.ref = ref

    this.config = {
      name: config.name,
      start: config.start ?? 0,
      debug: config.debug
    }

    this.state = {
      current: this.config.start,
      playing: false
    }

    if (this.config.debug)
      console.log(`New VideoPlayer Initialized: ${config.name}`)
  }

  play() {
    this.ref.current?.play?.()
    this.state.playing = true
  }

  pause() {
    this.ref.current?.pause?.()
    this.state.playing = false
  }

  restart() {
    this.play()
    this.ref.current.currentTime = this.config.start
  }
}

export const useVideoPlayer = (config = {}) => {
  const ref = useRef()
  const player = useMemo(() => new SlideVideoPlayer(ref, config), [])

  const { debug, autoplay } = config
  const name = `VideoPlayer-${config.name}`

  const autoStart = useCallback(() => {
    if (debug) console.log(`${name}: slideMount`)
    player.play()
  }, [])

  return {
    ref,
    player,
    playStateChange: useCallback(({ playing }) => {
      if (debug) console.log(`${name}: playStateChange`, playing)
      if (playing) player.play()
      else player.pause()
    }, []),
    slideRestart: useCallback(() => {
      if (debug) console.log(`${name}: slideRestart`)
      player.restart()
    }),
    slideTransitionIn: autoplay ? autoStart : undefined,
    slideMount: autoplay ? autoStart : undefined
  }
}

export const useAudioPlayer = (source, config = {}) => {
  if (!source) return {}

  const player = useAssets()[source]
  const timeout = useRef()

  const { debug, duration: d, fade } = config
  const name = `AudioPlayer-${config.name}`

  return {
    player,
    touchStateChange: useCallback(({ touched }) => {
      if (debug) console.log(`${name}: touchStateChange`, touched)
      if (fade) {
        let duration = d.touch * 1000
        const vol = player.volume()
        // Handle partial duration fading
        if (vol >= 0.05 && vol <= 0.95) {
          duration = (touched ? vol : 1 - vol) * duration
        }
        player.fade(player.volume(), touched ? 0 : 1, Math.floor(duration))
      }
    }, []),
    muteStateChange: useCallback(({ muted }) => {
      if (debug) console.log(`${name}: muteStateChange`, muted)
      const muteInProgress = !!timeout.current
      if (muteInProgress) timeout.current = clearTimeout(timeout.current)
      if (fade) {
        const duration = Math.floor(d.mute * 1000)
        const vol = player.volume()
        if (muted) {
          player.fade(vol, 0, duration)
          setTimeout(() => {
            player.mute(true)
            timeout.current = undefined
          }, duration + 25)
        } else {
          if (muteInProgress) {
            duration = Math.floor(duration * (1 - vol))
          }
          player.mute(false)
          player.fade(muteInProgress ? vol : 0, 1, duration)
        }
      } else player.mute(muted)
    }, []),
    playStateChange: useCallback(({ playing }) => {
      if (debug) console.log(`${name}: playStateChange`, playing)
      if (playing) player.play()
      else player.pause()
    }, []),
    slideRestart: useCallback(() => {
      if (debug) console.log(`${name}: slideRestart`)
      player.stop()
      player.seek(config.start ?? 0)
      player.play()
    }, []),
    slideTransitionIn: useCallback(() => {
      if (debug) console.log(`${name}: slideTransitionIn`)
      player.mute(!!config.muted)
      player.seek(config.start ?? 0)
      player.volume(0)
      player.play()
      if (fade) player.fade(0, 1, Math.floor(d.crossfade * 1000))
      else player.volume(1)
    }, []),
    slideTransitionOut: useCallback(() => {
      if (debug) console.log(`${name}: slideTransitionOut`)
      if (fade) player.fade(player.volume(), 0, Math.floor(d.crossfade * 1000))
    }, []),
    slideMount: useCallback(() => {
      if (debug) console.log(`${name}: slideMount`)
      if (!player.playing()) {
        player.seek(config.start ?? 0)
        player.mute(!!config.muted)
        if (fade) {
          player.volume(0)
          player.play()
          player.fade(0, 1, Math.floor(d.standard * 1000))
        } else {
          player.play()
        }
      }
    }, []),
    slideUnMount: useCallback(() => {
      if (debug) console.log(`${name}: slideUnMount`)
      player.stop()
    }, [])
  }
}
