import { useBemify, useAssets, useEvents, useAudioPlayer } from "../hooks"
import { join } from "../utils"
import templates from "./templates"

const Slide = ({ id, recipient, state, settings, utils, schema }) => {
  // Use all of my superpowered hooks
  const bem = useBemify("slide")
  const assets = useAssets()

  const player = useAudioPlayer(schema.audio?.audio_src, {
    name: id,
    debug: false,
    start: schema.audio?.start,
    muted: state.muted,
    fade: true,
    duration: {
      touch: settings.threshold * 0.95 || 0,
      mute: settings.threshold * 0.95 || 0,
      crossfade: schema.crossfade || 0,
      standard: settings.duration || 0
    }
  })

  useEvents("slide", state, player)

  // Get the current template
  const Template = templates[schema.template.name]

  return (
    <div
      className={join(
        bem(),
        [utils.isCurrent(id), "current"],
        [utils.isNext(id), "next"]
      )}
    >
      <div className={bem("template")}>
        <Template
          id={id}
          recipient={recipient}
          assets={assets}
          schema={schema}
          state={state}
          settings={settings}
          utils={utils}
        />
      </div>
    </div>
  )
}

export default Slide

/*
  Future Features:
    1. Dynamic durations
    2. Crossfading Audio - Maybe done
    3. Concurrent Animations
*/
