import anime from "animejs"
import { useLayoutEffect, useRef, useState } from "react"
import { useBemify, usePinkyPromise, usePortal } from "../hooks"

const Modal = ({ children, allowClickOut, open, setOpen }) => {
  const bem = useBemify("modal")
  const [Portal] = usePortal("#modal")

  const container = useRef()
  const animation = useRef()
  const [contentVisible, setContentVisible] = useState(open)

  const openPromise = usePinkyPromise("modal-open", "promisor")
  const closePromise = usePinkyPromise("modal-close", "promisor")

  useLayoutEffect(() => {
    if (!container.current) return

    if (open) openModal()
    else closeModal()
  }, [open])

  const openModal = () => {
    if (animation.current) animation.current.pause()

    const tl = anime.timeline({
      easing: "easeInOutQuad",
      duration: 600
    })

    // Fade in overlay
    tl.add({
      targets: container.current.querySelector(`.${bem("overlay")}`),
      opacity: [0, 1],
      duration: 800,
      easing: "easeInQuad"
    })

    // Fade in content
    tl.add(
      {
        targets: container.current.querySelector(`.${bem("window")} > *`),
        opacity: [0, 1],
        translateY: [10, 0],
        complete: () => {
          setContentVisible(true)
          animation.current = null
          openPromise.keep()
        }
      },
      0
    )

    animation.current = tl
  }

  const closeModal = () => {
    if (!contentVisible) return

    if (animation.current) animation.current.pause()

    const tl = anime.timeline({
      easing: "easeInOutQuad",
      duration: 600
    })

    // Fade out content
    tl.add({
      targets: container.current.querySelector(`.${bem("window")} > *`),
      opacity: [1, 0]
    })

    // Fade out overlay
    tl.add(
      {
        targets: container.current.querySelector(`.${bem("overlay")}`),
        opacity: [1, 0],
        translateY: [0, -10],
        complete: () => {
          setContentVisible(false)
          animation.current = null
          closePromise.keep()
        }
      },
      0
    )
  }

  return (
    <Portal>
      {(contentVisible || open) && (
        <div className={bem()}>
          <div className={bem("container")} ref={container}>
            <div
              className={bem("overlay")}
              onClick={!allowClickOut ? undefined : () => setOpen(false)}
            />
            <div className={bem("window")}>{children}</div>
          </div>
        </div>
      )}
    </Portal>
  )
}

export default Modal
