// Hooks
import { useState, useEffect, useMemo } from "react"
import { useBemify, useTransitions, usePinkyPromise } from "../../hooks"

// Components
import Snowman from "../assets/Snowman"
import TextCycle from "../TextCycle"
import ProgressBar from "../ProgressBar"

// Helpers
import anime from "animejs"
import { renderText } from "../../utils"

// Data
import { texts } from "../../data"

export default function Loading({
  data: { recipient },
  loader: { loaded, progress },
  next
}) {
  const bem = useBemify("loading-screen")

  const [throttleAmount, setThrottleAmount] = useState(1)
  const [interceptReady, setInterceptReady] = useState(false)

  const [container, transitions, { fade, fly }] = useTransitions()

  // Use pinky promise to auto resolve
  const textCyclePromise = usePinkyPromise("TextCycle", "promisee")
  const progressBarPromise = usePinkyPromise("ProgressBar", "promisee")

  useEffect(() => {
    if (loaded && interceptReady) {
      // Play Progress Bar Animation
      progressBarPromise.then(() => {
        if (!container.current)
          return console.warn(
            "Something went wrong and the DOM element is missing."
          )
        const targets = {
          progressBar: container.current.querySelector(
            `.${bem("progress")} .progress-bar`
          ),
          words: container.current.querySelectorAll(
            `.${bem("load-message")} .word`
          )
        }

        const tl = anime.timeline({
          easing: "easeInQuad"
        })

        tl.add({
          targets: targets.progressBar,
          scaleX: {
            value: 2,
            duration: 200
          },
          scaleY: {
            value: 10,
            duration: 400,
            delay: 100
          },
          opacity: {
            value: 0.15,
            duration: 400,
            easing: "easeInOutQuad"
          }
        })

        tl.add({
          targets: targets.words,
          duration: 500,
          translateY: [8, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutQuad",
          delay: anime.stagger([0, 300])
        })

        tl.add({
          targets: targets.progressBar,
          opacity: 0,
          duration: 600,
          delay: 1200
        })

        tl.add(
          {
            targets: targets.words,
            opacity: 0,
            duration: 400
          },
          "-=200"
        )
      })

      // Keep the pinky promise
      textCyclePromise.keep().then(() => {
        transitions.exit(() => {
          textCyclePromise.reset()
          next()
        })
      })
    }
  }, [loaded, interceptReady])

  const formattedLoadMessage = useMemo(() => {
    if (!loaded) return null

    return (
      <>
        <p>{renderText("an extra dope experience", recipient)}</p>
      </>
    )
  }, [loaded])

  return (
    <div className={bem()}>
      <div className={bem("container")} ref={container}>
        <div className={bem("loader")}>
          <div className={bem("content")}>
            <div className={bem("illustration", fly)}>
              <Snowman animated={true} />
            </div>
            <div className={bem("text-cycle", fly)}>
              <TextCycle
                texts={texts.loading}
                interceptedTexts={texts.loaded}
                onChange={({ current, intercepted }) => {
                  if (intercepted) return
                  if (current > texts.throttle) return
                  let calculatedThrottle = current / texts.throttle
                  if (calculatedThrottle < 0.2) calculatedThrottle = 0.2
                  calculatedThrottle = 1 - calculatedThrottle
                  if (current !== texts.throttle) {
                    setThrottleAmount(calculatedThrottle)
                  } else {
                    setThrottleAmount(0)
                    setInterceptReady(true)
                  }
                }}
              />
            </div>
          </div>
          <div className={bem("progress", fade)}>
            <ProgressBar progress={progress * (1 - throttleAmount)} />
            <div className={bem("load-message")}>{formattedLoadMessage}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
