import anime from "animejs"
import { useRef, useLayoutEffect, useMemo, useCallback } from "react"
import { Timer } from "../utils"

// Create a store of animations
const baseAnimations = {
  fadeIn: { opacity: [0, 1] },
  fadeOut: { opacity: 0 },
  flyIn: {
    translateY: [anime.stagger([100, 25]), 0],
    opacity: [0, 1],
  },
  flyOut: {
    translateY: anime.stagger([-25, -100]),
    opacity: 0,
  },
  flutterIn: {
    translateY: [30,0],
    translateZ: 0,
    opacity: [0,1],
    easing: "easeOutQuad",
    duration: 500,
    delay: (el, i) => 50 + 30 * i
  },
  flutterOut: {
    translateY: [0,-10],
    opacity: 0,
    easing: "easeInQuad",
    duration: 400,
    delay: (el, i) => 50 + 30 * i
  },
  defaults: {
    easing: "easeInOutQuad",
    duration: 400
  }
}

const classNames = {
  fade: "use-fade",
  fly: "use-fly",
  flutter: "use-flutter",
}

const directions = ["In", "Out"]

// Get formed animation object
function getAnimations({ container, defaults = {} }) {

  // Construct base animations object with defauly
  const animations = {
    defaults: {
      ...baseAnimations.defaults,
      ...defaults
    }
  }

  // Loop through classnames
  for (const [name, className] of Object.entries(classNames)) {
    directions.forEach((direction) => {
      const key = name + direction
      animations[key] = {
        ...baseAnimations[key],
        getTargetAndDelay(parent) {
          const targets = (parent || container.current).querySelectorAll(`.${className}`)
          const delay = targets.length > 1 ? anime.stagger([0, 250]) : 0
          return { targets, delay }
        }
      }
    })

  }

  return animations

}

// Get a transition
function getTimeline(animations = {}, order = [], customParents = []) {

  const tl = anime.timeline({
    ...baseAnimations.defaults,
    ...animations.defaults
  })

  // Add animations to timeline
  order.forEach((item, index) => {

    // Get the baseline animation props
    const { getTargetAndDelay, ...staticAnimation } = (animations[item] || {})

    // Just prevent some general fuckery
    if (!getTargetAndDelay) return

    // Construct final object
    const animation = {
      ...staticAnimation,
      ...getTargetAndDelay(customParents[index])
    }
    
    tl.add(animation)
  })

  return tl

}

const useTransitions = (props = {}, dependencies = []) => {

  // Destructure Hook Properties
  const {
    autoplay = true,
    enterOrder = ["flyIn", "fadeIn"],
    exitOrder = ["flyOut", "fadeOut"],
    delayResolve = 1000,
    enterDefaults = {},
    exitDefaults = {},
    defaults = {}
  } = props

  // Create a merged settings props
  const settings = useMemo(() => ({
    enter: {
      order: enterOrder,
      defaults: {
        ...defaults,
        ...enterDefaults
      }
    },
    exit: {
      order: exitOrder,
      defaults: {
        ...defaults,
        ...exitDefaults
      }
    }
  }), dependencies)

  // Create Animation Parent Ref
  const container = useRef()
  const animation = useRef()

  const getTransition = useCallback((dir) => (
    async (onComplete) => {

      // Get Transition Settings
      const { order, defaults } = settings[dir]
      const animations = getAnimations({ container, defaults })

      // Update animation ref to allow for cleanup
      animation.current = getTimeline(animations, order)

      // Wait for the transition to complete
      await animation.current.finished
      if (delayResolve) await Timer.wait(delayResolve)
      if (onComplete) onComplete()
    }
  ), dependencies)

  const transitions = {
    enter: useCallback(getTransition("enter"), dependencies),
    exit: useCallback(getTransition("exit"), dependencies)
  }

  useLayoutEffect(() => {

    if (autoplay) transitions.enter()

    return () => {
      if (animation.current) animation.current?.pause()
    }

  }, dependencies)

  return [container, transitions, classNames]

}

const useAnimation = (sequence = [], dependencies = [], props = {}) => {

  const container = props.ref || useRef()

  const animation = useCallback((...customParents) => {

    const animations = getAnimations({
      container,
      defaults: props.defaults || {}
    })

    return getTimeline(animations, sequence, customParents)

  }, dependencies)

  const mappedClasses = useMemo(() => {
    const entries = Array.from(Object.entries(classNames))
    return sequence.map((name) => {
      return (entries.find(([key]) => name.startsWith(key)) || [])[1]
    })
  }, dependencies)
  
  return [animation, container, mappedClasses]

}

export { useTransitions, useAnimation }