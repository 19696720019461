import { tests } from "./types"
import filterMap from "./filterMap"

// This is the foundational function that both join and bemify will use
const filterConditionalClasses = (classes = [], transformFunction) => {
  // I'm still lazy ok
  const { isArr, isStr, isFunc } = tests

  // Test if transform is function
  const transform = isFunc(transformFunction)
    ? transformFunction
    : (value) => value

  // FilterMap the classes and return the joined strings
  return filterMap(classes, (item) => {
    if (!item) return null

    if (isStr(item)) return transform(item)

    if (isArr(item)) {
      const [condition, className, fallback] = item

      const result = condition ? className || condition : fallback

      if (result && isStr(result)) return transform(result)
    }
  })
}

// A little function to help join a bunch of classes together
export const join = (...classes) => filterConditionalClasses(classes).join(" ")

// I still like writing BEM ok. This makes it a bit easier in react
export const bemify = (block) => {
  return (element, ...classes) => {
    // Start with the base block class
    let classString = block

    // If no element or classes are present then don't do shit, just return the block
    if (!element) return classString

    // Create the element Block & Element
    const blockElement = `${block}__${element}`

    // If we don't have any more bullshit we caj just go ahead and return the element
    if (!classes || !classes.length) return blockElement

    // Next before we join the classes we do the bem magic to them
    const filtered = filterConditionalClasses(classes, (cn) => {
      return (cn.startsWith("--") ? blockElement : "") + cn
    })

    // Finally return it in all of its glory
    return [blockElement, ...filtered].join(" ")
  }
}
