import { useState, useCallback } from "react"
import { useBemify, useTransitions } from "../../hooks"

import axios from "axios"

import MaskedInput from "../MaskedInput"
import { Timer } from "../../utils"
import Icon from "../Icon"
import ExtraDopeSnowGlobe from "../assets/ExtraDopeSnowGlobe"

const Login = ({ onLogin, userInfo, shortcode }) => {
  // Get Bemify Instance
  const bem = useBemify("login")

  // Use Sexy Animations
  const [container, transition, { fade, fly }] = useTransitions()

  // Declare Login App State
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    message: "",
    shown: false
  })

  // Handle Form Submit
  const handleSubmit = async () => {
    // Handle UI Loading
    if (loading) return
    else setLoading(true)

    // scoll to top of page
    window.scrollTo(0, 0)
    document.body.classList.add("locked")

    // Clean Phone Input
    const cleanedPhone = [...phone]
      .filter((char) => /^\d+$/.test(char))
      .join("")

    const { data } = await Timer.min(
      axios.post(`/api/${shortcode ? "authenticate" : "send"}`, {
        phone: cleanedPhone,
        shortcode
      }),
      1600
    )

    if (data.success) {
      if (shortcode) {
        await transition.exit()
        onLogin(data.data)
      } else {
        console.log(data)
      }
    }

    // Display Error
    else {
      setError({
        message: data.message,
        shown: true
      })
    }

    setLoading(false)
  }

  // Handle Input Changes
  const handleInputChange = useCallback(
    (val) => {
      setPhone(val)
      if (error.shown)
        setError({
          ...error,
          shown: false
        })
    },
    [error.shown]
  )

  return (
    <div className={bem()} ref={container}>
      <div className={bem("container")}>
        {/* Header */}
        <div className={bem("header")}>
          <div className={bem("illustration", fly)}>
            <div className={bem("illustration-container")}>
              <ExtraDopeSnowGlobe />
            </div>
          </div>
          <h1 className={bem("title", fly)}>Happy Holidays!</h1>
          <h2 className={bem("title", "--recipient", fly)}>
            {userInfo?.sentFrom
              ? `From ${userInfo?.sentFrom}`
              : "from Arjun & Erin"}
          </h2>
        </div>

        {/* Form */}
        <div className={bem("form")}>
          <p className={bem("label", fly)}>
            Enter the last 4 digits of your phone number
          </p>
          <div className={bem("input", fly)}>
            <MaskedInput
              inputMode="numeric"
              mask="9999"
              placeholder="1234"
              value={phone}
              onChange={handleInputChange}
              onEnter={handleSubmit}
              isValid={phone && phone.length === 4}
              theme="pin"
              error={error.shown}
              onSubmit={handleSubmit}
              showLoader={loading}
            />
          </div>
          <div className={bem("error", [error.shown, "visible"])}>
            <Icon icon="error" size="xl" />
            <p>{error.message}</p>
          </div>
        </div>
      </div>

      {/* Customized Footer */}
      {userInfo?.displayLabel && (
        <footer className={bem("footer", fade)}>
          <p className={bem("footer-copy")}>
            made for {userInfo?.name?.toLowerCase()}
          </p>
        </footer>
      )}
    </div>
  )
}

export default Login
