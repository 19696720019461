import tutorial from "../data/tutorial";

export default function constructTutorialData(recipient) {

  if (
    !recipient?.tutorial?.audio_src || 
    !recipient?.tutorial?.video_src
  ) return tutorial

  return { ...tutorial, ...recipient.tutorial }

}