const settings = {
  defaultTheme: "light",
  threshold: 0.35,
  duration: 0.5,
  storyMountDuration: 1.2,
  appMountDuration: 1,
  backgrounds: {
    login: {
      image: "/assets/images/pattern_landscape.jpeg",
      alt: "Snowflakes texture",
      theme: "snowflakes"
    },
    app: {
      theme: "cosmic-gradient"
    }
  }
}

export default settings
