import anime from "animejs"
import { useLayoutEffect, useRef } from "react"
import { useBemify, usePinkyPromise } from "../hooks"

const ProgressBar = ({ progress }) => {
  const bem = useBemify("progress-bar")
  const bar = useRef()
  const animation = useRef()

  const pinkyPromise = usePinkyPromise("ProgressBar", "promisor")

  // Animate the
  useLayoutEffect(() => {
    if (animation.current) animation.current.pause()

    animation.current = anime({
      targets: bar.current,
      scaleX: animation.current ? progress : [0, progress],
      duration: 4000,
      easing: "easeOutSine"
    })

    if (progress === 1) {
      animation.current.finished.then(() => {
        pinkyPromise.keep()
      })
    }
  }, [progress])

  return (
    <div className={bem()}>
      <div className={bem("bar")}>
        <div className={bem("inner")} ref={bar} />
      </div>
    </div>
  )
}

export default ProgressBar
