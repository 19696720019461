import React, { useCallback, useState } from "react"
import { useBemify, usePinkyPromise, useStory } from "../../hooks"
import axios from "axios"
import Slide from "../Slide"
import Icon from "../Icon"
import Button from "../Button"
import Modal from "../Modal"

const debugMode = false

export default function Story({ data, next }) {
  const bem = useBemify("story")
  const [downloading, setDownloading] = useState(false)
  const { state, touchTarget, animation, progress, utils, modal } =
    useStory(data)

  const { downloadLink } = data.recipient

  const modalPromise = usePinkyPromise("modal-close", "promisee")

  const handleRestart = () => {
    modal.set(false)
    const fadeSound = !state.muted
    if (fadeSound) state.set({ muted: true })
    modalPromise.then(() => {
      modalPromise.reset()
      animation.onStoryRestart().then(() => {
        state.set({
          current: 0,
          complete: false,
          muted: fadeSound ? false : (cur) => cur
        })
      })
    })
  }

  const handleDownload = useCallback(() => {
    setDownloading(true)
    axios
      .get(downloadLink, { responseType: "blob" })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(data)
        const link = document.createElement("a")
        link.href = url
        link.download = url.replace("/^.*[\\/]/", "")
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
        setDownloading(false)
      })
      .catch((err) => {
        console.error(err)
        alert(
          "Something went wront while downloading your video. Please try again later."
        )
      })
  }, [])

  // Might need this later
  // const handleOpenGifts = () => {
  //   modal.set(false)
  //   const endedOnMute = state.muted
  //   if (!endedOnMute) state.set({ muted: true })
  //   modalPromise.then(() => {
  //     modalPromise.reset()
  //     animation.storyExit().then(() => next({ initialMute: endedOnMute }))
  //   })
  // }

  if (debugMode) console.log(data.slides[state.current]?.duration)

  return (
    <div className={bem()}>
      <div className={bem("container")} ref={animation.ref}>
        <div className={bem("slides")}>
          {data.slides.map(
            (slide, id) =>
              utils.isVisible(id) && (
                <React.Fragment key={id}>
                  <Slide
                    id={id}
                    recipient={data.recipient}
                    state={state}
                    schema={slide}
                    utils={utils}
                    settings={data.settings}
                  />
                </React.Fragment>
              )
          )}
        </div>

        <div className={bem("touch-targets")} {...touchTarget.props}>
          <div
            className={bem("touch-target", "--back")}
            id={touchTarget.id.back}
          />
          <div
            className={bem("touch-target", "--next")}
            id={touchTarget.id.next}
          />
        </div>

        <div className={bem("animation-assets", state.theme)}>
          <div className={bem("pointer")} />
          <div className={bem("ripple")} />
        </div>

        <div className={bem("progress", state.theme)} ref={progress.ref}>
          {data.slides.map((_, id) => (
            <div className={bem("indicator", `--${id}`)} key={id}>
              <span />
            </div>
          ))}
        </div>

        <div
          className={bem("mute", state.theme, [!state.muted, "active"])}
          onClick={() => state.set({ muted: (cur) => !cur })}
        >
          <Icon icon={`volume-${state.muted ? "off" : "on"}`} size="xl" />
        </div>

        {/* Temp DEV Playback switch */}
        {debugMode && (
          <div
            className={bem("playback", state.theme, [state.playing, "active"])}
            onClick={() => state.set({ playing: (cur) => !cur })}
          >
            <Icon icon={`more`} size="xl" />
          </div>
        )}

        <Modal open={modal.state} setOpen={modal.set}>
          <div className={bem("modal", state.theme)}>
            <div className={bem("modal-content")}>
              <h1 className={bem("modal-emoji")}>👋🏽</h1>
              <h3 className={bem("modal-title")}>
                {data?.recipient?.modal?.title || "Until next time!"}
              </h3>
              <p className={bem("modal-text")}>
                {data?.recipient?.modal?.copy ||
                  "We hope you enjoyed this Extra Dope experience, brought to you by Arjun's coding magic and Erin's wording wizardry! This is just a glimpse of the amazing things we'll create in the future. Here's to more inspiring adventures and sensational projects in 2024!"}
              </p>
            </div>
            <div className={bem("modal-actions", [!downloadLink, "single"])}>
              <Button
                text="Play Again"
                theme={`primary ${state.theme}`}
                onClick={handleRestart}
              />
              {downloadLink && (
                <Button
                  text="Download"
                  theme={`secondary ${state.theme}`}
                  onClick={handleDownload}
                  showLoader={downloading}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
