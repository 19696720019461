import anime from "animejs"
import { useLayoutEffect, useEffect, useRef, useState } from "react"
import { useBemify } from "../hooks"

export default function AppWrapper({ children, transition, background = {} }) {
  const bem = useBemify("app")
  const wrapper = useRef()
  const animation = useRef()
  const [childViewVisible, setChildViewVisible] = useState(false)
  const [backgroundLayers, setBackgroundLayers] = useState([background])

  // Handle Initial Mount
  useLayoutEffect(() => {
    const duration = transition ? transition * 1000 : 500
    const tl = anime.timeline({
      easing: "easeInQuad",
      duration
    })

    const targets = {
      background: wrapper.current.querySelector(`.${bem("background")}`),
      container: wrapper.current.querySelector(`.${bem("container")}`)
    }

    tl.add({
      targets: targets.background,
      opacity: [0, 1],
      complete: () => setChildViewVisible(true)
    })

    tl.add({
      targets: targets.container,
      opacity: {
        value: [0, 1],
        duration: duration / 2
      },
      translateY: {
        value: [60, 0],
        duration: duration / 2,
        easing: "easeOutQuad"
      },
      scale: {
        value: [0.9, 1],
        duration: duration / 4,
        easing: "easeOutQuad"
      },
      complete: () => {
        // Remove inline styles
        targets.container?.style?.removeProperty("opacity")
        targets.container?.style?.removeProperty("transform")
      }
    })

    animation.current = tl
  }, [])

  // Push Background Changes into stack
  useEffect(() => {
    setBackgroundLayers((prev) => [...prev, background])
  }, [background])

  // Handle Layer Animations
  useLayoutEffect(() => {
    // Only animate if there are more than 1 layer
    if (backgroundLayers?.length > 1) {
      anime({
        targets: wrapper.current.querySelector(
          `.${bem("background-layer")}:last-child`
        ),
        opacity: [0, 1],
        easing: "easeOutQuad",
        duration: transition ? transition * 1000 : 500,
        complete: () => setBackgroundLayers([background])
      })
    }
  }, [backgroundLayers])

  return (
    <main className={bem()} ref={wrapper}>
      <div className={bem("background")}>
        {backgroundLayers.map((layer, key) => (
          <div
            className={bem("background-layer", [
              layer?.theme,
              `--${layer?.theme}`
            ])}
            key={key}
          >
            {layer.image && <img src={layer.image} alt={layer.alt} />}
          </div>
        ))}
      </div>
      <div className={bem("container")}>{childViewVisible && children}</div>
    </main>
  )
}
