import Video from "./Video"
import TitleSequence from "./TitleSequence"
import Memories from "./Memories"
import Polaroids from "./Polaroids"

const templates = {
  video: Video,
  "title-sequence": TitleSequence,
  memories: Memories,
  polaroids: Polaroids
}

export default templates
