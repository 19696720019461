import Welcome from "./Welcome"
import Loading from "./Loading"
import Story from "./Story"
import Gifts from "./Gifts"

const views = {
  loading: {
    Component: Loading,
    next: "welcome"
  },
  welcome: {
    Component: Welcome,
    next: "story"
  },
  story: {
    Component: Story,
    next: "gifts"
  },
  gifts: {
    Component: Gifts,
    next: "story"
  }
}

export const initialView = "loading"

export default views