export const loading = [
  "Making spirits bright…",
  "Dashing through the snow…",
  "Walking in a winter wonderland…",
  "Making a list and checking it twice…"
]

export const loaded = ["Your card has arrived!"]

export const loadMessage = "{{count}} {{days}} till Christmas"

export const throttle = 0
