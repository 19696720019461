// Hooks
import { useState, useRef } from "react"
import {
  useAssets,
  useBemify,
  useLocalStorage,
  useTransitions
} from "../../hooks"

// Components
import Button from "../Button"
import Tutorial from "../Tutorial"
import Branding from "../Branding"
import anime from "animejs"

// Settings
const ringCount = 4

function SoundCheck({ data, next }) {
  const bem = useBemify("welcome-screen")

  const [container, transitions, { fly, fade }] = useTransitions({
    delayResolve: 0
  })

  const rings = useRef()
  const ringAinimation = useRef()
  const timeout = useRef()

  const assets = useAssets()

  const handleSoundClick = () => {
    const player = assets[data.tutorial.audio_src]

    if (timeout.current) clearTimeout(timeout.current)
    if (ringAinimation.current) ringAinimation.current.pause()

    rings.current.classList.add("animating")
    const baseTime = 250

    timeout.current = setTimeout(() => {
      player?.seek(0)
      player?.play()
    }, baseTime * 0.9)

    const tl = anime.timeline({
      easing: "easeOutElastic(1, 0.5)"
    })

    tl.add({
      targets: rings.current.querySelectorAll("p"),
      opacity: [1, 0],
      duration: baseTime * 0.7,
      easing: "easeInOutElastic"
    })

    tl.add(
      {
        targets: rings.current,
        scale: [1, 0.6],
        duration: baseTime,
        easing: "easeInOutElastic",
        complete: () => {
          rings.current.classList.remove("animating")
        }
      },
      0
    )

    tl.add(
      {
        targets: rings.current,
        scale: 1,
        duration: baseTime
      },
      baseTime
    )

    tl.add(
      {
        targets: rings.current.querySelectorAll("p"),
        opacity: 1,
        duration: baseTime * 0.7
      },
      baseTime * 1.5
    )

    tl.add(
      {
        targets: rings.current.querySelectorAll(`.${bem("ring")}`),
        scale: {
          value: [1, 5],
          duration: 600
        },
        opacity: {
          value: [1, 0],
          duration: 300,
          delay: 250
        },
        duration: 600,
        easing: "easeOutQuad",
        delay: anime.stagger(125),
        complete: () => {
          rings.current
            .querySelectorAll(`.${bem("ring")}`)
            .forEach((ring) => (ring.style.transform = "scale(1)"))
          ringAinimation.current = null
        }
      },
      baseTime
    )

    ringAinimation.current = tl
  }

  return (
    <div className={bem()} ref={container}>
      <div className={bem("container")}>
        <div className={bem("instructions")}>
          <h1 className={bem("title", "--emoji", fly)}>🎶</h1>
          <h1 className={bem("title", "--sound-check", fly)}>
            Let's do a quick sound check...
          </h1>
          <p className={bem("subtitle", fly)}>
            Tap the button below to make sure your sound is working
          </p>
        </div>

        <div className={bem("sound", fade)} onClick={handleSoundClick}>
          <div className={bem("rings")} ref={rings}>
            <p className={bem("sound-label")}>tap here</p>
            {new Array(ringCount).fill(0).map((_, i) => (
              <div className={bem("ring")} key={i} />
            ))}
          </div>
        </div>

        <div className={bem("submit", fade)}>
          {/* <Button
            text="Continue without sound"
            theme="text"
            onClick={() => {
              transitions.exit().then(() => next(true))
            }}
          /> */}
          <Button
            icon="arrow-forward"
            iconSize="xl"
            theme="primary circle lg"
            onClick={() => {
              transitions.exit().then(() => next())
            }}
          />
        </div>
      </div>
    </div>
  )
}

function Onboarding({ data, setStep, setTutorialComplete }) {
  const bem = useBemify("welcome-screen")

  const [container, transitions, { fade }] = useTransitions({
    delayResolve: 0
  })

  const assets = useAssets()

  return (
    <div className={bem()} ref={container}>
      <div className={bem("branding")}>
        <Branding customText="How it works" animationClass={fade} />
      </div>
      <div className={bem("container")}>
        <div className={bem("tutorial", fade)}>
          <Tutorial assets={assets} data={data.tutorial} />
        </div>

        <div className={bem("submit", "--center")}>
          <Button
            icon="arrow-forward"
            iconSize="xl"
            theme={`primary circle ${fade} lg`}
            onClick={() => {
              setTutorialComplete()
              transitions.exit().then(() => setStep("sound-check"))
            }}
          />
        </div>
      </div>
    </div>
  )
}

const steps = {
  "sound-check": SoundCheck,
  tutorial: Onboarding
}

export default function Welcome({ data, next }) {
  const [{ tutorialComplete }, setLocal] = useLocalStorage()
  const [step, setStep] = useState(() =>
    !tutorialComplete ? "tutorial" : "sound-check"
  )
  const Step = steps[step]

  return (
    <Step
      data={data}
      next={(mute = false) => next({ initialMute: mute })}
      setStep={setStep}
      setTutorialComplete={() => setLocal({ tutorialComplete: true })}
    />
  )
}
