import SnowFlakeLoader from "./assets/SnowFlakeLoader"
import anime from "animejs"
import { useEffect, useState, useRef } from "react"
import { useBemify } from "../hooks"
import { Timer } from "../utils"
import axios from "axios"

export default function Preloader({
  initialLoadState,
  shortcode,
  onComplete,
  setName,
  dependencies
}) {
  const bem = useBemify("preloader")
  const [loaded, setLoaded] = useState(initialLoadState)
  const container = useRef()

  useEffect(() => {
    // Create Generic Load Promise
    const documentLoad = new Promise((resolve) => {
      if (!loaded) {
        window.addEventListener("load", () => resolve())
      } else resolve()
    })

    // Create Image Load Promise
    const imageLoad = new Promise((resolve) => {
      if (dependencies?.images?.length) {
        const images = dependencies.images.map((src) => {
          const img = new Image()
          img.src = src
          return img
        })

        Promise.allSettled(images.map((img) => img.decode())).then(() =>
          resolve()
        )
      } else resolve()
    })

    // If there is a shortcode then we need to
    const shortcodeValidated = new Promise((resolve) => {
      if (shortcode) {
        axios
          .post("/api/validate-shortcode", {
            shortcode
          })
          .then(({ data }) => {
            setName(data?.data)
            resolve(!!data?.success)
          })
      } else resolve(true)
    })

    // Wait for promises to resolve
    Timer.min(
      Promise.allSettled([
        documentLoad,
        document.fonts.ready,
        imageLoad,
        shortcodeValidated
      ]),
      2000
    ).then(() => setLoaded(true))
  }, [])

  useEffect(() => {
    if (loaded) {
      anime({
        targets: container.current,
        opacity: 0,
        duration: 800,
        easing: "easeInQuad",
        complete: onComplete
      })
    }
  }, [loaded])

  return (
    <div className={bem()} ref={container}>
      <SnowFlakeLoader />
    </div>
  )
}

// Courtesty of:
// https://codepen.io/hotveyn/pen/VwByGEE
