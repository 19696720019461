import { join } from "../utils"

export default function Icon({ icon, size = "md", color }) {

  // Determine if using custom size
  const parsedSize = typeof size === "number" ? `${size}px` : size
  const customSize = (
    !!["%", "vh", "vw", "px", "em"].filter((unit) => (
      parsedSize.endsWith(unit)
    )).length
  )

  // Determine if using custom color
  const customColor = (
    color &&
    !!["#", "rgb", "hsl", "var"].filter((prefix) => (
      color.startsWith(prefix)
    )).length
  )

  // construct style object
  const style = (customColor || customSize) ? {
    color: customColor ? color : undefined,
    height: customSize ? parsedSize : undefined,
    width: customSize ? parsedSize : undefined
  } : undefined

  return (
    <svg
      style={style}
      className={join(
        "icon",
        [!customSize, `icon-${size}`],
        [!customColor, color]
      )} 
    >
      <use href={`/assets/icons.svg#${icon}`} />
    </svg>
  )

}