import { useCallback, useRef } from "react"
import Gradient from "../Gradient"
import {
  useBemify,
  useEvents,
  useRefArray,
  useThemeFontColors
} from "../../hooks"
import { renderText } from "../../utils"
import anime from "animejs"

const Polaroids = ({ recipient, schema, assets, state, settings }) => {
  const bem = useBemify("template-polaroids")

  const { data } = schema.template
  const { polaroids, theme } = data

  const [, setRef, getRef] = useRefArray(polaroids)
  const animation = useRef()
  const visibleVideo = useRef()
  const container = useThemeFontColors(theme)

  const constructAnimation = useCallback((transitionBuffer = 0) => {
    const totalDuration =
      (schema.duration - schema.crossfade - transitionBuffer) * 1000

    const tl = anime.timeline({
      easing: "easeOutQuad",
      autoplay: false
    })

    for (let i = 0; i < polaroids.length; i++) {
      const polaroid = getRef(i)
      const captionWords = polaroid.current.querySelectorAll(
        `.${bem("caption")} .word`
      )

      const stagger = 300
      const duration = 500
      const entryAngle = 15 + Math.round(Math.random() * 25)
      const exitAngle = -(15 + Math.round(Math.random() * 25))
      const angle = (1.5 + Math.round(Math.random() * 2.5)) * (i % 2 ? 1 : -1)
      const delay = totalDuration / polaroids.length - (stagger + duration) * 3

      // Animate Frame into place
      tl.add(
        {
          targets: polaroid.current,
          translateX: [400, 0],
          translateY: [50, 0],
          rotate: [entryAngle, angle],
          opacity: [0, 1],
          duration: duration * 1.25,
          begin:
            polaroids[i]?.type !== "video"
              ? undefined
              : () => {
                  const video = polaroid.current.querySelector("video")
                  visibleVideo.current = video
                  video
                    .play()
                    .catch(() =>
                      console.log("safari is being a dumb fucking bitch")
                    )
                  video.currentTime = polaroids[i]?.video?.start ?? 0
                }
        },
        i ? undefined : `+=${transitionBuffer * 1000}`
      )

      // Flutter words into place
      tl.add({
        targets: captionWords,
        // translateX: [20, 0],
        opacity: [0, 1],
        easing: "easeOutQuad",
        duration,
        delay: anime.stagger([0, stagger])
      })

      // flutter words away
      tl.add(
        {
          targets: captionWords,
          // translateX: [0, -10],
          opacity: 0,
          easing: "easeInQuad",
          duration,
          delay: anime.stagger([0, stagger], { direction: "reverse" })
        },
        `+=${delay}`
      )

      // Animate Frame out of place
      tl.add(
        {
          targets: polaroid.current,
          translateX: -400,
          translateY: 50,
          rotate: exitAngle,
          opacity: 0,
          duration: duration * 1.25,
          complete:
            polaroids[i]?.type !== "video"
              ? undefined
              : () => {
                  visibleVideo.current?.pause()
                  visibleVideo.current = null
                }
        },
        `-=${stagger}`
      )
    }

    animation.current = tl
  })

  const getStoryCompleteAnimation = useCallback(() => {
    const tl = anime.timeline({
      easing: "easeOutQuad",
      autoplay: true
    })

    for (let i = 0; i < polaroids.length; i++) {
      const polaroid = getRef(i)
      const captionWords = polaroid.current.querySelectorAll(
        `.${bem("caption")} .word`
      )

      const stagger = 300
      const duration = 500
      const exitAngle = -(15 + Math.round(Math.random() * 25))

      // flutter words away
      tl.add(
        {
          targets: captionWords,
          translateX: [0, -10],
          opacity: 0,
          easing: "easeInQuad",
          duration,
          delay: anime.stagger([0, stagger], { direction: "reverse" })
        },
        0
      )

      // Animate Frame out of place
      tl.add(
        {
          targets: polaroid.current,
          translateX: -400,
          translateY: 50,
          rotate: exitAngle,
          opacity: 0,
          duration: duration * 1.25,
          complete:
            polaroids[i]?.type !== "video"
              ? undefined
              : () => {
                  visibleVideo.current?.pause()
                  visibleVideo.current = null
                }
        },
        duration / 2
      )
    }
  }, [])

  useEvents("slide", state, {
    slideTransitionIn: useCallback(() => {
      if (!animation.current) constructAnimation(state.transitionBuffer)
    }, []),
    slideTransitionOut: useCallback(() => {
      anime({
        targets: container.current,
        opacity: 0,
        duration: schema.crossfade * 1000,
        easing: "easeOutQuad"
      })
    }, []),
    slideMount: useCallback(() => {
      if (!animation.current) constructAnimation(settings.duration)
      animation.current.play()
    }, []),
    playStateChange: useCallback(({ playing }) => {
      const method = playing ? "play" : "pause"
      visibleVideo.current?.[method]?.()
      if (!animation.current?.completed) {
        animation.current?.[method]?.()
      }
    }, []),
    slideRestart: useCallback(() => {
      animation.current?.pause()
      animation.current = null
      constructAnimation(settings.duration)
    }, []),
    slideUnMount: useCallback(() => {
      animation.current?.pause()
      animation.current = null
    }, []),
    storyComplete: useCallback(() => {
      animation.current?.pause()
      animation.current = null
      getStoryCompleteAnimation()
    })
  })

  return (
    <div className={bem()} ref={container}>
      <div className={bem("background")}>
        {data.background?.includes?.("gradient") && (
          <Gradient
            type={data.background}
            theme={theme}
            playing={state.playing}
          />
        )}
      </div>
      <div className={bem("content")}>
        {polaroids.map((polaroid, index) => (
          <div
            className={bem("polaroid", `--${index}`)}
            key={index}
            ref={setRef(index)}
          >
            <div className={bem("frame")}>
              {polaroid.type === "photo" && (
                <img src={polaroid.img_src} crossOrigin="anynomous" />
              )}
              {polaroid.type === "video" && (
                <video
                  src={assets[polaroid.video_src]}
                  muted={true}
                  autoPlay={false}
                  controls={false}
                  playsInline={true}
                  loop={true}
                  crossOrigin="anynomous"
                />
              )}
            </div>
            <div className={bem("caption")}>
              <p>{renderText(polaroid.caption, recipient)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Polaroids
