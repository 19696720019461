import { useMemo, useEffect, useCallback, useState } from "react"
import axios from "axios"
import { Timer } from "../utils"
import { Howl, Howler } from "howler"
import { unmute } from "../utils"

const assetCache = {}

const videoKey = "video_src"
const audioKey = "audio_src"
const imageKey = "img_src"

const getSources = (data = {}) => {
  const photos = []
  const videos = []
  const audio = []

  const searchArray = (arr) => {
    arr.forEach((item) => {
      if (Array.isArray(item)) searchArray(item)
      else if (typeof item === "object") searchObject(item)
    })
  }

  const searchObject = (obj) => {
    ;[...Object.entries(obj)].forEach(([key, value]) => {
      if (key === videoKey) videos.push(value)
      else if (key === audioKey) audio.push(value)
      else if (key === imageKey) photos.push(value)
      else if (Array.isArray(value)) searchArray(value)
      else if (typeof value === "object") searchObject(value)
    })
  }

  // Initialize the search
  searchObject(data)

  return {
    photos: [...new Set(photos)],
    audio: [...new Set(audio)],
    videos: [...new Set(videos)]
  }
}

export const useAssetLoader = (data) => {
  const [loaded, setLoaded] = useState(false)
  const [filesLoaded, setFilesLoaded] = useState(0)

  // Parse the object for media
  const { photos, videos, audio } = useMemo(() => getSources(data), [])

  // Calculate teh total assets available
  const totalAssets = useMemo(() => {
    return photos.length + videos.length * 3 + audio.length * 2
  }, [photos.length, videos.length, audio.length])

  // Calculate the percentage progress of loaded files
  const getProgress = useCallback(
    (val) => {
      let percent = Math.round((val * 100) / totalAssets) / 100
      if (percent > 1) percent = 1
      if (percent < 0) percent = 0
      return percent
    },
    [totalAssets]
  )

  // Load assets on component mount
  useEffect(() => {
    // Create a queue to hold promises
    const queue = []

    // Cache Images
    photos.forEach((photo) => {
      queue.push(
        new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve()
          img.onerror = () => reject()
          img.crossOrigin = "anonymous"
          img.src = photo
        }).then(() => setFilesLoaded((x) => x + 1))
      )
    })

    // Cache other videos
    videos.forEach((file) => {
      queue.push(
        new Promise((resolve, reject) => {
          axios
            .get(file, { responseType: "blob" })
            .catch((error) => {
              console.warn(error)
              reject(error)
            })
            .then((res) => {
              if (!res)
                throw new Error(
                  "File not valid or doesn't exist, check URL: " + file
                )
              const fileBlob = res.data
              assetCache[file] = URL.createObjectURL(fileBlob)
              resolve()
            })
            .catch((error) => reject(error))
        }).then(() => setFilesLoaded((x) => x + 3))
      )
    })

    audio.forEach((audioFile) => {
      queue.push(
        new Promise((resolve, reject) => {
          assetCache[audioFile] = new Howl({
            src: [audioFile],
            html5: false,
            onload: () => resolve(),
            onloaderror: (id, error) => reject(error)
          })
        }).then(() => setFilesLoaded((x) => x + 2))
      )
    })

    const timer = Timer.start(2000)

    Promise.allSettled(queue)
      .then(() => {
        unmute(Howler.ctx, false, false)
        return timer.hold()
      })
      .then(() => setLoaded(true))
  }, [])

  return {
    loaded,
    assets: assetCache,
    progress: getProgress(filesLoaded)
  }
}

export const useAssets = () => assetCache
