import anime from "animejs"
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useCallback
} from "react"
import SnowGlobe from "./assets/SnowGlobe"
import { useBemify } from "../hooks"

const config = {
  duration: 400
}

export default function Tutorial({ assets, data }) {
  const bem = useBemify("tutorial")
  const [current, setCurrent] = useState(0)
  const timeline = useRef()
  const slideTimeline = useRef()
  const container = useRef()
  const video = useRef()

  const changeSlide = useCallback((target) => {
    const { duration } = config

    slideTimeline.current = anime.timeline({
      duration,
      easing: "easeInOutSine"
    })

    slideTimeline.current.add({
      targets: container.current.querySelector(
        `.${bem(
          "screen-slide--" + ["start", "end"].filter((s) => s !== target)[0]
        )}`
      ),
      opacity: 0,
      duration: duration,
      complete() {
        if (target === "start") {
          video.current.pause()
        }
      }
    })

    slideTimeline.current.add({
      targets: container.current.querySelector(
        `.${bem("screen-slide--" + target)}`
      ),
      opacity: 1,
      duration: duration,
      begin() {
        if (target === "end") {
          video.current.play()
          video.current.currentTime = 0
        }
      }
    })
  }, [])

  const overlays = useMemo(() => {
    return [
      ...Object.entries({
        next: {
          tip: "Tap the right side to skip",
          hightlight: true,
          touchType: "tap",
          slide: "start",
          onTap: () => changeSlide("end")
        },
        pause: {
          tip: "Tap and hold to pause",
          hightlight: false,
          touchType: "hold",
          slide: "end",
          onHoldStart: () => {
            video.current.pause()
          },
          onHoldEnd: () => video.current.play()
        },
        back: {
          tip: "Tap the left side to go back",
          hightlight: true,
          touchType: "tap",
          slide: "start",
          onTap: () => changeSlide("start")
        }
      })
    ].map(([key, value]) => ({
      name: key,
      ...value
    }))
  }, [])

  useLayoutEffect(() => {
    // Set Opacity to nothing on initial render
    if (!timeline.current) {
      const targets = container.current.querySelectorAll(
        `.${bem("overlay")}, .${bem("screen-slide")}`
      )
      anime.set(targets, {
        opacity: 0
      })
      changeSlide("start")
    }

    const overlay = {
      ...overlays[current],
      element: container.current.querySelector(
        `.${bem(`overlay--${overlays[current].name}`)}`
      )
    }

    overlay.elements = {
      touchIcon: overlay.element.querySelector(`.${bem("overlay-touch-icon")}`),
      hightlight: overlay.element.querySelector(
        `.${bem("overlay-touch-highlight")}`
      ),
      words: overlay.element.querySelectorAll(
        `.${bem("overlay-text")} .word.use-flutter`
      ),
      text: overlay.element.querySelectorAll(`.${bem("overlay-text")}`),
      shade: overlay.element.querySelector(`.${bem("overlay-darken")}`)
    }

    const { duration } = config

    timeline.current = anime.timeline({
      duration,
      easing: "easeInOutSine"
    })

    // Fade In overlay
    timeline.current.add({
      targets: overlay.element,
      opacity: 1,
      delay: duration * 2
    })

    // Fade in text container
    timeline.current.add({
      targets: overlay.elements.text,
      opacity: [0, 1]
    })

    // Flutter in words
    timeline.current.add({
      targets: overlay.elements.words,
      translateY: [20, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutQuad",
      duration: duration + 100,
      delay: (el, i) => 50 + 30 * i
    })

    // Darken
    timeline.current.add(
      {
        targets: overlay.elements.shade,
        opacity: [0, 1],
        duration: duration * 4,
        easing: "easeOutQuad"
      },
      duration / 2
    )

    if (overlay.touchType === "tap") {
      timeline.current.add(
        {
          targets: overlay.elements.touchIcon,
          opacity: [0, 1],
          scale: [1, 1.8],
          easing: "easeInQuad",
          complete: () => overlay.onTap()
        },
        `-=${duration / 2}`
      )

      timeline.current.add({
        targets: overlay.elements.touchIcon,
        opacity: 0.25,
        scale: 1,
        easing: "easeOutQuad"
      })

      if (overlay.hightlight) {
        timeline.current.add(
          {
            targets: overlay.elements.hightlight,
            opacity: [0, 1],
            scale: [0, 1],
            easing: "easeInQuad"
          },
          `-=${duration * 1.5}`
        )

        timeline.current.add({
          targets: overlay.elements.hightlight,
          opacity: 0,
          easing: "easeOutQuad"
        })
      }
    } else {
      timeline.current.add({
        targets: overlay.elements.touchIcon,
        opacity: [0, 1],
        scale: [1, 1.8],
        easing: "easeInQuad",
        complete: () => overlay.onHoldStart()
      })

      timeline.current.add({
        targets: overlay.elements.touchIcon,
        opacity: 0.25,
        scale: 1,
        easing: "easeOutQuad",
        delay: duration * 4,
        complete: () => overlay.onHoldEnd()
      })
    }

    // Fade out touch icon
    timeline.current.add({
      targets: overlay.elements.touchIcon,
      opacity: 0
    })

    // Darken
    timeline.current.add({
      targets: overlay.elements.shade,
      opacity: 0,
      duration: duration * 2,
      easing: "easeInQuad"
    })

    // Flutter out text
    timeline.current.add(
      {
        targets: overlay.elements.words,
        translateY: [0, -10],
        opacity: 0,
        easing: "easeInQuad",
        delay: (el, i) => 50 + 30 * i
      },
      `-=${duration}`
    )

    // Fade out text container
    timeline.current.add({
      targets: overlay.elements.text,
      opacity: 0
    })

    // Fade out container
    timeline.current.add(
      {
        targets: overlay.element,
        opacity: 0
      },
      `+=${duration}`
    )

    const nextSlide = current === overlays.length - 1 ? 0 : current + 1

    timeline.current.finished.then(() => setCurrent(nextSlide))

    return () => {
      slideTimeline.current?.pause()
      timeline.current?.pause()
    }
  }, [current])

  return (
    <div className={bem()}>
      <div className={bem("container")} ref={container}>
        <div className={bem("animation")}>
          <div className={bem("screen")}>
            <div className={bem("screen-slide", "--start")}>
              <SnowGlobe />
              <h2>
                Happy Holidays{" "}
                <span>
                  <span>2</span>
                  <span>0</span>
                  <span>2</span>
                  <span>3</span>
                </span>
              </h2>
            </div>
            <div className={bem("screen-slide", "--end")}>
              <video
                src={assets[data.video_src]}
                ref={video}
                muted={true}
                playsInline={true}
              />
            </div>
          </div>
          <div className={bem("overlays")}>
            {overlays.map(({ name, tip, hightlight }) => (
              <div className={bem("overlay", `--${name}`)} key={name}>
                <div className={bem("overlay-darken")} />
                <div className={bem("overlay-touch", `--${name}`)}>
                  {hightlight && (
                    <div className={bem("overlay-touch-highlight")} />
                  )}
                  <div className={bem("overlay-touch-icon")} />
                </div>
                <div className={bem("overlay-text")}>
                  <p>
                    {tip.split(" ").map((word, key) => (
                      <React.Fragment key={key}>
                        <span className="word use-flutter">{word}</span>
                        <span>&nbsp;</span>
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
