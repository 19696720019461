import { useRef, useEffect, useCallback, useMemo } from "react"

const useRefArray = (source, setInitialValue, dependenceies = []) => {

  const initial = useMemo(() => source.map(setInitialValue || (() => {
    return { current: undefined }
  })), dependenceies)

  const parent = useRef(initial)

  useEffect(() => {
    parent.current = parent.current.slice(0, source.length)
  }, dependenceies)

  return [
    parent,
    // Set Ref at index
    useCallback((index) => {
      return (element) => {
        parent.current[index].current = element
      }
    }, dependenceies),
    // Get Ref at index
    useCallback((index) => parent.current[index], dependenceies)
  ]

}

export default useRefArray