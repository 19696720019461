import { join } from "../utils"
import { useBemify } from "../hooks"
import Icon from "./Icon"

const Button = ({
  type,
  disabled,
  showLoader,
  onClick,
  children,
  text,
  theme,
  icon,
  iconSize
}) => {
  const bem = useBemify("button")

  return (
    <button
      type={type || "button"}
      className={join(
        bem(),
        [disabled, "disabled"],
        [showLoader, "loading"],
        [theme || "primary square"]
      )}
      onClick={onClick}
    >
      {children && children}
      {icon && <Icon icon={icon} size={iconSize} />}
      {text && <p className={bem("text")}>{text}</p>}
      <div className={bem("loader", [!showLoader, "hidden"])}>
        <span />
        <span />
        <span />
      </div>
    </button>
  )
}

export default Button
