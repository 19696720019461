import { useMemo } from "react"
import { constructSlides, constructTutorialData } from "../utils"

export default function useRecipient(recipient) {
  const tutorial = useMemo(() => constructTutorialData(recipient), [])
  const slides = useMemo(() => constructSlides(recipient), [])

  return {
    tutorial,
    slides,
    isGeneric: recipient?.name === "user"
  }
}
