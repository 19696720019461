import { useCallback, useRef } from "react"
import Gradient from "../Gradient"
import {
  useBemify,
  useEvents,
  useRefArray,
  useThemeFontColors
} from "../../hooks"
import { renderText } from "../../utils"
import anime from "animejs"

const Memories = ({ recipient, schema, assets, state, settings }) => {
  const bem = useBemify("template-memories")

  const { data } = schema.template
  const { memories, theme } = data

  const [, setRef, getRef] = useRefArray(memories)
  const animation = useRef()
  const visibleVideo = useRef()
  const container = useThemeFontColors(theme)

  const constructAnimation = useCallback((transitionBuffer = 0) => {
    const totalDuration = (schema.duration - transitionBuffer) * 1000

    const tl = anime.timeline({
      easing: "easeOutQuad",
      autoplay: false
    })

    for (let i = 0; i < memories.length; i++) {
      const currentMemory = getRef(i)

      const frame = currentMemory.current.querySelector(`.${bem("frame")}`)
      const captionWords = currentMemory.current.querySelectorAll(
        `.${bem("caption")} .word`
      )

      const stagger = 300
      const duration = 500
      const angle = 20
      const delay = totalDuration / memories.length - (stagger + duration) * 3

      // Animate Frame into place
      tl.add(
        {
          targets: frame,
          translateX: [400, 0],
          translateY: [50, 0],
          rotate: [angle, 0],
          opacity: [0, 1],
          duration: duration * 1.25,
          begin:
            memories[i]?.type !== "video"
              ? undefined
              : () => {
                  const video = frame.querySelector("video")
                  visibleVideo.current = video
                  video
                    .play()
                    .catch(() =>
                      console.log("safari is being a dumb fucking bitch")
                    )
                  video.currentTime = memories[i]?.video?.start ?? 0
                }
        },
        i ? undefined : `+=${transitionBuffer * 1000}`
      )

      // Flutter words into place
      tl.add({
        targets: captionWords,
        translateY: [20, 0],
        opacity: [0, 1],
        easing: "easeOutQuad",
        duration,
        delay: anime.stagger([0, stagger])
      })

      // flutter words away
      tl.add(
        {
          targets: captionWords,
          translateY: [0, 10],
          opacity: 0,
          easing: "easeInQuad",
          duration,
          delay: anime.stagger([0, stagger], { direction: "reverse" })
        },
        `+=${delay}`
      )

      // Animate Frame out of place
      tl.add(
        {
          targets: frame,
          translateX: -400,
          translateY: 50,
          rotate: -angle,
          opacity: 0,
          duration: duration * 1.25,
          complete:
            memories[i]?.type !== "video"
              ? undefined
              : () => {
                  visibleVideo.current?.pause()
                  visibleVideo.current = null
                }
        },
        `-=${stagger}`
      )
    }

    animation.current = tl
  })

  useEvents("slide", state, {
    slideTransitionIn: useCallback(() => {
      if (!animation.current) constructAnimation(state.transitionBuffer)
    }, []),
    slideTransitionOut: useCallback(() => {
      anime({
        targets: container.current,
        opacity: 0,
        duration: schema.crossfade * 1000,
        easing: "easeOutQuad"
      })
    }, []),
    slideMount: useCallback(() => {
      if (!animation.current) constructAnimation(settings.duration)
      animation.current.play()
    }, []),
    playStateChange: useCallback(({ playing }) => {
      const method = playing ? "play" : "pause"
      visibleVideo.current?.[method]?.()
      if (!animation.current?.completed) {
        animation.current?.[method]?.()
      }
    }, []),
    slideRestart: useCallback(() => {
      animation.current?.pause()
      animation.current = null
      constructAnimation(settings.duration)
    }, []),
    slideUnMount: useCallback(() => {
      animation.current?.pause()
      animation.current = null
    }, [])
  })

  return (
    <div className={bem()} ref={container}>
      <div className={bem("background")}>
        {data.background?.includes?.("gradient") && (
          <Gradient
            type={data.background}
            theme={theme}
            playing={state.playing}
          />
        )}
      </div>
      <div className={bem("content")}>
        {memories.map((memory, index) => (
          <div
            className={bem("memory", `--${index}`)}
            key={index}
            ref={setRef(index)}
          >
            <div className={bem("frame")}>
              {memory.type === "photo" && (
                <img src={memory.img_src} crossOrigin="anynomous" />
              )}
              {memory.type === "video" && (
                <video
                  src={assets[memory.video_src]}
                  muted={true}
                  autoPlay={false}
                  controls={false}
                  playsInline={true}
                  loop={true}
                  crossOrigin="anynomous"
                />
              )}
            </div>
            <div className={bem("caption")}>
              <p>{renderText(memory.caption, recipient)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Memories
