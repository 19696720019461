// Hooks
import { useEffect, useState } from "react"
import { useBemify, useTransitions } from "../../hooks"

// Components
import Button from "../Button"
import ExtraDopeSnowGlobe from "../assets/ExtraDopeSnowGlobe"

// Helpers
import axios from "axios"
import { Timer } from "../../utils"

// Define Static Copy
const copy = {
  header: "Happy Holidays",
  from: "from Arjun & Erin",
  cta: "Let's Go"
}

const SplashScreen = ({ onContinue }) => {
  const bem = useBemify("login")
  const [genericRecipient, setGenericRecipient] = useState()
  const [container, transitions, { fade, fly }] = useTransitions({
    delayResolve: 0
  })

  // Fetch the generic recipient
  useEffect(() => {
    const fetchGenericRecipient = async () => {
      const { data } = await Timer.min(
        axios.get("/api/generic-recipient"),
        1600
      )
      setGenericRecipient(data.data)
    }
    fetchGenericRecipient()
  }, [])

  return (
    <div className={bem()} ref={container}>
      <div className={bem("container")}>
        {/* Header */}
        <div className={bem("header")}>
          <div className={bem("illustration", fly)}>
            <div className={bem("illustration-container")}>
              <ExtraDopeSnowGlobe />
            </div>
          </div>
          <h1 className={bem("title", fly)}>{copy.header}</h1>
          <h2 className={bem("title", "--recipient", fly)}>{copy.from}</h2>
        </div>

        {/* CTA */}
        <div className={bem("form")}>
          <div className={bem("cta", fade)}>
            <Button
              showLoader={!genericRecipient}
              icon="arrow-forward"
              iconSize="xxl"
              theme="primary circle xl"
              onClick={() => {
                transitions.exit().then(() => onContinue(genericRecipient))
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
