import InputMask from "react-input-mask"
import { useBemify } from "../hooks"
import { join } from "../utils"
import Button from "./Button"

const InputForm = ({
  mask,
  value,
  onChange,
  maskedChar,
  onEnter,
  isValid,
  theme,
  children,
  error,
  placeholder,
  onSubmit,
  showLoader,
  type,
  inputMode
}) => {
  const bem = useBemify("input-form")

  return (
    <div className={join(bem(), [theme], [error, "error"])}>
      <div className={bem("input")}>
        <InputMask
          type={type || "numeric"}
          placeholder={placeholder}
          inputMode={inputMode}
          mask={mask}
          maskChar={maskedChar ?? null}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyUp={
            onEnter
              ? ({ keyCode }) => {
                  if (keyCode !== 13 || !isValid) return
                  onEnter()
                }
              : undefined
          }
        />
      </div>
      {children && <div className={bem("children")}>{children}</div>}
      {onSubmit && (
        <div className={bem("submit")}>
          <Button
            icon="arrow-forward"
            iconSize="lg"
            onClick={onSubmit}
            showLoader={showLoader}
            disabled={!isValid || error}
          />
        </div>
      )}
    </div>
  )
}

export default InputForm
