import { useBemify } from "../hooks"

const Branding = ({ animationClass, prefix, customText }) => {
  const bem = useBemify("branding")

  return (
    <div className={bem()}>
      {customText ? (
        <p className={bem("name")}>{customText}</p>
      ) : (
        <p className={bem("name", [animationClass])}>
          {prefix && (
            <>
              <span className={bem("dope")}>{prefix}</span>
              <span>&nbsp;</span>
            </>
          )}
          <span className={bem("dope")}>Dope</span>
          <span>&nbsp;</span>
          <span className={bem("moments")}>Moments</span>
        </p>
      )}
    </div>
  )
}

export default Branding
