import { tests } from "./types"

// Sometimes you want to map and filter at the same time
const filterMap = (arr, test) => {
  // I'm lazy ok
  const { isArr, isNullish } = tests

  // Prevent some sus shit
  if (!test || !isArr(arr)) return

  const filtered = []

  for (let i = 0; i < arr.length; i++) {
    // Run test and pass it the typical method params
    const result = test(arr[i], i, arr)

    // If the result is nullish then get that shit out of here. 0 and false are ok
    if (!isNullish(result)) filtered.push(result)
  }

  return filtered
}

export default filterMap
