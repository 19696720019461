import { useState, useMemo } from "react"
import { useAssetLoader, useRecipient, useLocalStorage } from "./hooks"
import views, { initialView } from "./components/views"
import { settings } from "./data"
import Login from "./components/views/Login"
import SplashScreen from "./components/views/SplashScreen"
import ErrorScreen from "./components/views/ErrorScreen"
import Preloader from "./components/Preloader"
import AppWrapper from "./components/AppWrapper"

function Authenticated({ recipient }) {
  const { tutorial, slides } = useRecipient(recipient)
  const [{ tutorialComplete }] = useLocalStorage()
  const loader = useAssetLoader({
    slides,
    tutorial: !tutorialComplete
      ? { ...tutorial }
      : {
          audio_src: tutorial.audio_src
        }
  })

  // Handle Global Staet
  const [view, setView] = useState(initialView)
  const [dataStore, setDataStore] = useState(null)

  // Get View Component
  const AuthenticatedView = recipient && views[view].Component

  return (
    <AuthenticatedView
      loader={loader}
      data={{
        slides,
        tutorial,
        recipient,
        settings,
        dataStore,
        setDataStore
      }}
      next={(dataFromCall) => {
        setView(views[view].next)
        setDataStore(dataFromCall)
      }}
    />
  )
}

function Unauthenticated({ setRecipient, userInfo, shortcode }) {
  return shortcode ? (
    userInfo ? (
      <Login onLogin={setRecipient} userInfo={userInfo} shortcode={shortcode} />
    ) : (
      <ErrorScreen goToDefault={setRecipient} />
    )
  ) : (
    <SplashScreen onContinue={setRecipient} />
  )
}

export default function App() {
  const [recipient, setRecipient] = useState()
  const [userInfo, setUserInfo] = useState({})
  const [loaded, setLoaded] = useState(
    window.document.readyState === "complete"
  )

  // Cache the shortcode
  const shortcode = useMemo(
    () => window.location.pathname.split("/").at(-1) || null,
    []
  )

  // Determine Which View to Render
  const View = recipient ? Authenticated : Unauthenticated
  const background = recipient
    ? settings.backgrounds.app
    : settings.backgrounds.login

  return loaded ? (
    <AppWrapper transition={settings.appMountDuration} background={background}>
      <View
        recipient={recipient}
        setRecipient={setRecipient}
        userInfo={userInfo}
        shortcode={shortcode}
      />
    </AppWrapper>
  ) : (
    <Preloader
      initialLoadState={loaded}
      setName={setUserInfo}
      onComplete={() => setLoaded(true)}
      shortcode={shortcode}
      dependencies={{
        images: [
          settings?.backgrounds?.login?.image,
          settings?.backgrounds?.app?.image
        ]
      }}
    />
  )
}
