import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
  useCallback,
  useMemo
} from "react"
import { useBemify, usePinkyPromise, useAnimation } from "../hooks"

const get = (text) => {
  let str = text,
    customClass,
    transform
  if (Array.isArray(text)) {
    ;[str, customClass, transform] = text
  }
  if (transform) str = transform(str)
  return [str, customClass]
}

const TextCycle = ({
  texts = [],
  interceptedTexts = [],
  baseInterval = 2000,
  onChange
}) => {
  const bem = useBemify("text-cycle")
  const pinkyPromise = usePinkyPromise("TextCycle", "promisor")

  // Create Animations
  const [animation, container, [flutter]] = useAnimation([
    "flutterOut",
    "flutterIn"
  ])

  // Create State
  const [current, setCurrent] = useState(0)
  const [interceptedCurrent, setInterceptedCurrent] = useState(0)
  const previousIndex = useRef(current)

  useLayoutEffect(() => {
    // Destructure Intercepted Flag
    const intercepted =
      pinkyPromise?.kept &&
      pinkyPromise?.subscribed &&
      !!interceptedTexts.length

    // Calculate Indexes
    const prev = previousIndex.current
    const next = !intercepted ? current : interceptedCurrent + texts.length

    // Allow the intercept function to be called
    if (onChange)
      onChange({
        current: intercepted ? interceptedCurrent : current,
        intercepted
      })

    // Start animation
    const children = [...container.current.children]

    // Use animation function to get timeline
    const tl = animation(children[prev], children[next])

    // Update PreviousIndex
    previousIndex.current = next

    // Calculate the variable interval
    const [currentText] = get(
      !intercepted ? texts[current] : interceptedTexts[interceptedCurrent]
    )
    const interval = baseInterval + currentText.length * 50

    // Start the timeout so we can trigger the next animation
    const timeoutID = setTimeout(
      !intercepted
        ? () => {
            setCurrent(current == texts.length - 1 ? 0 : current + 1)
          }
        : () => {
            if (interceptedCurrent < interceptedTexts.length - 1) {
              setInterceptedCurrent(interceptedCurrent + 1)
            } else {
              pinkyPromise.keep()
            }
          },
      interval
    )

    // cleanup timers and prevent memory leaks
    return () => {
      tl.pause()
      clearTimeout(timeoutID)
    }
  }, [current, interceptedCurrent])

  // Create the function that will be used to map and render the words
  const renderText = useCallback(
    (key) => (txt, i) => {
      const [text, customClass] = get(txt)
      return (
        <p className={bem("item", customClass)} key={`${key}-${i}`}>
          {text.split(" ").map((word, j) => (
            <React.Fragment key={j}>
              <span className={bem("word", flutter)}>{word}</span>
              {text.split(" ")[j + 1] && <span>&nbsp;</span>}
            </React.Fragment>
          ))}
        </p>
      )
    },
    []
  )

  const renderedTexts = useMemo(() => texts.map(renderText("text")), [])
  const renderedInterceptedTexts = useMemo(
    () => interceptedTexts.map(renderText("intercept")),
    []
  )

  return (
    <div className={bem()} ref={container}>
      {renderedTexts}
      {renderedInterceptedTexts}
    </div>
  )
}

export default TextCycle
