import { useLayoutEffect, useRef } from "react"

const fonts = {
  dark: ["#333333cc", "#333"],
  light: ["#ffffffcc", "#fff"]
}

const themes = {
  starburst: ["#ef008f", "#6ec3f4", "#7038ff", "#ffba27", ...fonts.light],
  sprite: ["#32b99b", "#85FFBD", "#FFFB7D", "#72dfff", ...fonts.dark],
  extra_dope: ["#96DFCE", "#A5DF96", "#EEFF27", "#FFBA27", ...fonts.dark],
  mint: ["#fcfdff", "#abe0e7", "#6caabc", "#2a8777", ...fonts.dark],
  azure: ["#ecedff", "#9db6d0", "#6b7fb7", "#2e55a9", "#00183f85", "#00183f"],
  last_light: ["#484969", "#55598b", "#b6638e", "#fece5e", ...fonts.light],
  lush_love: ["#ffb0cf", "#ff8ec2", "#66bf72", "#da3d40", ...fonts.light],
  passion: ["#ed94d3", "#c1609b", "#6654c9", "#380e91", ...fonts.light],
  clay: ["#b0484e", "#7b4f54", "#aa521e", "#da785a", ...fonts.light],
  emerald: ["#ccbcaf", "#747f4b", "#39594e", "#09130a", ...fonts.light],
  teal_splash: ["#94d6d1", "#806fb6", "#425f6d", "#1e2325", ...fonts.light],
  pink_lemonade: ["#FF9A8B", "#FF6A88", "#FF99AC", "#D5AEDE", ...fonts.light],
  christmas: ["#FBEEEE", "#E65B5B", "#20D27C", "#76BF2D", ...fonts.dark]
}

const defaultTheme = "starburst"

export const useThemeFontColors = (theme, existingRef) => {
  const ref = existingRef || useRef()
  const colors = ["dark", "light"].includes(theme)
    ? fonts[theme]
    : (themes[theme] || themes[defaultTheme]).slice(-2)

  useLayoutEffect(() => {
    if (!theme) return // Update global font colors
    ;["primary", "secondary"].forEach((variant, i) => {
      ref.current?.style?.setProperty(
        `--active-gradient-accent-${variant}`,
        colors[i]
      )
    })
  }, [theme])

  return ref
}

export const useThemeColors = (theme, existingRef) => {
  const ref = existingRef || useRef()
  const colors = (themes[theme] || themes[defaultTheme]).slice(0, 4)

  useLayoutEffect(() => {
    if (!ref.current?.style) return
    const { style } = ref.current

    // Update the css color variable values for this particular dom node
    colors.forEach((color, i) => {
      style.setProperty(`--gradient-color-${i + 1}`, color)
    })
  }, [theme])

  return ref
}
