import { useEffect, useState } from "react"

export default function useLocalStorage() {
  const [state, setState] = useState(() => {
    const localData = localStorage.getItem("data")
    return localData ? JSON.parse(localData) : {}
  })

  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(state))
  }, [state])

  return [state, setState]
}
