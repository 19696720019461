const spelledNumbers = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "fourty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
  100: "hundred",
  1000: "thousand",
  1000000: "million",
  1000000000: "billion",
  1000000000000: "trillion",
  1000000000000000: "quadrillion",
  1000000000000000000: "quintillion"
}

export default function getSpelledNumbers(num) {
  if (!spelledNumbers[num]) {
    if (num < 100) {
      return `${spelledNumbers[Math.floor(num / 10) * 10]}-${
        spelledNumbers[num % 10]
      }`
    } else if (num < 1000) {
      return `${
        spelledNumbers[Math.floor(num / 100)]
      } hundred ${getSpelledNumbers(num % 100)}`
    } else if (num < 1000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000)
      )} thousand ${getSpelledNumbers(num % 1000)}`
    } else if (num < 1000000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000000)
      )} million ${getSpelledNumbers(num % 1000000)}`
    } else if (num < 1000000000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000000000)
      )} billion ${getSpelledNumbers(num % 1000000000)}`
    } else if (num < 1000000000000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000000000000)
      )} trillion ${getSpelledNumbers(num % 1000000000000)}`
    } else if (num < 1000000000000000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000000000000000)
      )} quadrillion ${getSpelledNumbers(num % 1000000000000000)}`
    } else if (num < 1000000000000000000000) {
      return `${getSpelledNumbers(
        Math.floor(num / 1000000000000000000)
      )} quintillion ${getSpelledNumbers(num % 1000000000000000000)}`
    }
  }

  return spelledNumbers[num]
}
