import React from "react"

export const wrapWords = (text, recipient) => {
  const replaced = text.replaceAll("{{name}}", recipient.name)
  const words = replaced.split(" ")
  return words.map((word, index) => {
    return (
      <React.Fragment key={index}>
        <span className="word">{word}</span>
        {index !== words.length - 1 && <span className="space">&nbsp;</span>}
      </React.Fragment>
    )
  })
}

export const renderText = (line, recipient) => {
  return !Array.isArray(line)
    ? wrapWords(line, recipient)
    : line.map((section, index) => {
        if (Array.isArray(section)) {
          const [words, customClass] = section
          return (
            <React.Fragment key={index}>
              <span className={customClass || "alt"}>
                {wrapWords(words, recipient)}
              </span>
              {index !== line.length - 1 &&
                !(customClass || "").includes("emoji") && (
                  <span className="space">&nbsp;</span>
                )}
            </React.Fragment>
          )
        } else
          return (
            <React.Fragment key={index}>
              {wrapWords(section, recipient)}
              {index !== line.length - 1 && (
                <span className="space">&nbsp;</span>
              )}
            </React.Fragment>
          )
      })
}
