import anime from "animejs"
import {
  useState,
  useLayoutEffect,
  useCallback,
  useRef,
  useMemo,
  useRef
} from "react"

const constants = {
  cn: ".story__indicator",
  events: [
    "storyMount",
    "storyUnMount",
    "storyComplete",
    "slideMount",
    "slideUnMount",
    "slideTransitionIn",
    "slideTransitionOut",
    "slideRestart",
    "muteStateChange",
    "playStateChange",
    "touchStateChange"
  ]
}

const config = {
  indicators: {
    scale: {
      min: {
        x: 0.95,
        y: 0.7
      },
      max: {
        x: 1.02,
        y: 1
      }
    }
  }
}

// Use Progress for auto-changing the slides
const useProgress = ({ state, onTransitionReady, onComplete, settings }) => {
  const ref = useRef()
  const animation = useRef()
  const transitioning = useRef(false)

  const slideMount = useCallback(() => {
    // Pause Existing
    animation.current?.pause?.()

    // Destructure variables
    const { current, duration } = state
    const { total, crossfade } = duration
    const { cn } = constants
    const mount = 300

    const targets = {
      all: ref.current.querySelectorAll(cn),
      current: ref.current.querySelectorAll(`${cn}--${current}`),
      rest: ref.current.querySelectorAll(`${cn}:not(${cn}--${current})`),
      currentInner: ref.current.querySelector(`${cn}--${current} span`)
    }

    // Create a timeline
    const tl = anime.timeline({ easing: "easeInQuad" })

    // STAAGE 1A: scale down the non-active icons
    tl.add(
      {
        targets: targets.rest,
        scaleX: [1, config.indicators.scale.min.x],
        scaleY: [1, config.indicators.scale.min.y],
        duration: mount
      },
      0
    )

    // STAGE 1B: scale up the active icon
    tl.add(
      {
        targets: targets.current,
        scaleX: [1, config.indicators.scale.max.x],
        duration: mount
      },
      0
    )

    // animate the linear progress bar
    tl.add(
      {
        targets: targets.currentInner,
        opacity: [1, 1],
        scaleX: [0, 1],
        easing: "linear",
        duration: total * 1000 - mount
      },
      mount
    )

    // Reset all the indicators back to 1
    tl.add(
      {
        targets: targets.all,
        scaleX: 1,
        scaleY: 1,
        duration: crossfade * 1000,
        begin: () => {
          transitioning.current = true
          if (onTransitionReady) onTransitionReady()
        },
        complete: () => {
          animation.current = null
          transitioning.current = false
          if (onComplete) onComplete()
        }
      },
      1000 * (total - crossfade)
    )

    // Update the value of our ref
    animation.current = tl
  }, [state.current])

  const onPageChange = useCallback(
    (direction = 1) => {
      animation.current?.pause?.()

      // Destructure variables
      const { current, slideCount } = state
      const { cn } = constants

      const targets = {
        all: ref.current.querySelectorAll(cn),
        currentInner: ref.current.querySelector(`${cn}--${current} span`),
        nextInner: ref.current.querySelector(
          `${cn}--${direction ? current + 1 : current - 1} span`
        ),
        reset: []
      }

      // Create a timeline
      const tl = anime.timeline({ easing: "easeInOutQuad" })

      // animate the linear progress bar
      tl.add({
        targets: targets.currentInner,
        scaleX: direction,
        opacity: 0,
        duration: settings.duration * 800
      })

      tl.add(
        {
          targets: targets.all,
          scaleX: 1,
          scaleY: 1,
          duration: settings.duration * 1000
        },
        0
      )

      tl.add(
        {
          targets: targets.nextInner,
          opacity: 0,
          duration: settings.duration * 1000
        },
        0
      )

      // Reset Inner indicators for slides greater than current
      if (!direction) {
        for (let i = current; i < slideCount; i++) {
          targets.reset.push(ref.current.querySelector(`${cn}--${i} span`))
        }
        tl.add(
          {
            targets: targets.reset,
            scaleX: 0,
            duration: 150
          },
          settings.duration * 850
        )
      } else {
        tl.add(
          {
            targets: targets.currentInner,
            scaleX: 1,
            duration: 150
          },
          settings.duration * 850
        )
      }

      tl.add(
        {
          targets: targets.currentInner,
          opacity: 1,
          duration: settings.duration * 800
        },
        settings.duration * 950
      )
    },
    [state.current]
  )

  return {
    ref,
    isTransitioning: () => !!transitioning.current,
    playStateChange: ({ playing }) =>
      animation.current?.[playing ? "play" : "pause"]?.(),
    slideRestart: () => animation.current?.restart?.(),
    slideUnMount: () => {
      animation.current?.pause?.()
      animation.current = null
    },
    slideMount,
    onPageChange
  }
}

// This is a hook we can use to write some general transitions and animations
const useStoryAnimations = ({ settings, onComplete }) => {
  const ref = useRef()
  const animation = useRef()
  const touchAnimation = useRef()

  const white = useMemo(() => {
    return window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--white")
  }, [])

  const d = useCallback(
    (percent) => {
      return Math.floor(settings.storyMountDuration * percent * 1000)
    },
    [settings.storyMountDuration]
  )

  const storyMount = useCallback(() => {
    const tl = anime.timeline({
      easing: "easeInOutQuad",
      complete: () => {
        animation.current = null
        onComplete?.()
      }
    })

    tl.add({
      targets: ref.current,
      backgroundColor: [white, "#222"],
      duration: d(0.6),
      easing: "easeInQuad"
    })

    tl.add({
      targets: ref.current.querySelector(".story__slides"),
      opacity: {
        value: [0, 1],
        duration: d(0.3),
        delay: d(0.1)
      },
      scale: {
        value: [1.15, 1],
        duration: d(0.4)
      }
    })

    tl.add(
      {
        targets: ref.current.querySelectorAll(".story__indicator"),
        delay: anime.stagger([0, d(0.15)]),
        translateX: {
          value: [50, 0],
          duration: d(0.2),
          easing: "easeOutExpo"
        },
        translateY: {
          value: [-25, 0],
          duration: d(0.2),
          easing: "easeOutExpo"
        },
        scale: {
          value: [1.2, 1],
          duration: d(0.12),
          delay: d(0.08),
          easing: "easeOutBack"
        },
        opacity: {
          value: [0, 1],
          duration: d(0.15),
          easing: "easeOutCirc"
        }
      },
      `-=${d(0.25)}`
    )

    const indicatorSpans = ref.current.querySelectorAll(
      ".story__indicator span"
    )
    tl.add(
      {
        targets: indicatorSpans,
        duration: 400,
        delay: indicatorSpans.length ? anime.stagger([0, 200]) : 0,
        easing: "easeOutQuad",

        opacity: [1, 0],
        scaleX: [1, 1],
        complete: () => {
          anime.set(indicatorSpans, {
            scaleX: 0
          })
        }
      },
      `-=${d(0.25)}`
    )

    tl.add(
      {
        targets: ref.current.querySelector(".story__mute"),
        duration: d(0.2),
        scale: {
          value: [0.6, 1],
          duration: d(0.2)
        },
        opacity: {
          value: [0, 1],
          duration: d(0.2)
        }
      },
      `-=${d(0.15)}`
    )

    animation.current = tl

    return tl
  }, [])

  const onTouchStart = useCallback((event) => {
    let { clientX, clientY } = event

    if (event.type === "touchstart") {
      clientX = event.touches[0]?.clientX
      clientY = event.touches[0]?.clientY
    }

    const pointer = ref.current.querySelector(".story__pointer")
    const assets = ref.current.querySelector(".story__animation-assets")

    anime.set(assets, {
      top: clientY,
      left: clientX
    })

    const tl = anime.timeline({
      duration: settings.threshold * 800
    })

    tl.add({
      targets: pointer,
      opacity: [0, 0.9],
      scale: [0.4, 1],
      easing: "easeOutElastic"
    })

    tl.add({
      targets: pointer,
      opacity: 0,
      scale: 0.5,
      delay: settings.threshold * 400,
      easing: "easeOutExpo"
    })

    touchAnimation.current = tl
  }, [])

  const onHold = useCallback(() => {
    touchAnimation.current?.pause?.()
  }, [])

  const onRelease = useCallback(() => {
    touchAnimation.current?.play?.()
  }, [])

  const onPageChange = useCallback(() => {
    const ripple = ref.current.querySelector(".story__ripple")
    const tl = anime.timeline({})
    animation.current = tl

    return new Promise((resolve) => {
      tl.add({
        targets: ripple,
        scale: [0, 1],
        opacity: {
          value: [0.25, 1],
          duration: settings.duration * 750
        },
        easing: "easeInOutQuad",
        duration: settings.duration * 1000,
        complete: () => resolve()
      })

      tl.add({
        targets: ripple,
        opacity: [1, 0],
        easing: "easeInQuad",
        duration: settings.duration * 750,
        delay: settings.duration * 250
      })

      tl.finished.then(() => {
        animation.current = null
        anime.set(ripple, { scale: 0 })
      })
    })
  }, [])

  const onStoryRestart = useCallback(() => {
    const tl = anime.timeline({ easing: "easeInQuad" })
    animation.current = tl

    return new Promise((resolve) => {
      // Fade out
      tl.add({
        targets: ref.current.querySelector(".story__slides"),
        opacity: [1, 0],
        duration: settings.duration * 1000,
        complete: () => resolve()
      })

      // Reset Slide Indicators
      tl.add({
        targets: ref.current.querySelectorAll(`${constants.cn} span`),
        scaleX: 0,
        duration: 250,
        delay: anime.stagger([0, 200], { direction: "reverse" })
      })

      // Fade In
      tl.add({
        targets: ref.current.querySelector(".story__slides"),
        opacity: [0, 1],
        duration: settings.duration * 1000,
        delay: settings.duration * 250,
        easing: "easeOutQuad"
      })

      tl.finished.then(() => {
        animation.current = null
      })
    })
  }, [])

  const storyExit = useCallback(() => {
    // Create a timeline
    const tl = anime.timeline({
      easing: "easeInOutQuad"
    })

    // Fade out the slides
    tl.add({
      targets: ref.current.querySelector(".story__slides"),
      opacity: 0,
      duration: d(0.3)
    })

    // Fade out the mute button
    tl.add({
      targets: ref.current.querySelector(".story__mute"),
      duration: d(0.2),
      scale: {
        value: 0.6,
        duration: d(0.2)
      },
      opacity: {
        value: 0,
        duration: d(0.2)
      }
    })

    // Concurrently flutter out the slides
    tl.add({
      targets: ref.current.querySelectorAll(".story__indicator"),
      delay: anime.stagger([0, d(0.15)], { direction: "reverse" }),
      translateX: {
        value: -10,
        duration: d(0.2),
        easing: "easeInQuad"
      },
      translateY: {
        value: -25,
        duration: d(0.2),
        easing: "easeInQuad"
      },
      opacity: {
        value: 0,
        duration: d(0.15),
        easing: "easeInCirc"
      }
    })

    // Fade back to white
    tl.add({
      targets: ref.current,
      backgroundColor: white,
      opacity: 0,
      duration: d(0.8),
      easing: "easeInQuad"
    })

    animation.current = tl

    return tl.finished
  }, [])

  return {
    ref,
    isRunning: () => !!animation.current,
    storyMount,
    storyUnMount: () => animation.current?.pause?.(),
    onTouchStart,
    onPageChange,
    onHold,
    onRelease,
    onStoryRestart,
    storyExit
  }
}

// Use Touch Event Listeners
const useTouchTarget = (
  componentHandlers = {},
  settings,
  dependencies = []
) => {
  const { onTouchStart, onTap, onBackTap, onNextTap, onHold, onRelease } =
    componentHandlers

  const timeout = useRef()
  const timestamp = useRef()
  const threshold = settings.threshold * 1000

  const id = useMemo(() => {
    const hash = Date.now()
    return {
      back: `tt-back-${hash}`,
      next: `tt-next-${hash}`
    }
  }, [])

  const handleStart = useCallback((event) => {
    timestamp.current = event.timeStamp
    if (onTouchStart) onTouchStart(event)
    timeout.current = setTimeout(() => {
      if (onHold) onHold(event)
    }, threshold)
  }, dependencies)

  const handleEnd = useCallback(
    (event) => {
      clearTimeout(timeout.current)
      const delta = event.timeStamp - timestamp.current
      if (delta < threshold) {
        const { target } = event
        if (id.back === target.id && onBackTap) onBackTap(event)
        if (id.next === target.id && onNextTap) onNextTap(event)
        if (onTap) onTap(event)
      } else if (onRelease) onRelease()
    },
    [id.back, id.next, ...dependencies]
  )

  const handlers = useMemo(
    () => ({
      onMouseDown: handleStart,
      onMouseUp: handleEnd,
      onTouchStart: handleStart,
      onTouchEnd(event) {
        event.preventDefault()
        handleEnd(event)
      },
      onContextMenu(event) {
        event.preventDefault()
      }
    }),
    dependencies
  )

  return { props: handlers, id }
}

// This hook allows us to tap into the state
const useStoryState = (slides, settings, initialMute) => {
  // Create Pieces of state
  const [current, setCurrent] = useState(-1)
  const [next, setNext] = useState(0)
  const [playing, setPlaying] = useState(true)
  const [muted, setMuted] = useState(initialMute)
  const [touched, setTouched] = useState(false)
  const [startTime, setStartTime] = useState(Date.now())
  const [event, setEvent] = useState()
  const [complete, setComplete] = useState(false)

  // Construct combined state object
  return {
    current,
    next,
    playing,
    muted,
    complete,
    touched,
    startTime,
    event,
    slideCount: slides.length,
    theme: slides[current]?.theme || settings.defaultTheme,
    duration: {
      total: slides[current]?.duration || 0,
      crossfade: slides[current]?.crossfade || 0
    },
    set: useCallback((values = {}, e) => {
      ;[...Object.entries(values)].forEach(([key, value]) => {
        if (e) setEvent(e)
        switch (key.toLowerCase()) {
          case "playing":
            return setPlaying(value)
          case "muted":
            return setMuted(value)
          case "touched":
            return setTouched(value)
          case "restart":
            return value && setStartTime(Date.now())
          case "next":
            if (value !== slides.length) return setNext(value)
            break
          case "complete":
            return setComplete(value)
          case "current":
            if (value < slides.length && value >= 0) return setCurrent(value)
            break
        }
      })
    }, [])
  }
}

// Event Dispatcher
const useEventDispatcher = (handlers, dependenceies) => {
  return useMemo(() => {
    const store = {}
    constants.events.forEach((event) => {
      store[event] = handlers
        .map((handler) => handler[event])
        .filter((x) => !!x)
    })

    return {
      store,
      dispatch(type, data) {
        store[type].forEach((handler) => handler(data))
      }
    }
  }, dependenceies)
}

// Events For Story parent Element
const useStoryEvents = (state, dispatcher) => {
  // Destructure State
  const { current, next, startTime, playing, muted, touched, complete } = state
  const mounted = useRef(false)

  // Handle Restart
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("slideRestart")
  }, [startTime])

  // Handle Play/Pause
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("playStateChange", { playing })
  }, [playing])

  // Handle Touchstate Change
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("touchStateChange", { touched })
  }, [touched])

  // Handle Mute StateChange
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("muteStateChange", { muted })
  }, [muted])

  // Handle Transition Out
  useLayoutEffect(() => {
    if (mounted.current && next >= 0)
      dispatcher.dispatch("slideTransitionOut", { next })
  }, [next])

  // Trigger Slide Mount Event
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("slideMount")
    return () => dispatcher.dispatch("slideUnMount")
  }, [current])

  // Handle Mount and unmount
  useLayoutEffect(() => {
    dispatcher.dispatch("storyMount")
    mounted.current = true
    return () => dispatcher.dispatch("storyUnMount")
  }, [])

  // Handle Complete
  useLayoutEffect(() => {
    if (mounted.current && complete) {
      dispatcher.dispatch("storyComplete")
    }
  }, [complete])
}

// Events for child slide elements
const useSlideEvents = (state, dispatcher) => {
  // Destructure State
  const { current, next, startTime, playing, muted, touched, event, complete } =
    state
  const mounted = useRef(false)

  // Handle Restart
  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("slideRestart", { event })
  }, [startTime])

  useLayoutEffect(() => {
    if (mounted.current)
      dispatcher.dispatch("playStateChange", { event, playing })
  }, [playing])

  useLayoutEffect(() => {
    if (mounted.current)
      dispatcher.dispatch("muteStateChange", { event, muted })
  }, [muted])

  useLayoutEffect(() => {
    if (mounted.current)
      dispatcher.dispatch("touchStateChange", { event, touched })
  }, [touched])

  useLayoutEffect(() => {
    if (mounted.current && next >= 0)
      dispatcher.dispatch("slideTransitionOut", { event })
  }, [next])

  useLayoutEffect(() => {
    if (mounted.current) dispatcher.dispatch("slideMount", { event })
  }, [current])

  // Handle Mount & Unmount
  useLayoutEffect(() => {
    if (next >= 0) dispatcher.dispatch("slideTransitionIn", { event })
    else dispatcher.dispatch("slideMount", { event })
    mounted.current = true
    return () => dispatcher.dispatch("slideUnMount", { event })
  }, [])

  // Handle Complete
  useLayoutEffect(() => {
    if (mounted.current && complete) {
      dispatcher.dispatch("storyComplete")
    }
  }, [complete])
}

// Use Story Events Hook
export const useEvents = (type, state, ...handlers) => {
  const dispatcher = useEventDispatcher(handlers, [state.current])

  if (type === "story") return useStoryEvents(state, dispatcher)
  if (type === "slide") return useSlideEvents(state, dispatcher)
}

// This is the main hook that we will be using
const useStory = (data) => {
  // Use the lovely story state
  const state = useStoryState(
    data.slides,
    data.settings,
    data.dataStore.initialMute
  )

  // Create some state for the modal
  const [modal, setModal] = useState(false)

  // Use the progress hook
  const {
    isTransitioning,
    onPageChange: progressOnPageChange,
    ...progress
  } = useProgress({
    state,
    settings: data.settings,
    onTransitionReady: () => {
      state.set({ next: state.current + 1 })
    },
    onComplete: () => {
      state.set({
        current: state.current + 1,
        next: -1,
        complete: state.current === data.slides.length - 1 ? true : false
      })
    }
  })

  // Use Animations for the story itself
  const {
    isRunning,
    onTouchStart,
    onHold,
    onRelease,
    onPageChange,
    ...animation
  } = useStoryAnimations({
    settings: data.settings,
    onComplete: () =>
      state.set({
        current: 0,
        next: -1
      })
  })

  // Use Touch Target Hook
  const touchTarget = useTouchTarget(
    {
      onTouchStart: (e) => {
        onTouchStart(e)
        if (isRunning() || isTransitioning()) return
        state.set(
          {
            touched: true
          },
          e
        )
      },
      onHold: (e) => {
        if (isRunning() || isTransitioning()) return
        onHold()
        state.set(
          {
            touched: true,
            playing: false
          },
          e
        )
      },
      onRelease: (e) => {
        if (isRunning()) return
        onRelease()
        state.set(
          {
            touched: false,
            playing: true
          },
          e
        )
      },
      onNextTap: async (e) => {
        if (isRunning() || isTransitioning()) return
        if (state.current + 1 !== data.slides.length) {
          progressOnPageChange()
          await onPageChange()
        }
        state.set(
          {
            current: state.current + 1,
            next: -1,
            touched: false,
            complete: state.current === data.slides.length - 1 ? true : false
          },
          e
        )
      },
      onBackTap: async (e) => {
        if (isRunning() || isTransitioning()) return
        progressOnPageChange(0)
        await onPageChange()
        // Go Back to previous Slide
        if (state.current)
          state.set(
            {
              current: state.current - 1,
              touched: false,
              complete: false
            },
            e
          )
        // Restart Current Slide
        else
          state.set(
            {
              restart: true,
              touched: false
            },
            e
          )
      }
    },
    data.settings,
    [state.current, state.next]
  )

  // Use Hooks to run some shite
  useEvents("story", state, animation, progress, {
    storyComplete: () => setModal(true)
  })

  return {
    state,
    utils: {
      isCurrent: (id) => id === state.current,
      isNext: (id) => id === state.next,
      isVisible: (id) => [state.next, state.current].includes(id),
      isLast: (id) => id === data.slides.length - 1
    },
    touchTarget,
    progress,
    animation,
    modal: {
      state: modal,
      set: setModal
    }
  }
}

export default useStory
