import React, { useCallback, useRef, useMemo } from "react"
import { useBemify, useEvents, useThemeFontColors } from "../../hooks"
import { renderText } from "../../utils"
import Gradient from "../Gradient"
import Video from "./Video"
import anime from "animejs"

const TitleSequence = ({ id, recipient, schema, assets, state, settings }) => {
  // Turbocharge Functionality with my hooks!
  const bem = useBemify("template-title-sequence")

  // Destructure the schema
  const { data } = schema.template
  const { titles } = data
  const { theme } = data

  // Create a refs
  const animation = useRef()
  const container = useThemeFontColors(theme)

  const characterCountPercentages = useMemo(() => {
    const characterCounts = titles.map((title) => {
      if (!Array.isArray(title)) return title.length
      return title
        .map((section) => {
          if (!Array.isArray(section)) return section.length
          else return section[0].length
        })
        .reduce((a, b) => a + b)
    })

    return characterCounts.map((count) => {
      return count / characterCounts.reduce((a, b) => a + b)
    })
  }, [titles])

  // Construct the animation timeline
  const constructAnimation = useCallback((transitionBuffer = 0) => {
    const totalDuration = schema.duration - transitionBuffer

    const tl = anime.timeline({
      easing: "easeOutQuad",
      autoplay: false
    })

    for (let i = 0; i < titles.length; i++) {
      const sectionDuration = Math.floor(
        totalDuration * characterCountPercentages[i] * 1000
      )

      const stagger = 300
      const duration = 500
      const delay = sectionDuration - (stagger + duration) * 2

      const currentWords = container.current.querySelectorAll(
        `.${bem(`title--${i}`)} .word`
      )

      tl.add(
        {
          targets: currentWords,
          translateY: [80, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutQuad",
          duration,
          delay: anime.stagger([0, stagger])
        },
        i ? undefined : `+=${transitionBuffer * 1000}`
      )

      if (i !== titles.length - 1) {
        tl.add(
          {
            targets: currentWords,
            translateY: [0, -40],
            opacity: 0,
            easing: "easeInQuad",
            duration,
            delay: anime.stagger([0, stagger])
          },
          `+=${delay}`
        )
      }
    }

    animation.current = tl
  }, [])

  // Construct animation for story complete
  const fadeTextOnComplete = useCallback(() => {
    const tl = anime.timeline({
      easing: "easeOutQuad",
      autoplay: true
    })

    for (let i = 0; i < titles.length; i++) {
      tl.add(
        {
          targets: container.current.querySelectorAll(
            `.${bem(`title--${i}`)} .word`
          ),
          translateY: [0, -40],
          opacity: 0,
          easing: "easeInQuad",
          duration: 500,
          delay: anime.stagger([0, 300])
        },
        0
      )
    }
  }, [])

  // Hook into slide lifecycle
  useEvents("slide", state, {
    slideTransitionIn: useCallback(() => {
      if (!animation.current) constructAnimation(schema.crossfade)
    }, []),
    slideMount: useCallback(() => {
      if (!animation.current) constructAnimation(settings.duration)
      animation.current?.play()
    }, []),
    playStateChange: useCallback(({ playing }) => {
      if (playing) animation.current?.play()
      else animation.current?.pause()
    }, []),
    slideRestart: useCallback(() => {
      animation.current?.pause()
      animation.current = null
      constructAnimation(settings.duration)
      animation.current?.play()
    }, []),
    slideUnMount: useCallback(() => {
      animation.current?.pause()
      animation.current = null
    }, []),
    slideTransitionOut: useCallback(() => {
      animation.current?.pause()
      const tl = anime.timeline({
        easing: "easeInQuad"
      })

      tl.add({
        targets: container.current.querySelectorAll(
          `.${bem(`title--${titles.length - 1}`)} .word`
        ),
        translateY: [0, -40],
        opacity: 0,
        easing: "easeInQuad",
        duration: 500,
        delay: anime.stagger([0, 300])
      })

      if (data.background?.includes?.("gradient")) {
        tl.add(
          {
            targets: container.current.querySelector(`.${bem("background")}`),
            opacity: 0,
            duration: schema.crossfade * 900
          },
          0
        )
      }

      animation.current = tl
    }),
    storyComplete: useCallback(() => {
      animation.current?.pause()
      animation.current = null
      fadeTextOnComplete()
    })
  })

  return (
    <div className={bem()} ref={container}>
      <div className={bem("background")}>
        {data.background?.includes?.("gradient") && (
          <Gradient type={data.background} theme={theme} />
        )}
        {data.background === "video" && (
          <Video
            id={id}
            schema={schema}
            state={state}
            settings={settings}
            assets={assets}
          />
        )}
      </div>
      <div className={bem("content")}>
        {titles.map((title, key) => (
          <div className={bem("title", `--${key}`)} key={key}>
            <h2>{renderText(title, recipient)}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TitleSequence
