// Based on https://codepen.io/robin-dela/pen/ZBvpzb?editors=1100

// Generate Layer Names
const layers = new Array(8).fill("Snowflake-Layer")

// Define Vectors
const vectors = [
  "M40.5,40c6.9,0,7.5,0.9,4.6,6.4c-0.5,0.9-1,1.8-1.6,2.5c-1.9,2.1-4.2,4.8-7,4c-2.7-0.9-0.9-4.3-1.3-6.6				C34.5,41.4,35.9,38.7,40.5,40z",
  "M30.9,46.6c-0.6,2.3,1,5.3-1.1,6.2c-2.4,1-4.5-1.4-6.4-3c-0.6-0.5-1.2-1.2-1.6-1.9c-1.5-2.2-2.6-4.6-2.2-7.4				c0.1-0.5,9.7-0.8,10.3-0.2C31.7,42.2,30,44.7,30.9,46.6z",
  "M30.5,32.2c-0.8,1,2.1,4.2-1.6,4.6c-2.7,0.3-7.7-5.6-7.8-8.9c-0.1-3.9,3-1.9,4.7-2.2				C31.1,24.6,31.1,28,30.5,32.2z",
  "M35.2,31.5c0.9-2-1.8-5.9,3-5.8c2.2,0,5.4-1.3,6.4,0.9c1.2,2.5-0.9,5.1-2.7,7c-1.5,1.7-3.4,3.9-5.8,3				C34.3,35.9,35.7,33.3,35.2,31.5z",
  "M30.7,18.1c-0.4,1,1.2,3.2-1.3,3.7c-1.9,0.4-6.1-4.6-6.1-7.1c0-2.9,2.3-1.5,3.6-1.8C31.1,12.2,31,15,30.7,18.1				z",
  "M35.5,18.1c-0.9-4.2,0.4-5.8,4-5.2c1.2,0.2,3.2-0.9,3.3,1.7c0.1,2.4-4.1,7.6-6,7.2				C34,21.3,35.9,18.9,35.5,18.1z",
  "M35.2,60.3c-0.1,1.8,0.6,4.2-2.5,3.8c-3.3-0.4-1.5-3.1-1.8-4.7c-0.2-1.4-0.6-3.5,2.1-3.4				C36.5,56.2,34.5,59,35.2,60.3z",
  "M36,7.1c-0.3,1.9-1.4,3-3.4,2.8c-1.7-0.2-2.7-1.4-2.6-3.1c0.1-2,1.4-3,3.3-2.8C35.1,4.1,36,5.3,36,7.1z"
]

const SnowFlakeLoader = () => {
  return (
    <div className="snowflake-loader">
      {layers.map((layer, i) => (
        <svg
          key={`${layer}-${i}`}
          id={"Layer_1"}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 64 64"
        >
          <g>
            {vectors.map((vector, j) => (
              <path
                key={j}
                className={`snowflake-loader__layer--${j}`}
                d={vector}
              />
            ))}
          </g>
        </svg>
      ))}
    </div>
  )
}

export default SnowFlakeLoader
