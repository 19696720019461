// A quick built in function to
const createTestLoop = (test) => {
  return (...values) => {
    let undefeated = true

    for (const value of values) {
      if (!test(value)) {
        undefeated = false
        break
      }
    }

    return undefeated
  }
}

// Cache the commonly used functions
export const tests = {
  isArr: createTestLoop((value) => Array.isArray(value)),
  isObj: createTestLoop((value) => value && typeof value === "object"),
  isStr: createTestLoop((value) => typeof value === "string"),
  isNum: createTestLoop(
    (value) => typeof value === "number" && !Number.isNaN(value)
  ),
  isFunc: createTestLoop(
    (value) =>
      value &&
      (typeof value === "function" ||
        value instanceof Function ||
        {}.toString.call(value) === "[object Function]")
  ),
  isNullish: createTestLoop(
    (value) => value === null || value === undefined || Number.isNaN(value)
  )
}

// A handy function that lets you create quick test functions for types or more
export const is = (type, props) => {
  // Prevent developers from doing some stupid shit...
  if (!type)
    return () => console.warn("Please specicy something to test against...")

  // Create functinos based on string input
  switch (type?.toLowerCase()) {
    case "array":
    case "arr":
      return tests.isArr

    case "object":
    case "obj":
      return tests.isObj

    case "string":
    case "str":
      return tests.isStr

    case "number":
    case "num":
      return tests.isNum

    case "function":
    case "func":
      return tests.isFunc

    case "null":
    case "nullish":
      return tests.isNullish

    case "between":
    case "isbetween":
    case "inrange":
    case "range":
    case "minmax":
      return (value, min, max, inclusive) => {
        // More being lazy
        const { isArr, isNum } = tests

        // Get Final Values
        const minimum = props.min ?? min
        const maximum = props.max ?? max
        const isInclusive = props.inclusive ?? inclusive

        // Use closures to create a test function within the test so we can preserve the params
        const test = (testValue) => {
          // Clean our numeric inputs
          const parsedValue = parseFloat(testValue)

          // Prevent this from being fucked with possible NaN values
          if (!isNum(parsedValue)) return false

          if (isInclusive)
            return parsedValue >= minimum && parsedValue <= maximum
          else return parsedValue > minimum && parsedValue < maximum
        }

        // If the value is an array then we need to do the loopty loop
        if (isArr(value)) return createTestLoop(test)(...value)
        else return test(value)
      }
  }
}
