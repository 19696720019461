import { createRoot } from "react-dom/client"
import App from "./App"

function updateDocumentHeight() {
  window.scrollTo(0, 0)
  const doc = document.documentElement
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`)
}

window.addEventListener("DOMContentLoaded", () => {
  // Update Document Height CSS variable
  window.addEventListener("resize", updateDocumentHeight)
  updateDocumentHeight()

  // Initialize React App
  const root = createRoot(document.querySelector("#app"))
  root.render(<App />)
})
