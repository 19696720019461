class Timer {
  constructor(threshold = 2000) {
    this.threshold = threshold
    this.timeout = null
  }

  startTimer(callback) {
    this.initial = new Date()

    if (!callback) return this

    clearTimeout(this.timeout)
    this.timeout = setTimeout(callback, this.threshold)

    return this
  }

  stop() {
    this.final = new Date()
    this.elapsed = this.final - this.initial
    this.remaining =
      this.elapsed >= this.threshold ? 0 : this.threshold - this.elapsed
  }

  async hold() {
    this.stop()
    if (this.remaining) await Timer.createTimeoutPromise(this.remaining)
    return true
  }

  reset() {
    this.elapsed = 0
    this.remaining = 0
  }

  static createTimeoutPromise(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  static start(time, callback) {
    return new Timer(time).startTimer(callback)
  }

  static wait(time) {
    return new Timer(time).startTimer().hold()
  }

  static async min(promise, threshold) {
    const timer = new Timer(threshold).startTimer()
    const data = await promise
    await timer.hold()
    return data
  }
}

export default Timer
