import Button from "../Button"
import { bemify } from "../../utils"

export default function Gifts({ next, data }) {
  const back = () => next(data.dataStore)
  const bem = bemify("gifts")

  return (
    <div className={bem()}>
      <h1>Gifts</h1>
      <div>
        <Button icon="arrow-back" theme="primary circle" onClick={back} />
      </div>
    </div>
  )
}
